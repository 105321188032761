import { Caption, Navbar } from '../config/models';
import { keyframes } from 'styled-components';
import { createTheme } from '@material-ui/core';

export const landscapeMobile = `only screen and (min-height: 319px) and (max-height: 480px) and (orientation: landscape)`;
export const landscapeSmall = `only screen and (max-height: 800px) and (orientation: landscape)`;
export const big = `only screen and (min-width: 1536px)`;
export const mobile = `only screen and (max-width: 1020px) and (orientation:portrait)`;
export const miniMobile = `only screen and (max-width: 380px) and (max-height: 600px) and (orientation:portrait)`;
export const mobileLandscape = `only screen and (max-width: 1020px) and (orientation:landscape)`;
export const tablet = `all and (min-width: 768px) and (min-height: 1024px) and (orientation:portrait)`;
export const smartphone = `only screen and (max-width: 768px)`;
export const firefox = '@-moz-document url-prefix()';
export const ie = 'all and (-ms-high-contrast: none), (-ms-high-contrast: active)';
export const safari = 'not all and (min-resolution:.001dpcm)';

export const theme = createTheme({
    typography: {
        fontFamily: ['Open Sans', 'Helvetica Neue', 'Arial', 'sans-serif'].join(',')
    }
});

export const RR_COLORS = {
    'br-blau': 'rgba(0, 130, 198, 1)',
    'br-blaugrau-dunkel': 'rgba(54, 59, 74, 1)',
    'br-blaugrau-mittel': 'rgba(75, 79, 97, 1)',
    'br-dunkelblau': 'rgba(0, 65, 130, 1)',
    'br-dunkelgrau': 'rgba(106, 107, 107, 1)',
    'br-hellblau': 'rgba(157, 193, 215, 1)',
    'br-hellgrau': 'rgba(196, 202, 208, 1)',
    'br-infogelb': 'rgba(235, 191, 63, 1)',
    'br-infogr-n': 'rgba(144, 164, 85, 1)',
    'br-infoorange': 'rgba(212, 113, 33, 1)',
    'br-inforrot': 'rgba(172, 62, 80, 1)',
    'br-mittelgrau': 'rgba(140, 140, 145, 1)',
    'br1-blau': 'rgba(0, 160, 213, 1)',
    'pv-super-hellgrau': 'rgba(244, 244, 244, 1)'
};

const BR24COLORS = {
    white: '#ffffff',
    veryLightGrey: '#f4f4f4',
    lightGrey: '#d7d7d7',
    mediumGrey: '#8e949a',
    darkGrey: '#434343',
    veryDarkGrey: '#3c3c3c',
    black: '#323232',
    blueGrey: '#373B47',
    darkBlueGrey: '#292d3b',
    lightBlueGrey: '#484B5A',
    blue: '#0b9fd8',
    orange: '#e4743a',
    green: '#3ad29f',
    red: '#e64242',
    yellow: '#fbb800'
};

export const COLOR = {
    br24: BR24COLORS,
    pageBackground: 'white',
    contentBackground: '#EEF0F1',
    contentFont: BR24COLORS.black,
    contentBox: BR24COLORS.veryLightGrey,
    error: BR24COLORS.red,
    warning: BR24COLORS.orange,
    info: BR24COLORS.green,
    overlay: BR24COLORS.darkBlueGrey,
    overlayGradient: `radial-gradient(${BR24COLORS.lightBlueGrey}, ${BR24COLORS.darkBlueGrey})`,
    overlayFont: BR24COLORS.white,
    primary: '#0082C6',
    secondary: '#EEF0F1',
    tertiary: '#C5C5C5',
    captions: {
        [Caption.Coronavirus]: '#D20DE2',
        [Caption.Bayern]: '#1587c6',
        [Caption.Sport]: '#F4B73B',
        [Caption.Netzwelt]: '#DF1868',
        [Caption.Newsletter]: '#7D7D7D',
        [Caption.Wirtschaft]: '#35B873',
        [Caption.Kultur]: '#79CE2E',
        [Caption.Nachrichten]: '#7D7D7D',
        [Caption.Wissen]: '#FD8F25'
    }
};

export const STYLE = {
    default: {
        padding: '0.75rem',
        shadow: '0 0 10px rgba(0,0,0,0.5)',
        radius: '0.65rem',
        navbarHeight: '60px', //BrowserDetector.includesTaskbar ? '80px' : '60px',
        tabHeight: '50px',
        mapHeight: '30vh'
    },
    page: {
        maxWidth: '100vw'
    },
    header: {
        height: 'calc(40vh + 15vw)'
    },
    navbar: {
        sizes: {
            [Navbar.SMALL]: {
                breakpoint: '0px',
                height: '50px'
            },
            [Navbar.MEDIUM]: {
                breakpoint: '1020px',
                height: '60px'
            },
            [Navbar.LARGE]: {
                breakpoint: '1536px',
                height: '70px'
            }
        }
    },
    content: {
        maxWidth: '980px',
        width: '60vw',
        padding: {
            top: '2rem',
            right: '4rem',
            bottom: '0',
            left: '4rem'
        },
        margin: {
            top: '0',
            right: '5%',
            bottom: '0',
            left: '5%'
        }
    }
};

export const popOut = keyframes`
    0%{transform:scale(0)}
    80%{transform:scale(1.1)}
    100%{transform:scale(1)}
`;
export const fadeIn = keyframes`
    0%{opacity:0}
    100%{opacity:1}
`;

export const pulse = keyframes`
    0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
`;

export const pulseImg = keyframes`
    0% {
		filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0.25));
    }
	70% {
		filter: drop-shadow(10px 10px 100px rgba(255, 255, 255, 0));
	}
	100% {
		filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0));
	}
`;

export const elasticSpin = (endAngle = 0) => keyframes`
  0% {
    transform: rotate(${endAngle}deg);
  }
  30% {
    transform: rotate(${endAngle - 20}deg);
  }
  60% {
    transform: rotate(${endAngle + 20}deg);
  }
  80% {
    transform: rotate(${endAngle - 10}deg);
  }
  100% {
    transform: rotate(${endAngle}deg);
  }
`;

export const noise = (size = 0) => keyframes`
  0% {
    transform: scaleY(calc(1 - (var(--size,${size}))));
  }
  100% {
    transform: scaleY(calc(1 + (var(--size,${size}))));
  }
`;

export const notify = keyframes`
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
`;
