import React from 'react';
import { HashRouter } from 'react-router-dom';
import { App } from './App';
import { createRoot } from 'react-dom/client';

const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

const container = document.querySelector('main');
if (container) {
    const root = createRoot(container);
    root.render(
        <HashRouter>
            <App />
        </HashRouter>
    );
}
