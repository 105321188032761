import { Playlist, PlayType } from '../models';
import { ISoundAttributes, PlayerCore } from 'web-audio-api-player';
import { BrowserDetector } from '../utils/browserDetection';
import { defaultConfig } from '../config';

interface JinglePlayer {
    audio: string;
    type: PlayType;
}

interface JingleList {
    intro: JinglePlayer;
    outro: JinglePlayer;
    pling: JinglePlayer;
}

export class PlayListPlayer {
    private jingleList: JingleList;
    private playlist: Playlist;
    private player: PlayerCore;
    private playingJingle?: string;
    private automated?: boolean;
    private silence?: HTMLAudioElement;
    private handleSeconds: (seconds: number, index?: number, p?: boolean, t?: PlayType, v?: boolean) => void = () => {};
    private handleEnd: () => void = () => {};

    constructor(playList: Playlist, introSound: string, outroSound: string, plingSound: string) {
        this.jingleList = {
            intro: { audio: introSound, type: PlayType.INTRO },
            outro: { audio: outroSound, type: PlayType.OUTRO },
            pling: { audio: plingSound, type: PlayType.PLING }
        };
        const items = JSON.parse(JSON.stringify(playList));
        this.playlist = items;
        this.player = new PlayerCore({
            playNextOnEnded: true,
            preload: BrowserDetector.isIOs(),
            playingProgressIntervalTime: 500
        });
        this.initSilenceAudio();
        this.initPlaylist(items);
    }

    private initPlaylist(items: Playlist) {
        const playlist: ISoundAttributes[] = [
            {
                source: { url: this.jingleList.intro.audio, codec: 'mp3' },
                id: this.jingleList.intro.type,
                onStarted: () => {
                    this.handleSeconds(0, 0, true, PlayType.INTRO);
                    this.playingJingle = this.jingleList.intro.type;
                },
                onResumed: () => {
                    this.handleSeconds(0, 0, true, PlayType.INTRO);
                    this.playingJingle = this.jingleList.intro.type;
                }
            }
        ];
        items.map((item, index) => {
            playlist.push({
                source: { url: item.audioFileUrl, codec: 'mp3' },
                id: index,
                onPlaying: (_, __, seconds) => {
                    //console.error('PLAYING', index, seconds);
                    this.handleSeconds(seconds, index, true, PlayType.BEITRAG, this.automated);
                    this.automated = true;
                },
                onLoading: (progress: number, maximumValue: number, currentValue: number) => {
                    //console.debug('LOADING AUDIO', index, 'PROGRESS', progress);
                },
                onStarted: () => {
                    this.playingJingle = undefined;
                },
                onEnded: () => {
                    //console.error(index, 'ENDED');
                }
            });
            if (index < items.length - 1) {
                playlist.push({
                    source: { url: this.jingleList.pling.audio, codec: 'mp3' },
                    id: this.jingleList.pling.type + index,
                    onStarted: () => {
                        this.playingJingle = this.jingleList.pling.type;
                    }
                });
            }
        });
        playlist.push({
            source: { url: this.jingleList.outro.audio, codec: 'mp3' },
            id: this.jingleList.outro.type,
            onStarted: () => {
                this.playingJingle = this.jingleList.outro.type;
                this.handleSeconds(items.at(-1)?.duration || 0, items.length - 1, true, PlayType.OUTRO);
            },
            onEnded: () => {
                this.handleEnd();
            },
            onResumed: () => {
                this.playingJingle = this.jingleList.outro.type;
                this.handleSeconds(items.at(-1)?.duration || 0, items.length - 1, true, PlayType.OUTRO);
            }
        });
        playlist.forEach((sound) => this.player.addSoundToQueue({ soundAttributes: sound }));
        return playlist;
    }

    public async play(index?: number, seconds?: number) {
        //console.error('PLAY', index, seconds);
        try {
            if (BrowserDetector.isIOs() && defaultConfig.useIOsAudioHack) {
                this.toggleSilence();
            }
            this.automated = false;
            await this.player.play({
                whichSound: index === 0 && seconds === 0 ? PlayType.INTRO : index,
                playTimeOffset: seconds
            });
        } catch (e) {
            console.error('Catched error:', e);
        }
    }

    public pause() {
        this.player.pause();
    }

    public stop() {
        this.player.stop();
    }

    public async reset() {
        try {
            this.player?.reset();
            await this.player?.disconnect();
        } catch (e) {
            console.debug('Could not reset Player... continuing.', e);
        }
    }

    public skip() {
        this.automated = false;
        const currentIndex = this.player.getQueue().findIndex((s) => s.state === 'sound_state_playing');
        const current = this.player.getQueue()[currentIndex];
        const next =
            this.player.getQueue()[
                currentIndex + (this.playingJingle || current?.id === this.playlist.length - 1 ? 1 : 2)
            ];
        const isNextAvailable = !!next;
        if (isNextAvailable) {
            this.player.play({ whichSound: next?.id });
        } else {
            this.player.play({ whichSound: PlayType.INTRO });
        }
    }

    public back() {
        this.automated = false;
        const currentIndex = this.player.getQueue().findIndex((s) => s.state === 'sound_state_playing');
        const previous = this.player.getQueue()[currentIndex - (this.playingJingle ? 1 : 2)];
        const isPreviousAvailable = !!previous;
        if (isPreviousAvailable) {
            this.player.play({ whichSound: previous?.id });
        } else {
            this.player.play({ whichSound: PlayType.INTRO });
        }
    }

    public onPlaying(cb: (seconds: number, index?: number, isPlaying?: boolean) => void) {
        this.handleSeconds = cb;
    }

    public onEnd(cb: () => void) {
        this.handleEnd = cb;
    }

    private initSilenceAudio() {
        if (!this.silence && BrowserDetector.isIOs() && defaultConfig.useIOsAudioHack) {
            console.debug('Not having silence audio, creating');
            this.silence = new Audio();
            this.silence.crossOrigin = 'anonymous';

            this.silence.src = 'media/silence.mp3';
            this.silence.controls = false;
            this.silence.autoplay = false;
            this.silence.id = 'silence';

            document.body.appendChild(this.silence);
        }
    }

    private toggleSilence = () => {
        this.silence?.play();
    };
}
