import { LatLngLiteral } from 'leaflet';
import React from 'react';
import { geocoding } from '@maptiler/client';
import { GeocodingResult } from '../modules/maptiler-geosuggest/types/additionals';

/**
 * Create a random string ID
 * @param length - Length of the id
 * @param except - Id's that should not be generated
 */
export function makeId(length: number, except: string[] = []): string {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (except.includes(result)) {
        return makeId(length, except);
    } else {
        return result;
    }
}

/**
 * Get's the filename of a given path
 * @param path
 * @param withExtension - print with extension (default false)
 */
export const getFilename = (path: string, withExtension = false) =>
    path.split('~')[1].replace(!withExtension ? /(\.).*/ : '', '');

/**
 * Get a random number between two numbers. Ability to exclude a number and round
 * @param min
 * @param max
 * @param round
 * @param except
 */
export const randomBetween = (min: number, max: number, round = false, except?: number | number[]): number => {
    const random = round ? Math.floor(Math.random() * (max - min + 1) + min) : Math.random() * (max - min + 1) + min;
    if (except) {
        if (Array.isArray(except)) {
            return !except.includes(random) ? random : randomBetween(min, max, round, except);
        } else {
            return random !== except ? random : randomBetween(min, max, round, except);
        }
    } else {
        return random;
    }
};

/**
 * Capitalize word (First letter is capital)
 * @param word
 */
export const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

/**
 * Check if current state of page is english
 */
export const isEnglish = () => location.pathname.includes('/en');

/**
 * Change language by either adding or removing "/en"
 */
export const changeLang = () => {
    if (isEnglish()) {
        window.location.href = window.location.href.replace('/en', '');
    } else {
        const url = window.location.href.replace(/([a-zA-Z0-9\s_\\.\-\(\):])+(.html)$/, '');
        let suffix = 'en';
        if (url[url.length - 1] !== '/') {
            suffix = '/' + suffix;
        }
        window.location.href = url + suffix;
    }
};
/**
 * Returns correct path (with prepended "../" if its english)
 * @param assetPath
 */
export const path = (assetPath?: string) => `${isEnglish() ? '../' : ''}${assetPath}`;

export const getPageWidth = () => {
    const boundingBox = document.body.getBoundingClientRect();
    return boundingBox.width;
};

export const getNavbarHeight = (
    sizes: { [key: number]: { breakpoint: string; height: string } },
    pageWidth: number
) => {
    return Object.values(sizes).reduce((acc, obj) => (pageWidth > parseInt(obj.breakpoint) ? obj : acc))['height'];
};

/**
 * Array.map function for objects
 * @param object - Object to map through
 * @param mapFn - Classic map function
 */
export const mapObj = <T extends { [key: string]: any }>(
    object: T,
    mapFn: (key: string, value: T[keyof T]) => T[keyof T]
): T =>
    Object.keys(object).reduce(function (result, key) {
        (result as { [key: string]: any })[key] = mapFn(key, object[key]);
        return result;
    }, {} as T);

export const getDetailsFromLatLng = async (latlng: LatLngLiteral) => {
    const searchResult = await geocoding.reverse([latlng.lng, latlng.lat]);
    const results = searchResult.features as GeocodingResult[];
    let city: string | undefined = undefined;
    let district: string | undefined = undefined;

    if (results.length > 0) {
        let r = 0,
            rl = results.length;
        for (; r < rl; r += 1) {
            const result = results[r];
            if (result.place_type[0] === 'municipal_district' || result.place_type[0] === 'municipality') {
                city = result.text;
            }
            if (result.place_type[0] === 'county') {
                district = result.text;
            }
        }
    }

    return { city, district };
};

export const getClosest = <T = { [key: string]: any }>(arr: T[], key: keyof T, goal: number = 0) =>
    arr.length > 0
        ? arr.reduce((prev, curr) => {
              let prevDiff = Math.abs((prev[key] as any) - goal);
              let currDiff = Math.abs((curr[key] as any) - goal);

              if (prevDiff == currDiff) {
                  return prev[key] > curr[key] ? prev : curr;
              } else {
                  return currDiff < prevDiff ? curr : prev;
              }
          })
        : undefined;

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Flattens all child elements into a single list
export const flatten = (children: any, flat = []): any[] => {
    flat = [...flat, ...React.Children.toArray(children)] as any;

    if (children.props && children.props.children) {
        return flatten(children.props.children, flat);
    }

    return flat;
};

// Strips all circular references and internal fields
export const simplify = (children?: any) => {
    if (children?.type) {
        const flat = flatten(children);

        return flat.map(({ key, ref, type, props: { children, ...props } }) => ({
            key,
            ref,
            type,
            props
        }));
    } else {
        return children;
    }
};

export const compareDeep = (a: any, b: any) => {
    return JSON.stringify(simplify(a)) === JSON.stringify(simplify(b));
};

export const isApproxEqual = (v1: number, v2 = 0, epsilon = 0.001) => Math.abs(v1 - v2) <= epsilon;

export const cleanedCounty = (county?: string) => county?.replace('Landkreis ', '').toLowerCase();
