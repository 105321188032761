import React, { useEffect } from 'react';
import { Page } from '../../components/presenter/Page';
import { Flex, H2, P } from '../../styles/styledComponents';
import { Button } from '../../components/presenter/Button';
import { COLOR, mobile, STYLE } from '../../styles/variables';
import { useGlobal } from '../../logic/main';
import { defaultConfig } from '../../config';
import { Slider } from '@material-ui/core';
import styled from 'styled-components';
import { BrowserDetector } from '../../utils/browserDetection';
import { Level } from '../../models';
import { Select } from '../../components/presenter/Select';
import { track } from '../../logic/tracker';

const SliderWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-flow: row;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    > span:nth-child(1) {
        flex: 1;
    }

    > div:nth-child(2) {
        flex: 0.1;
    }

    .MuiSlider-rail {
        height: 3px;
    }

    .MuiSlider-track {
        height: 3px;
    }

    @media ${mobile} {
        width: auto;
    }
`;

export const Settings: React.FC = () => {
    const [
        { userLocation, coverage, maxNewsAge },
        { getPlaylist, resetAll, getCoverage, setMaxNewsAge, setNotification }
    ] = useGlobal(
        (state) => ({
            userLocation: state.userLocation,
            maxNewsAge: state.maxNewsAge,
            maxPlaylistDuration: state.maxPlaylistDuration,
            coverage: state.coverage
        }),
        (actions) => ({
            getPlaylist: actions.getPlaylist,
            resetAll: actions.resetAll,
            getCoverage: actions.getCoverage,
            setMaxNewsAge: actions.setMaxNewsAge,
            setMaxPlaylistDuration: actions.setMaxPlaylistDuration,
            setNotification: actions.setNotification
        })
    );

    useEffect(() => {
        if (coverage.length === 0) {
            getCoverage();
        }
    }, [userLocation]);

    function valuetext(value: string | number, mark: string) {
        return `${value} ${mark}`;
    }

    return (
        <Page center={true} skipMargin={true}>
            <H2 color="black" skipMargin={true} style={{ marginBottom: '0.25rem', marginTop: '0.75rem' }}>
                Ihr Interessen-Radius für {userLocation?.name}:{' '}
                {userLocation?.radiusRounded || defaultConfig.defaultRadius} km
            </H2>
            <Flex>
                <H2 color="black" skipMargin={true} style={{ marginBottom: '0.25rem' }}>
                    {BrowserDetector.isPortrait ? 'Max.' : 'Maximales'} Nachrichten-Alter:
                </H2>
                <SliderWrapper>
                    {BrowserDetector.isPortrait ? (
                        <Select
                            title="Nachrichtenalter einstellen"
                            options={[12, 24, 36, 48, 60, 72, 84, 96, 108, 120]}
                            onSelect={(value) => setMaxNewsAge(Number(value), true)}
                            selectedOption={maxNewsAge}
                        />
                    ) : (
                        <Slider
                            title="Nachrichtenalter einstellen"
                            valueLabelDisplay="off"
                            aria-label="Max-Age"
                            valueLabelFormat={(v) => valuetext(v, 'Std.')}
                            value={maxNewsAge}
                            min={12}
                            max={120}
                            onChange={(_, value) => setMaxNewsAge(value as number)}
                            onChangeCommitted={(_, value) => setMaxNewsAge(value as number, true)}
                        />
                    )}

                    <H2 color="black" skipMargin={true} style={{ marginBottom: '0.25rem' }}>
                        {!BrowserDetector.isPortrait ? maxNewsAge : ''} Std.
                    </H2>
                </SliderWrapper>
            </Flex>
            <P color="black" style={{ padding: `0 calc(${STYLE.default.padding} / 2)`, lineHeight: '1.2rem' }}>
                Je größer Sie Radius und Alter wählen, desto mehr Meldungen erhalten Sie.
            </P>
            {!userLocation?.isSet && (
                <Button
                    color={'white'}
                    bgColor={COLOR.primary}
                    outlineColor={COLOR.primary}
                    onClick={() => {
                        if (coverage.length > 0) {
                            track('start', 'startedVia', 'button');
                            getPlaylist(true);
                        } else {
                            setNotification(
                                'Leider liegen mit Ihren aktuellen Einstellungen keine neuen Meldungen vor. Bitte vergrößern Sie Ihren Interessen-Radius oder das Nachrichten-Alter oder kommen Sie zu einem späteren Zeitpunkt wieder zurück.',
                                Level.WARNING
                            );
                        }
                    }}
                >
                    Mein Regional-Update erstellen
                </Button>
            )}
            {userLocation?.isSet && (
                <Button color={'white'} bgColor={COLOR.primary} outlineColor={COLOR.primary} onClick={() => resetAll()}>
                    Mit neuem Ort starten
                </Button>
            )}
        </Page>
    );
};
