import * as store from 'store';
import { Location, Playlist } from '../models';

export enum LocalStorageKeys {
    showIntro = 'showIntro',
    showOnboarding = 'showOnboarding',
    location = 'location',
    playlist = 'playlist',
    maxAge = 'maxAge',
    maxDuration = 'maxDuration',
    annotator = 'annotator',
    usageTime = 'usageTime',
    didFeedback = 'didFeedback'
}

type Key = LocalStorageKeys | string;

const createLSGetter =
    <T = string>(key: Key) =>
    (): T | undefined =>
        store.get(key);

const createLSSetter =
    <T>(key: Key) =>
    (newValue?: T) => {
        store.set(key, newValue);
    };

const createLSDeleter = (key: Key) => () => {
    store.remove(key);
};

export class LocalStorage {
    // getters
    public static getLocation = createLSGetter<Location>(LocalStorageKeys.location);
    public static getPlaylist = createLSGetter<Playlist>(LocalStorageKeys.playlist);
    public static shouldShowIntro = createLSGetter<boolean>(LocalStorageKeys.showIntro);
    public static shouldShowOnboarding = createLSGetter<boolean>(LocalStorageKeys.showOnboarding);
    public static maxAge = createLSGetter<number>(LocalStorageKeys.maxAge);
    public static maxDuration = createLSGetter<number>(LocalStorageKeys.maxDuration);
    public static annotator = createLSGetter<string>(LocalStorageKeys.annotator);
    public static didFeedback = createLSGetter<boolean>(LocalStorageKeys.didFeedback);
    public static usageTime = createLSGetter<number>(LocalStorageKeys.usageTime);
    // setters
    public static setLocation = createLSSetter<Location>(LocalStorageKeys.location);
    public static setPlaylist = createLSSetter<Playlist>(LocalStorageKeys.playlist);
    public static setShowIntro = createLSSetter<boolean>(LocalStorageKeys.showIntro);
    public static setShowOnboarding = createLSSetter<boolean>(LocalStorageKeys.showOnboarding);
    public static setMaxAge = createLSSetter<number>(LocalStorageKeys.maxAge);
    public static setMaxDuration = createLSSetter<number>(LocalStorageKeys.maxDuration);
    public static setAnnotator = createLSSetter<string>(LocalStorageKeys.annotator);
    public static setDidFeedback = createLSSetter<boolean>(LocalStorageKeys.didFeedback);
    public static setUsageTime = createLSSetter<number>(LocalStorageKeys.usageTime);
    // resetters
    public static resetLocation = createLSDeleter(LocalStorageKeys.location);
    public static resetPlaylist = createLSDeleter(LocalStorageKeys.playlist);
    public static resetShowIntro = createLSDeleter(LocalStorageKeys.showIntro);
    public static resetShowOnboarding = createLSDeleter(LocalStorageKeys.showOnboarding);
    public static resetMaxAge = createLSDeleter(LocalStorageKeys.maxAge);
    public static resetMaxDuration = createLSDeleter(LocalStorageKeys.maxDuration);
}
