/* global window */
import * as React from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { geocoding, GeocodingOptions, config, GeocodingSearchResult } from '@maptiler/client';

import defaults from './defaults';
import filterInputAttributes from './filter-input-attributes';

import Input from './input';
import SuggestList from './suggest-list';
import IProps from './types/props';
import { GeocodingResult } from './types/additionals';

interface IState {
    readonly isSuggestsHidden: boolean;
    readonly isLoading: boolean;
    readonly ignoreBlur: boolean;
    readonly userInput: string;
    readonly activeSuggest: null | GeocodingResult;
    readonly suggests: GeocodingResult[];
}

/**
 * Entry point for the Geosuggest component
 */
export default class extends React.Component<IProps, IState> {
    /**
     * Default values for the properties
     */
    static defaultProps: IProps = defaults;

    /**
     * A timer
     */
    timer?: number;

    /**
     * Geocoder
     */
    geocoder?: typeof geocoding;

    /**
     * The input component
     */
    input: Input | null = null;

    /**
     * Id for the suggestions list
     */
    listId: string;

    /**
     * The constructor. Sets the initial state.
     */
    // eslint-disable-next-line max-statements
    constructor(props: IProps) {
        super(props);

        config.apiKey = this.props.apiKey;

        this.state = {
            activeSuggest: null,
            ignoreBlur: false,
            isLoading: false,
            isSuggestsHidden: true,
            suggests: [],
            userInput: props.initialValue || ''
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onAfterInputChange = this.onAfterInputChange.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrev = this.onPrev.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSuggestMouseDown = this.onSuggestMouseDown.bind(this);
        this.onSuggestMouseOut = this.onSuggestMouseOut.bind(this);
        this.onSuggestNoResults = this.onSuggestNoResults.bind(this);
        this.hideSuggests = this.hideSuggests.bind(this);
        this.selectSuggest = this.selectSuggest.bind(this);
        this.listId = `geosuggest__list${props.id ? `--${props.id}` : ''}`;

        if (props.queryDelay) {
            this.onAfterInputChange = debounce(this.onAfterInputChange, props.queryDelay);
        }
    }

    /**
     * Change inputValue if prop changes
     */
    componentDidUpdate(prevProps: IProps): void {
        if (prevProps.initialValue !== this.props.initialValue) {
            this.setState({ userInput: this.props.initialValue || '' });
        }
    }

    /**
     * Called on the client side after component is mounted.
     * Maptiler api sdk object will be obtained and cached as a instance property.
     * Necessary objects of Maptiler api will also be determined and saved.
     */
    componentDidMount(): void {
        if (typeof window === 'undefined') {
            return;
        }

        this.geocoder = geocoding;
    }

    /**
     * When the component will unmount
     */
    componentWillUnmount(): void {
        clearTimeout(this.timer);
    }

    /**
     * When the input changed
     */
    onInputChange(userInput: string): void {
        if (!userInput) {
            if (this.props.onSuggestSelect) {
                this.props.onSuggestSelect();
            }
        }
        this.setState({ userInput }, this.onAfterInputChange);
    }

    /**
     * On After the input got changed
     */
    onAfterInputChange(): void {
        this.showSuggests();
        if (this.props.onChange) {
            this.props.onChange(this.state.userInput);
        }
    }

    /**
     * When the input gets focused
     */
    onInputFocus(): void {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
        this.showSuggests();
    }

    /**
     * When the input gets blurred
     */
    onInputBlur(): void {
        if (!this.state.ignoreBlur) {
            this.hideSuggests();
        }
    }

    onNext(): void {
        this.activateSuggest('next');
    }

    onPrev(): void {
        this.activateSuggest('prev');
    }

    onSelect(): void {
        this.selectSuggest(this.state.activeSuggest!);
    }

    onSuggestMouseDown(): void {
        this.setState({ ignoreBlur: true });
    }

    onSuggestMouseOut(): void {
        this.setState({ ignoreBlur: false });
    }

    onSuggestNoResults(): void {
        if (this.props.onSuggestNoResults) {
            this.props.onSuggestNoResults(this.state.userInput);
        }
    }

    /**
     * Focus the input
     */
    focus(): void {
        if (this.input) {
            this.input.focus();
        }
    }

    /**
     * Blur the input
     */
    blur(): void {
        if (this.input) {
            this.input.blur();
        }
    }

    /**
     * Update the value of the user input
     */
    update(userInput: string): void {
        this.setState({ userInput });
        if (this.props.onChange) {
            this.props.onChange(userInput);
        }
    }

    /*
     * Clear the input and close the suggestion pane
     */
    clear(): void {
        this.setState({ userInput: '' }, this.hideSuggests);
    }

    /**
     * Search for new suggests
     */
    // eslint-disable-next-line complexity, max-statements
    searchSuggests(): void {
        if (!this.state.userInput) {
            this.updateSuggests();
            return;
        }

        const options: GeocodingOptions = {};
        const inputLength = this.state.userInput.length;
        const isShorterThanMinLength = this.props.minLength && inputLength < this.props.minLength;

        if (isShorterThanMinLength || this.state.userInput?.startsWith(' ')) {
            this.updateSuggests();
            return;
        }

        const { bounds, types, country } = this.props;

        /* eslint-disable curly */
        if (bounds) options.bbox = bounds;
        if (types) options.types = types;
        if (country) options.country = Array.isArray(country) ? country : [country];
        /* eslint-enable curly */

        this.setState({ isLoading: true }, async () => {
            const suggests = await this.geocoder?.forward(this.state.userInput, options);
            this.setState({ isLoading: false });
            this.updateSuggests(
                suggests, // can be null
                () => {
                    if (this.props.autoActivateFirstSuggest && !this.state.activeSuggest) {
                        this.activateSuggest('next');
                    }
                }
            );
        });
    }

    /**
     * Update the suggests
     */
    updateSuggests(geoSuggests?: GeocodingSearchResult, callback: () => void = () => {}): void {
        const suggests: GeocodingResult[] = [];
        const { skipSuggest } = this.props;
        let activeSuggest: GeocodingResult | null;

        geoSuggests?.features.forEach((suggest) => {
            if (skipSuggest && !skipSuggest(suggest as any)) {
                suggests.push(suggest as any);
            }
        });

        activeSuggest = this.updateActiveSuggest(suggests);

        if (this.props.onUpdateSuggests) {
            this.props.onUpdateSuggests(suggests, activeSuggest);
        }
        this.setState({ suggests, activeSuggest }, callback);
    }

    /**
     * Return the new activeSuggest object after suggests have been updated
     */
    updateActiveSuggest(suggests: GeocodingResult[] = []): GeocodingResult | null {
        let activeSuggest = this.state.activeSuggest;

        if (activeSuggest) {
            const newSuggest = suggests.filter(
                (listedSuggest) =>
                    activeSuggest && activeSuggest.id === listedSuggest.id && activeSuggest.type === listedSuggest.type
            )[0];

            activeSuggest = newSuggest || null;
        }

        return activeSuggest;
    }

    /**
     * Show the suggestions
     */
    showSuggests(): void {
        this.searchSuggests();
        this.setState({ isSuggestsHidden: false });
    }

    /**
     * Hide the suggestions
     */
    hideSuggests(): void {
        if (this.props.onBlur) {
            this.props.onBlur(this.state.userInput);
        }
        this.timer = window.setTimeout(() => {
            this.setState({
                activeSuggest: null,
                isSuggestsHidden: true
            });
        }, 100);
    }

    /**
     * Activate a new suggest
     */
    // eslint-disable-next-line complexity, max-statements
    activateSuggest(direction: 'next' | 'prev'): void {
        if (this.state.isSuggestsHidden) {
            this.showSuggests();
            return;
        }

        const suggestsCount = this.state.suggests.length - 1;
        const next = direction === 'next';
        let newActiveSuggest = null;
        let newIndex = 0;
        let i = 0;

        for (i; i <= suggestsCount; i++) {
            if (this.state.suggests[i] === this.state.activeSuggest) {
                newIndex = next ? i + 1 : i - 1;
            }
        }

        if (!this.state.activeSuggest) {
            newIndex = next ? 0 : suggestsCount;
        }

        if (newIndex >= 0 && newIndex <= suggestsCount) {
            newActiveSuggest = this.state.suggests[newIndex];
        }

        if (this.props.onActivateSuggest) {
            this.props.onActivateSuggest(newActiveSuggest);
        }

        this.setState({ activeSuggest: newActiveSuggest });
    }

    /**
     * When an item got selected
     */
    // eslint-disable-next-line complexity
    selectSuggest(suggestToSelect: GeocodingResult): void {
        let suggest: GeocodingResult = suggestToSelect;

        if (!suggestToSelect && this.props.autoActivateFirstSuggest && this.state.suggests.length > 0) {
            suggest = this.state.suggests[0];
        }

        this.setState({
            isSuggestsHidden: true,
            userInput: suggest?.text
        });

        if (suggest?.center) {
            this.setState({ ignoreBlur: false });
            if (this.props.onSuggestSelect) {
                this.props.onSuggestSelect(suggest);
            }
            return;
        }
    }

    /**
     * Geocode a suggest
     */

    /**
     * Render the view
     */
    render(): JSX.Element {
        const attributes = filterInputAttributes(this.props);
        const classes = classnames('geosuggest', this.props.className, {
            'geosuggest--loading': this.state.isLoading
        });
        const input = (
            <Input
                className={this.props.inputClassName}
                ref={(i): Input | null => (this.input = i)}
                value={this.state.userInput}
                doNotSubmitOnEnter={!this.state.isSuggestsHidden}
                ignoreTab={this.props.ignoreTab}
                ignoreEnter={this.props.ignoreEnter}
                style={this.props.style && this.props.style.input}
                onChange={this.onInputChange}
                onFocus={this.onInputFocus}
                onBlur={this.onInputBlur}
                onKeyDown={this.props.onKeyDown}
                onKeyPress={this.props.onKeyPress}
                inputType={this.props.inputType}
                onNext={this.onNext}
                onPrev={this.onPrev}
                onSelect={this.onSelect}
                onEscape={this.hideSuggests}
                isSuggestsHidden={this.state.isSuggestsHidden}
                activeSuggest={this.state.activeSuggest}
                label={this.props.label}
                id={this.props.id}
                listId={this.listId}
                {...attributes}
            />
        );
        const suggestionsList = (
            <SuggestList
                isHidden={this.state.isSuggestsHidden}
                style={this.props.style && this.props.style.suggests}
                suggestItemStyle={this.props.style && this.props.style.suggestItem}
                userInput={this.state.userInput}
                isHighlightMatch={Boolean(this.props.highlightMatch)}
                suggestsClassName={this.props.suggestsClassName}
                suggestItemClassName={this.props.suggestItemClassName}
                suggests={this.state.suggests}
                hiddenClassName={this.props.suggestsHiddenClassName}
                suggestItemActiveClassName={this.props.suggestItemActiveClassName}
                activeSuggest={this.state.activeSuggest}
                onSuggestNoResults={this.onSuggestNoResults}
                onSuggestMouseDown={this.onSuggestMouseDown}
                onSuggestMouseOut={this.onSuggestMouseOut}
                onSuggestSelect={this.selectSuggest}
                renderSuggestItem={this.props.renderSuggestItem}
                listId={this.listId}
            />
        );

        return (
            <div className={classes} id={this.props.id}>
                <div className="geosuggest__input-wrapper">{input}</div>
                <div className="geosuggest__suggests-wrapper">{suggestionsList}</div>
            </div>
        );
    }
}
