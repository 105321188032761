import { createGlobalStyle } from 'styled-components';
import { COLOR, STYLE, mobile } from './variables';

export const GlobalStyle = createGlobalStyle`
  :root {
    --app-height: 100%;
  }
  
  html {
    margin: 0;
    font-size: clamp(0.8rem, 1.45vh, 0.95rem);
    line-height: 1.6;

    @media ${mobile} {
      line-height: 1.5;
      font-size: clamp(0.8rem, 1.4vh, 0.95rem);
      height: 100vh;
      height: var(--app-height);
    }
  }

  body {
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    font-family: 'Open Sans', 'OpenSans', Helvetica, sans-serif;
    background-color: ${COLOR.pageBackground};
    height: 100vh;
    height: var(--app-height);
  }

  button{
    font-family: 'Open Sans', 'OpenSans', Helvetica, sans-serif;
  }

  main {
    margin: 0 auto;
    display: block;
    max-width: ${STYLE.page.maxWidth};
    background: ${COLOR.contentBackground};
    height: var(--app-height);
  }

  a {
    color: white;
  }

  b {
    font-weight: 700;
  }

  * {
    box-sizing: border-box;
  }

  .line {
    opacity: 0.5;
    border-color: white !important;
  }

  .ReactModalPortal {
    display: initial;

    .ReactModal__Overlay {
      width: 100vw;
      height: 100vh;
      height: var(--app-height);
      background-color: rgba(0, 0, 0, 0.5) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box;
    }
  }

  .root {
    overflow-x: hidden;
  }

  .MuiPopover-root {
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.25);

    .MuiPopover-paper {
      padding: 0.75rem;
      margin-top: -1rem;
    }

  }

  .react-swipeable-view-container {
    height: 100%
  }

  .MuiTabs-scrollButtonsDesktop {
    display: none !important;
  }

  .MuiSlider-valueLabel {
    transform: scale(1) translateY(60px) !important;
    width: max-content !important;
    left: unset !important;

    > span {
      background-color: transparent !important;
      width: auto !important;
      height: auto !important;
      transform: none !important;
      display: inline !important;
      border-radius: 0 !important;

      > span {
        color: grey;
        font-size: 1rem;
        font-weight: bold;
        transform: none;
      }

    }

  }

  .MuiSlider-rail {
    height: 5px !important;
    background-color: #c4c4c4 !important;
  }

  .MuiSlider-thumb {
    background-color: ${COLOR.primary} !important;
    width: 15px !important;
    height: 15px !important;
    margin-top: -5px !important;
    margin-top: -5px !important;
    margin-left: -7px !important;
  }

  .MuiSlider-track {
    background-color: ${COLOR.primary} !important;
    height: 5px !important;
  }
`;
