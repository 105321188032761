import React, { ReactNode } from 'react';
import { InitPage } from '../components/presenter/Page';
import { A, H3, P } from '../styles/styledComponents';
import { useGlobal } from '../logic/main';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import styled from 'styled-components';
import { STYLE } from '../styles/variables';
import { track } from '../logic/tracker';

const StyledAccordion = styled(Accordion)`
    .MuiAccordionDetails-root {
        p {
            margin-top: 0;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
    }

    .MuiAccordion-rounded:last-child {
        border: none;
    }

    &.Mui-expanded {
        margin: initial !important;
        box-shadow: none !important;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    &.MuiPaper-elevation1 {
        box-shadow: none !important;
    }
`;

export const FAQs: React.FC = () => {
    const [_, { goBack }] = useGlobal(
        (_) => undefined,
        (actions) => ({
            goBack: actions.goBack
        })
    );

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        if (isExpanded) {
            track('info', 'faqDetails', panel);
        }
    };

    const faqs: { title: string; text: ReactNode; hide?: boolean }[] = [
        {
            title: 'Was ist das BR Regional-Update?',
            text: (
                <>
                    Das BR Regional-Update liefert Ihnen die neuesten Radionachrichten individuell aus Ihrer Region.
                    Dazu stellen wir Beiträge aus dem
                    <A
                        href="https://www.br.de/radio/bayern1/inhalt/bayern-regional/index.html"
                        inline={true}
                        target="_blank"
                    >
                        Nachrichtenangebot von Bayern 1
                    </A>{' '}
                    anhand ihrer geografischen Interessen neu für Sie zusammen. Mit diesem Prototypen erproben wir vom
                    <A href="https://www.br.de/extra/ai-automation-lab/index.html" inline={true} target="_blank">
                        AI + Automation Lab
                    </A>
                    , wie der Bayerische Rundfunk seinen öffentlich-rechtlichen Auftrag, Nachrichten für die Menschen in
                    Bayern zur Verfügung zu stellen, noch besser erfüllen kann.
                    <A
                        href="https://medium.com/br-next/publikumszentrierte-news-mit-ki-84ae932f6304"
                        inline={true}
                        target="_blank"
                    >
                        Hier können Sie noch mehr zu den Hintergründen nachlesen
                    </A>
                    . Wir entwickeln das Projekt schrittweise weiter und testen es immer wieder mit Nutzer:innen, um so
                    ein ideales Angebot zu schaffen. Die Anwendung stellt daher einen Zwischenstand unserer Arbeit dar,
                    den wir Ihnen – trotz qualitativer Limitierungen, dazu unten mehr – bereits jetzt zeigen möchten.
                    Wir freuen uns über konstruktives Feedback dazu von Ihnen.
                </>
            )
        },
        {
            title: 'Wie funktioniert das BR Regional-Update?',
            text: (
                <>
                    Um die Anwendung zu nutzen, müssen Sie entweder Ihren Standort freigeben oder einen Wunschort
                    (innerhalb Bayerns) angeben. Im zweiten Schritt legen Sie fest, aus welchem Umkreis Sie Nachrichten
                    erhalten wollen und wie alt diese maximal sein dürfen. Damit ist die Einrichtung abgeschlossen und
                    Sie erhalten ab sofort bei jedem Besuch der Website ihr persönliches Regional-Update.
                </>
            )
        },
        {
            title: 'Was bedeutet „Prototyp“, bzw. „public beta“?',
            text: (
                <>
                    Das BR Regional-Update ist kein fertiges Produkt, sondern ein Projekt-Zwischenstand, der sichtbar
                    macht, woran wir aktuell arbeiten. Manche Funktionen und Inhalte sind daher noch nicht fehlerfrei.
                    <A
                        href="https://medium.com/br-next/publikumszentrierte-news-mit-ki-84ae932f6304"
                        inline={true}
                        target="_blank"
                    >
                        Mehr Informationen dazu finden Sie hier
                    </A>
                    .
                </>
            )
        },
        {
            title: 'Wie arbeitet der Algorithmus?',
            text: (
                <>
                    Wir nutzen als Datengrundlage
                    <A
                        href="https://www.br.de/radio/bayern1/inhalt/mittags-in-regionalstreaming-100.html"
                        inline={true}
                        target="_blank"
                    >
                        die Regionalnachrichten von Bayern 1
                    </A>
                    , die individuell für fünf bayerische Regionen halbstündlich im Radio laufen. Der Algorithmus scannt
                    diese fünf Audiospuren fortlaufend, schneidet sie in die einzelnen darin enthaltenen Meldungen und
                    ergänzt den Handlungsort vollautomatisiert als Geo-Koordinaten. Diese geo-getaggten Audioclips
                    stehen dann für maximal 120 Stunden in unserem Datenpool zur Verfügung und werden Ihnen basierend
                    auf Ihren Einstellungen ausgespielt. Die Sortierung der Nachrichten richtet sich dabei
                    ausschließlich nach der Entfernung zu Ihrem eingestellten Ort – je näher, desto weiter vorne wird
                    die Nachricht eingereiht. Eine anderweitige Sortierung oder Gewichtung findet im Moment nicht statt.
                </>
            )
        },
        {
            title: 'Warum sind manche Meldungen falsch geschnitten oder nicht richtig verortet?',
            text: (
                <>
                    Da es sich bei dieser Anwendung um einen Prototypen in Erprobung handelt, der noch nicht die
                    Qualitätsansprüche an ein fertiges Produkt erfüllt, kann es vorkommen, dass die zugrundeliegende
                    Automatisierung nicht fehlerfrei arbeitet und einzelne Meldungen oder Daten nicht richtig erkennt
                    oder zuschneidet.
                </>
            )
        },
        {
            title: 'Wieso finde ich hier manche Meldungen, die im Radio liefen, nicht?',
            text: (
                <>
                    Wir nutzen eine automatisierte Fehlererkennung, um falsch zugeschnittene Meldungen nach Möglichkeit
                    auszusortieren. Daher kann es vorkommen, dass einige Radiomeldungen es nicht in das BR
                    Regional-Update schaffen.
                </>
            )
        },
        {
            title: 'Wieso erhalte ich nur Meldungen aus Bayern?',
            text: (
                <>
                    Das BR Regional-Update verfolgt das Ziel, eine digitale, personalisierte Ergänzung zum
                    Hörfunkprogramm des BR mit starkem Fokus auf Regionalisierung zu bieten. Als Datenbasis für dieses
                    Projekt nutzen wir daher aktuell ausschließlich
                    <A
                        href="https://www.br.de/radio/bayern1/inhalt/mittags-in-regionalstreaming-100.html"
                        inline={true}
                        target="_blank"
                    >
                        die Regionalnachrichten von Bayern 1
                    </A>{' '}
                    aus dem linearen Programm. Aus diesem Grund sind momentan keine überregionalen oder internationalen
                    Meldungen enthalten.
                </>
            )
        },
        {
            title: 'Welche weiteren Funktionen sind geplant?',
            hide: false,
            text: (
                <>
                    Wir haben u.a. durch zwei User-Tests schon viele Ideen für weitere Funktionen vorliegen, z.B. einen
                    Themenfilter oder die Möglichkeit, mehr als einen Ort festzulegen. Im Moment evaluieren wir den
                    Prototypen im Live-Betrieb und “Ist-Zustand”. Falls Sie auch eine Idee oder eine Anregung für das
                    Regional-Update haben, schreiben Sie es uns bitte über die Feedback-Funktion.
                </>
            )
        },
        {
            title: 'Wer ist Ansprechpartner:in für das Projekt?',
            text: (
                <>
                    Bitte wenden Sie sich bei Fragen an das
                    <A href="https://www.br.de/extra/ai-automation-lab/index.html" inline={true} target="_blank">
                        AI + Automation Lab
                    </A>{' '}
                    des Bayerischen Rundfunks. Sie erreichen uns auch
                    <A href="https://twitter.com/BR_AILab" inline={true} target="_blank">
                        via Twitter
                    </A>
                    .
                </>
            )
        }
    ];

    return (
        <InitPage
            onBackClick={goBack}
            innerStyle={{
                margin: `${STYLE.default.padding} 0`,
                height: '50vh'
            }}
            mobileHeight="70vh"
        >
            {faqs.map(({ text, title, hide }, index) =>
                !hide ? (
                    <StyledAccordion key={`faq-${index}`} onChange={handleChange(title)}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <H3>{title}</H3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <P>{text}</P>
                        </AccordionDetails>
                    </StyledAccordion>
                ) : null
            )}
        </InitPage>
    );
};
