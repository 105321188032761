import styled from 'styled-components';
import { COLOR, mobile, STYLE } from './variables';
import { hexOpacity } from '../utils/styling';

export const Article = styled.article``;

export const H1 = styled.h1`
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.3;
    margin-top: 0;
    margin-block-end: 0;
    color: ${({ color = 'black' }) => color}; ;
`;

export const Paper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: white;
    box-shadow: ${STYLE.default.shadow} !important;
    border-radius: ${STYLE.default.radius} !important;
`;

export const ButtonWrapper = styled.div`
    width: max-content;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media ${mobile} {
        margin-bottom: 20px;
    }
`;

export const H2 = styled.h2<{ skipMargin?: boolean }>`
    font-size: 1.4rem;
    font-weight: 600;
    white-space: pre;

    margin: ${({ skipMargin }) => (!skipMargin ? '1rem 0' : 0)};
    margin-block-end: ${({ skipMargin }) => (!skipMargin ? '0.5rem' : 0)};
    margin-block-start: ${({ skipMargin }) => (!skipMargin ? '0.5rem' : 0)};
    color: ${({ color = 'black' }) => color};

    @media ${mobile} {
        font-size: 1.1rem;
        white-space: unset;
    }
`;

export const H3 = styled.h3`
    font-size: 1rem;
    font-weight: 600;
    color: ${({ color = 'black' }) => color}; ;
`;

export const H4 = styled.h4`
    font-size: 1rem;
    margin-block-end: 0;
    font-weight: 600;
    color: ${({ color = 'black' }) => color}; ;
`;

export const H5 = styled.h5`
    font-size: 1rem;
    font-weight: 600;
    color: ${({ color = 'black' }) => color}; ;
`;

export const P = styled.p<{ light?: boolean; color?: string }>`
    opacity: ${({ light }) => (light ? 0.55 : 1)};
    margin-bottom: 1em;
    color: ${({ color = 'black' }) => color};
`;

export const A = styled.a<{ inline?: boolean }>`
    color: ${({ inline }) => (inline ? COLOR.primary : COLOR.br24.mediumGrey)};
    opacity: 1;
    text-decoration: underline;
    cursor: pointer;
    text-decoration: ${({ inline }) => (!inline ? 'underline' : 'none')};

    :before {
        content: '\\00a0';
        text-decoration: none;
        display: inline-block;
    }

    :hover {
        text-decoration: ${({ inline }) => (inline ? 'underline' : 'none')};
    }
`;

export const Strong = styled.strong`
    font-weight: 600;
`;

export const Em = styled.em`
    font-style: italic;
`;

export const Section = styled.section`
    width: ${STYLE.content.width};
    max-width: ${STYLE.content.maxWidth};
    margin: 0 25% 4em 10%;
    color: ${COLOR.contentFont};

    @media ${mobile} {
        max-width: unset;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }
`;

export const Block = styled.div<{ isOverlay?: boolean }>`
    background-color: ${COLOR.contentBackground};
    margin-top: ${({ isOverlay }) => (isOverlay ? '-6rem' : `${STYLE.content.padding.top}`)};
    margin-right: ${STYLE.content.margin.right};
    margin-left: ${STYLE.content.margin.left};
    padding-top: ${({ isOverlay }) => (isOverlay ? `${STYLE.content.padding.top}` : '0')};
    padding-right: ${STYLE.content.padding.right};
    padding-bottom: ${STYLE.content.padding.bottom};
    padding-left: ${STYLE.content.padding.left};

    @media ${mobile} {
        margin-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
    }
`;

export const List = styled.ul`
    list-style: none;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
`;

export const ListItem = styled.li`
    position: relative;
    padding-left: 1.4rem;

    :before {
        display: block;
        position: absolute;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNiA2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Ik0wIDBoNnY2SDB6IiBmaWxsPSIjY2NjY2NjIi8+Cjwvc3ZnPg==);
        background-repeat: no-repeat;
        background-size: contain;
        content: ' ';
        height: 6px;
        left: 0;
        top: 0.55rem;
        width: 6px;
    }
`;

export const Column = styled.section`
    display: flex;
    flex-flow: column;
`;

export const Note = styled.div`
    text-align: right;
    padding: 0.15rem 0.5rem 0 0.5rem;
    font-size: 0.8rem;
    opacity: 0.5;
    color: ${COLOR.secondary};

    @media ${mobile} {
        margin-right: 2rem;
    }
`;

export const ErrorWrapper = styled.div`
    padding: 1em;
    margin-top: 1rem;
    border: 1px solid ${COLOR.error};
    background-color: ${hexOpacity(COLOR.error, 0.25)};
`;

export const Highlight = styled.span<{ color?: string; tColor?: string }>`
    background: ${({ color = COLOR.primary }) => color};
    padding: 0 4px;
    color: ${({ color = COLOR.overlayFont }) => color};
    border-radius: 5px;
    word-break-inside: none;
    box-decoration-break: clone;
`;

export const DashedLine = styled.hr`
    height: calc(${STYLE.default.padding} * 2);
    width: calc(100% + (${STYLE.default.padding} * 2));
    margin-left: -${STYLE.default.padding};
    border: none;
    border-top: 1px dashed black;
    opacity: 0.5;
    margin-block-start: 0;
    margin-block-end: 0;
`;
export const Flex = styled.div<{
    type?: 'column' | 'row';
    gap?: number;
    justify?: 'center' | 'start' | 'end';
    align?: 'center' | 'start' | 'end';
}>`
    display: flex;
    gap: ${({ gap = 20 }) => gap}px;
    flex-flow: ${({ type = 'row' }) => type};
    width: 100%;
    justify-content: ${({ justify = 'center' }) => justify};
    align-items: ${({ align = 'center' }) => align};
`;
