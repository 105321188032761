import React from 'react';
import { InitPage } from '../components/presenter/Page';
import { A, P } from '../styles/styledComponents';
import { useGlobal } from '../logic/main';

export const Info: React.FC = () => {
    const [_, { goBack }] = useGlobal(
        (_) => undefined,
        (actions) => ({
            goBack: actions.goBack
        })
    );

    return (
        <InitPage onBackClick={goBack} style={{ alignItems: 'center', textAlign: 'center' }}>
            <P color="black">
                Bei diesem Projekt handelt es sich um einen "public beta"-Prototypen des
                <A href="https://www.br.de/extra/ai-automation-lab/index.html" inline={true} target="_blank">
                    AI + Automation Lab
                </A>{' '}
                in Zusammenarbeit mit den Regionalredaktionen des Bayerischen Rundfunks. Das bedeutet, dass es sich in
                aktiver Entwicklung und Erprobung befindet, sich noch teilweise oder vollständig verändern kann und
                nicht den üblichen Qualitätsansprüchen des BR an ein finales Produkt genügt.
            </P>
            <P color="black">
                Mehr zu den Hintergründen dieses Projekts finden Sie im
                <A
                    href="https://medium.com/br-next/publikumszentrierte-news-mit-ki-84ae932f6304"
                    inline={true}
                    target="_blank"
                >
                    BR Next Blog
                </A>
                . Bei Fragen erreichen Sie uns
                <A href="https://twitter.com/BR_AILab" inline={true} target="_blank">
                    auf Twitter
                </A>{' '}
                – und
                <A href="https://www.br.de/extra/ai-automation-lab/kontakt-222.html" target="_blank" inline={true}>
                    hier
                </A>{' '}
                finden Sie weitere Kontaktmöglichkeiten.
            </P>
        </InitPage>
    );
};
