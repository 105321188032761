import React, { useState } from 'react';
import { InitPage } from '../components/presenter/Page';
import { A, H2, H3, P } from '../styles/styledComponents';
import { useGlobal } from '../logic/main';
import { COLOR } from '../styles/variables';
import { FeedbackType } from '../models';
import { Button } from '../components/presenter/Button';
import Input from '../components/presenter/Input';
import styled from 'styled-components';
import { FooterWrapper } from '../components/container/Footer';

const Feedbacks = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    gap: 30px;
    font-size: 3.25rem;
    align-items: center;
    justify-content: center;

    a {
        text-decoration: none;
    }
`;

const StyledH2 = styled(H2)`
    font-family: Open Sans SemiBold;
    white-space: normal;
`;

export const Feedback: React.FC = () => {
    const [_, { giveFeedback, goBack }] = useGlobal(
        () => ({}),
        (actions) => ({
            giveFeedback: actions.giveFeedback,
            goBack: actions.goBack
        })
    );

    const [activatedType, setActivatedType] = useState<FeedbackType | undefined>();
    const [customFeedback, setCustomFeedback] = useState('');

    const handleClose = () => {
        giveFeedback(activatedType!, customFeedback);
        setActivatedType(undefined);
        setCustomFeedback('');
        goBack();
    };

    const handleFeedbackType = (type: FeedbackType) => {
        setActivatedType(type);
    };

    const handleCustomFeedback = (value: string) => {
        setCustomFeedback(value);
    };

    const maxFeedback = 400;

    return (
        <InitPage onBackClick={goBack} style={{ alignItems: 'center', textAlign: 'center' }}>
            <StyledH2>Vielen Dank für Ihre Unterstützung</StyledH2>
            <H3>Ihr Feedback hilft uns, diesen Prototypen zu verbessern und weiterzuentwickeln.</H3>
            <P>Wie bewerten Sie das BR Regional-Update allgemein?</P>
            <Feedbacks>
                <A
                    style={{ opacity: activatedType === FeedbackType.Nope ? 1 : 0.25 }}
                    onClick={() => handleFeedbackType(FeedbackType.Nope)}
                >
                    🙁
                </A>
                <A
                    style={{ opacity: activatedType === FeedbackType.Ok ? 1 : 0.25 }}
                    onClick={() => handleFeedbackType(FeedbackType.Ok)}
                >
                    😐
                </A>

                <A
                    style={{ opacity: activatedType === FeedbackType.Happy ? 1 : 0.25 }}
                    onClick={() => handleFeedbackType(FeedbackType.Happy)}
                >
                    😀
                </A>
            </Feedbacks>
            <P>
                Bitte formulieren Sie Ihr Feedback und Anregungen möglichst knapp und konkret (max. {maxFeedback}{' '}
                Zeichen):
            </P>
            <Input
                placeholder={'Optional'}
                placeHolderColor={COLOR.tertiary}
                fullWidth={true}
                align="left"
                value={customFeedback}
                maxLength={maxFeedback}
                multiline={true}
                style={{ textAlign: 'left' }}
                handleValueChange={handleCustomFeedback}
            />

            <FooterWrapper style={{ display: 'block', margin: '20px 0 0' }}>
                Senden Sie uns nur Informationen und Daten, mit deren Verarbeitung durch uns Sie einverstanden sind.
                Weitere Informationen
                <A
                    href="https://www.br.de/unternehmen/service/datenschutz/datenschutz-kontaktaufnahme-100.html"
                    target="_blank"
                >
                    finden Sie hier
                </A>
                .
            </FooterWrapper>

            <Button
                bgColor={COLOR.primary}
                color={'white'}
                onClick={handleClose}
                fullWidth={false}
                disabled={!activatedType}
            >
                Absenden
            </Button>
        </InitPage>
    );
};
