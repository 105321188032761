import React from 'react';
import 'moment/locale/de';
import moment from 'moment';
import styled from 'styled-components';
import { COLOR, STYLE } from '../../styles/variables';

interface Props {
    date: Date | string;
    style?: any;
}

export const Layout = styled.div`
    display: flex;
    width: auto;
    flex-flow: column;
    box-shadow: ${STYLE.default.shadow};
    height: 100%;
    min-width: 65px;
`;

export const Month = styled.div`
    padding: 0.25ch 1.5ch;
    color: white;
    font-size: 1rem;
    background-color: ${COLOR.primary};
`;

export const Day = styled.div`
    color: black;
    padding: 0.5ch;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    font-weight: bold;
    height: 100%;
`;

export const DateIndicator: React.FC<Props> = ({ date, style }) => {
    const month = moment(date).locale('de').format('MMM');
    const day = moment(date).locale('de').format('DD');
    return (
        <Layout style={style}>
            <Month>{month}</Month>
            <Day>{day}</Day>
        </Layout>
    );
};
