import L, { Layer } from 'leaflet';
import '@maplibre/maplibre-gl-leaflet';
import { useMap } from 'react-leaflet';
import React, { ReactChildren, useEffect } from 'react';

export interface IMapboxGLProps extends Partial<L.MapboxGLOptions> {
    children?: ReactChildren;
}

export const MapBoxGLLayer: React.FC<IMapboxGLProps> = ({ accessToken = '', style }) => {
    const map = useMap();
    useEffect(() => {
        if (map) {
            //TODO: loads twice - why?
            const layer = L.maplibreGL({
                style: style
                    ? `${style}?key=${accessToken}`
                    : `https://api.maptiler.com/maps/c32064f0-1d02-4630-ada7-c4ad2f8a6706/style.json?key=${accessToken}`,
                attribution:
                    '\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
                minZoom: 6,
                maxZoom: 13
            } as any) as Layer;
            if (!map.hasLayer(layer)) {
                map.addLayer(layer);
            }
        }
    }, [map]);

    return null;
};

//export default withLeaflet(MapBoxGLLayer);
