import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Circle, MapContainer, Marker, Pane, TileLayer } from 'react-leaflet';
import { Circle as CircleRef, LatLngLiteral, Map as LeafletMap, LatLng, divIcon } from 'leaflet';
import GeometryUtil from 'leaflet-geometryutil';
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';
import { Coords, Marker as MarkerI } from '../../models';
import { MapBoxGLLayer } from './GLMap';
import { markerStyles, minusIcon, NumberMarker, plusIcon } from './marker';
import { RadiusLabel } from './marker/RadiusLabel';
import { renderToString } from 'react-dom/server';
import { isEqual } from 'lodash';
import { BrowserDetector } from '../../utils/browserDetection';
import { defaultConfig } from '../../config';
import { track } from '../../logic/tracker';

const useMaptiler = true;
const radiusCorrection = 1000;

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;

    .leaflet-div-icon {
        background: transparent;
        border: none;
    }

    ${markerStyles}

    #map {
        background-color: #faf7f2;
        overflow: hidden;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .leaflet-control-container {
            .leaflet-control-zoom {
                margin-top: 20px;
            }

            display: none;
        }

        .leaflet-shadow-pane {
            display: none;
        }

        .leaflet-marker-pane {
            opacity: 1;
        }

        .leaflet-interactive {
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
        }
    }
`;

const BG = styled.div`
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
`;

const correction = BrowserDetector.isPortrait ? -125 : -165;

interface Props {
    location?: { name?: string; location?: Coords };
    origin?: { name?: string; location?: Coords };
    isFixed?: boolean;
    radius?: number;
    marker?: MarkerI[];
    minRadius?: number;
    maxRadius?: number;
    onRadiusChange?: (to: number) => void;
    onDisabledRadiusClicked?: (type: 'min' | 'max') => void;
    style?: React.CSSProperties;
    items?: number;
    maxItems?: number;
}

export const MapPresenter: React.FC<Props> = ({
    location,
    isFixed,
    radius,
    origin,
    items,
    maxItems,
    onRadiusChange,
    onDisabledRadiusClicked,
    style,
    marker,
    minRadius,
    maxRadius
}) => {
    const circleRef = useRef<CircleRef<any>>();
    const [map, setMap] = useState<LeafletMap>();
    const [isMaxRadius, setIsMaxRadius] = useState<boolean>(false);
    const [isMinRadius, setIsMinRadius] = useState<boolean>(false);
    const defaultRadius = 2000;
    const defaultLocation = { lat: 48.1351, lng: 11.582 };

    useEffect(() => {
        if (location && location.location) {
            setLatLng(location.location);
        }
        setIsMinRadius((radius || minRadius || 2) <= (minRadius || 2) * 1000);
        setIsMaxRadius((radius || minRadius || 100) >= (maxRadius || 100) * 1000 || (items || 0) >= (maxItems || 15));
    }, [location, map, radius, marker]);

    const setLatLng = (latLng: LatLngLiteral) => {
        if (map) {
            //console.log('RADIUS', radius, 'DEFAULT RADIUS', defaultRadius);
            let zoom =
                (!!circleRef.current ? map.getBoundsZoom(circleRef.current.getBounds()) : map.getZoom()) -
                (innerWidth > innerHeight ? innerHeight / innerWidth : innerWidth / innerHeight) * (!radius ? 1.25 : 1);
            const targetPoint = map.project(latLng, zoom).subtract([0, correction]),
                targetLatLng = map.unproject(targetPoint, zoom);
           // console.log('SETTING MAP ZOOM TO', zoom);
            map.setView(targetLatLng, zoom, {
                animate: true,
                duration: 1.5,
                easeLinearity: 2
            });
        }
    };

    const renderMarker = useCallback(() => {
        if (radius && marker) {
            return marker?.map(({ latitude, longitude, count }, i) => (
                <Marker
                    key={`marker-${i}`}
                    zIndexOffset={-10}
                    icon={new NumberMarker({ amount: count })}
                    position={new LatLng(latitude, longitude)}
                />
            ));
        } else {
            return null;
        }
    }, [marker, radius]);

    return (
        <Wrapper style={style}>
            <svg xmlns="w3.org/2000/svg" version="1.1" style={{ display: 'none' }}>
                <defs>
                    <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="50%" id="highlight-gradient">
                        <stop stopColor="#049FD5" stopOpacity="0" offset="0%" />
                        <stop stopColor="#0082C6" stopOpacity="0" offset="22.033606%" />
                        <stop stopColor="#0082C6" stopOpacity="0.349278163" offset="99.9827565%" />
                    </radialGradient>
                </defs>
            </svg>
            <BG onClick={() => track('info', 'mapInteraction', true)} />
            <MapContainer
                center={defaultLocation}
                zoom={8}
                ref={setMap as any}
                id="map"
                minZoom={6}
                maxZoom={13}
                zoomDelta={0.25}
                zoomSnap={0.25}
                scrollWheelZoom={!isFixed}
                dragging={false}
                doubleClickZoom={false}
                zoomControl={!isFixed}
            >
                {useMaptiler ? (
                    <MapBoxGLLayer accessToken={'sFoAGq4hfNMTSV9ac3Ws'} />
                ) : (
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />
                )}

                <Circle
                    ref={circleRef as any}
                    center={location?.location || defaultLocation}
                    radius={(radius || defaultRadius) + radiusCorrection}
                    fillColor="#0B9FD8" //"url(#highlight-gradient)"
                    className={'main-circle'}
                    color={'#FFFFFF'}
                    weight={5}
                ></Circle>

                <Pane name="marker-pane" style={{ zIndex: 200 }}>
                    {renderMarker()}
                </Pane>

                {!!location && location.location && !!radius && map && (
                    <Pane name="radius-controls-pane" style={{ zIndex: 810 }}>
                        <Marker
                            icon={plusIcon}
                            position={GeometryUtil.destination(location.location, 90, radius + radiusCorrection)}
                            key={`radius-increase${isMaxRadius ? '-disabled' : ''}`}
                            alt={`radius-increase${isMaxRadius ? '-disabled' : ''}`}
                            eventHandlers={{
                                click: !isMaxRadius
                                    ? () => onRadiusChange && onRadiusChange(1)
                                    : onDisabledRadiusClicked
                                    ? () => onDisabledRadiusClicked('max')
                                    : undefined
                            }}
                        />
                        <Marker
                            icon={minusIcon}
                            key={`radius-decrease${isMinRadius ? '-disabled' : ''}`}
                            alt={`radius-decrease${isMinRadius ? '-disabled' : ''}`}
                            position={GeometryUtil.destination(location.location, 90, 0 - (radius + radiusCorrection))}
                            eventHandlers={{
                                click: !isMinRadius
                                    ? () => onRadiusChange && onRadiusChange(-1)
                                    : onDisabledRadiusClicked
                                    ? () => onDisabledRadiusClicked('min')
                                    : undefined
                            }}
                        />
                    </Pane>
                )}

                {!!location && location.location && map && (
                    <Pane name="radius-label-pane" style={{ zIndex: 810 }}>
                        <Marker
                            icon={divIcon({
                                className: 'radius-label',
                                html: renderToString(
                                    <RadiusLabel
                                        title={
                                            !radius
                                                ? 'Entfernung zu Ihrem Interessenszentrum'
                                                : `Größe Ihres Interessen-Radius (max. ${defaultConfig.maxRadius} km / ${defaultConfig.maxNews} Meldungen)`
                                        }
                                        radius={radius}
                                        map={map}
                                        items={items}
                                        location={location}
                                        origin={origin}
                                    />
                                )
                            })}
                            position={GeometryUtil.destination(
                                location.location,
                                0,
                                0 - ((radius || defaultRadius) + radiusCorrection)
                            )}
                        />
                    </Pane>
                )}
            </MapContainer>
        </Wrapper>
    );
};

export const Map = memo<Props>(MapPresenter as any, (prev, next) => {
    return (
        prev.radius === next.radius &&
        isEqual(prev.items, next.items) &&
        isEqual(prev.origin, next.origin) &&
        isEqual(prev.location, next.location) &&
        isEqual(prev.marker, next.marker)
    );
});
