import React from 'react';
import { useGlobal } from '../../logic/main';
import { View } from '../../models';
import { Header } from '../presenter/Header';

export const HeaderContainer: React.FC = () => {
    const [{ currentView }] = useGlobal((state) => ({
        currentView: state.currentView
    }));

    return <Header isTop={currentView === View.PLAYER} />;
};
