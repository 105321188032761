import styled from 'styled-components';
import {
    Tab as UnstyledTab,
    TabList as UnstyledTabList,
    Tabs as UnstyledTabs,
    TabPanel as UnstyledTabPanel
} from 'react-tabs';
import { mobile, RR_COLORS, STYLE } from './variables';

const Tabs = styled(UnstyledTabs)`
    width: 100%;
    border-top-left-radius: ${STYLE.default.radius};
    border-top-right-radius: ${STYLE.default.radius};
    @media ${mobile} {
        border-radius: unset;
    }
`;

const TabList = styled(UnstyledTabList)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    border-top-left-radius: ${STYLE.default.radius};
    border-top-right-radius: ${STYLE.default.radius};
    @media ${mobile} {
        border-radius: unset;
    }
`;

const Tab = styled(UnstyledTab).attrs({
    selectedClassName: 'selected',
    disabledClassName: 'disabled'
})<{ isDisabled?: boolean }>`
    flex-grow: 1;
    text-align: center;

    list-style: none;
    cursor: pointer;
    border-left: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    outline: none !important;
    position: relative;
    background-color: ${RR_COLORS['pv-super-hellgrau']};
    color: ${({ isDisabled }) => (isDisabled ? '#e0e0e0' : RR_COLORS['br-mittelgrau'])};
    overflow: hidden;
    padding: 7px 0;

    /*padding: 4px 0;
    margin-top: 4px;*/

    &:first-child {
        border-left: none;
        border-top-left-radius: ${STYLE.default.radius};

        @media ${mobile} {
            border-radius: unset;
        }
    }

    &:nth-child(2) {
        border-top-right-radius: ${STYLE.default.radius};
        @media ${mobile} {
            border-radius: unset;
        }
    }

    &.selected {
        /*margin-top: 0;*/
        color: ${RR_COLORS['br-blau']};
        padding: 8px 0;
        z-index: 999;
        background-color: white;
        border-bottom: none;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: ${RR_COLORS['br-blau']};
            left: 0;
            top: 0;
        }

        &:first-child {
            box-shadow: 4px 0 2px -4px ${RR_COLORS['br-mittelgrau']};
        }

        &:nth-child(2) {
            box-shadow: -4px 0 2px -4px ${RR_COLORS['br-mittelgrau']};

            @media ${mobile} {
                box-shadow: -4px -2px 0px -4px ${RR_COLORS['br-mittelgrau']};
            }
        }
    }

    &.disabled {
        color: #e0e0e0;
        cursor: not-allowed;
    }
`;

const TabPanel = styled(UnstyledTabPanel).attrs({ selectedClassName: 'selected' })<{ isDisabled?: boolean }>`
    background-color: white;
    display: none;
    padding: 10px 20px 0;
    box-shadow: 0px -3px 6px -5px ${RR_COLORS['br-dunkelgrau']};
    z-index: 99;
    position: relative;

    &.selected {
        display: block;
    }

    .disabled {
        color: #e0e0e0;
        cursor: not-allowed;
    }

    @media ${mobile} {
        padding: 10px 10px 0;
    }
`;

(Tab as any).tabsRole = 'Tab';
(Tabs as any).tabsRole = 'Tabs';
(TabPanel as any).tabsRole = 'TabPanel';
(TabList as any).tabsRole = 'TabList';

export { Tab, TabList, Tabs, TabPanel };
