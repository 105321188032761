import React, { useMemo } from 'react';
import { useGlobal } from '../../logic/main';
import { IndicatorBadge } from '../presenter/IndicatorBadge';

export const ListenedIndicatorBadge: React.FC = ({ children }) => {
    const [{ playlist, coverage, userLocation, hasUpdatedPlaylist }] = useGlobal((state) => ({
        playlist: state.filteredPlayList,
        hasUpdatedPlaylist: state.hasUpdatedPlaylist,
        userLocation: state.userLocation,
        coverage: state.coverage
    }));

    const notListenedNewsCount = useMemo(() => {
        const selectedCoverage = [...coverage].reverse().find((c) => (c.distance || 0) <= (userLocation?.radius || 0));
        const missingCoverageIdsInPlaylist =
            selectedCoverage?.broadcast_items.filter((b) => !playlist.find((p) => p.id === b.om_id)).length || 0;
        const listenedAndFilteredIdsInPlaylist = playlist.filter(
            (a) => !a.listened && selectedCoverage?.broadcast_items.some((b) => b.om_id === a.id)
        ).length;

        return playlist.length > 0
            ? listenedAndFilteredIdsInPlaylist + missingCoverageIdsInPlaylist
            : selectedCoverage?.broadcast_items.length || 0;
    }, [hasUpdatedPlaylist, playlist, userLocation, coverage]);

    return <IndicatorBadge value={notListenedNewsCount}>{children}</IndicatorBadge>;
};
