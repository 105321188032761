import { Author, Caption, LayoutConfig, PageConfig, TextConfig, UserConfig } from './src/config/models';

// Texte für Title, SEO & Sharing, ...
const de: TextConfig = {
    title: 'BR Regional-Update',
    headline: 'BR Regional-Update',
    description: 'Das personalisierte News-Briefing aus Ihrer Region',
    keywords: [
        'BR',
        'Bayerischer Rundfunk',
        'Regional',
        'Update',
        'Regional-Update',
        'Regionalnachrichten',
        'Nachrichten',
        'Audio',
        'News',
        'Radionachrichten',
        'Personalisierung',
        'Beta',
        'Public Beta',
        'Remix Regional',
        'Prototyp'
    ]
};

// Falls englische Seite gewünscht ("provideEn" in pageConfig = true), Übersetzungen hier eintragen:
const en: TextConfig = {
    title: 'BR Regional-Update',
    headline: 'BR Regional-Update',
    description: 'The personalized news briefing from your region',
    keywords: [
        'BR',
        'Bayerischer Rundfunk',
        'Regional',
        'Update',
        'Regional-Update',
        'Regional news',
        'News',
        'Audio',
        'News',
        'Radio news',
        'Personalization',
        'Beta',
        'Public Beta',
        'Remix Regional',
        'Prototype'
    ]
};

// Konfiguration der Seiteninhalte
const pageConfig: Omit<PageConfig, 'url'> = {
    provideEn: false,
    authors: [Author.SebastianBayerl],
    caption: Caption.Bayern,
    ATId: 'null',
    previewImage: 'static/teaser.png',
    headerImage: 'static/header.jpg'
};

// Basic Layout Konfiguration (eher Entwickler-Einstellungen)
const layoutConfig: LayoutConfig = {
    showAside: true,
    showProgress: true,
    headerOverlay: true
};

// Hier nichts verändern
export const config: UserConfig = {
    ...pageConfig,
    ...layoutConfig,
    de,
    en
};
