import React from 'react';
import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { COLOR, STYLE } from '../../styles/variables';

export interface ButtonProps {
    color?: string;
    bgColor?: string;
    outlineColor?: string;
    dropShadow?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    isActive?: boolean;
    onClick?: () => void;
    style?: React.CSSProperties;
    margin?: string;
    title?: string;
}

const Container = styled(ButtonBase)<{
    $color?: string;
    $margin?: string;
    $bgColor?: string;
    $fullWidth?: boolean;
    $dropShadow?: boolean;
    $outlineColor?: string;
}>`
    background-color: ${({ $bgColor }) => $bgColor} !important;
    color: ${({ $color }) => $color} !important;
    border-color: ${({ $outlineColor }) => $outlineColor} !important;
    border-width: 2px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid !important;
    font-family: Open Sans SemiBold;
    width: ${({ $fullWidth = false }) => ($fullWidth ? '100%' : 'auto')} !important;
    border-radius: ${STYLE.default.radius} !important;
    box-shadow: ${({ $dropShadow }) => ($dropShadow ? STYLE.default.shadow : '')} !important;
    padding: ${({ $fullWidth = false }) => ($fullWidth ? '1rem 0.75rem' : '1rem 2rem')} !important;
    margin: ${({ $margin = `1rem 0` }) => $margin} !important;
    font-size: 1rem !important;

    :disabled {
        opacity: 0.5 !important;
    }
`;

export const Button: React.FC<ButtonProps> = ({
    color,
    bgColor,
    outlineColor,
    onClick,
    dropShadow,
    children,
    style,
    margin,
    isActive,
    fullWidth,
    disabled,
    title
}) => {
    return (
        <Container
            title={title}
            style={style}
            $fullWidth={fullWidth}
            $bgColor={isActive ? COLOR.primary : bgColor}
            $color={isActive ? 'white' : color}
            $outlineColor={outlineColor}
            $dropShadow={dropShadow}
            onClick={onClick}
            disabled={disabled}
            $margin={margin}
        >
            {children}
        </Container>
    );
};
