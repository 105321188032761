import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { miniMobile, mobile, STYLE, tablet } from '../../styles/variables';
import { Footer } from '../container/Footer';

interface Props {
    bgColor?: string;
    center?: boolean;
    skipMargin?: boolean;
    skipPadding?: boolean;
    mobileHeight?: string;
    style?: React.CSSProperties;
    innerStyle?: React.CSSProperties;
    icon?: any;
    onBackClick?: () => void;
    id?: string;
    maxHeight?: string;
    showFooter?: boolean;
    showInfoLink?: boolean;
}

const StyledPage = styled.div<Props>`
    padding: ${({ skipPadding = false }) => (skipPadding ? 0 : `0 calc(3 * ${STYLE.default.padding})`)};
    background-color: ${({ bgColor }) => bgColor || 'transparent'};
    align-items: ${({ center = true }) => (center ? 'center' : '')};
    text-align: ${({ center = true }) => (center ? 'center' : '')};
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: ${({ skipMargin = false }) => (skipMargin ? 0 : `calc(${STYLE.default.padding})`)};

    @media ${mobile} {
        padding: ${({ skipPadding = false }) => (skipPadding ? 0 : `0`)};
    }
`;

const StyledInitPage = styled.div<Props>`
    padding: ${({ skipPadding = false }) =>
        skipPadding
            ? 0
            : `calc(4 * ${STYLE.default.padding}) calc(6 * ${STYLE.default.padding}) calc(${STYLE.default.padding}) calc(6 * ${STYLE.default.padding})`};
    background-color: ${({ bgColor }) => bgColor || 'transparent'};
    align-items: ${({ center = true }) => (center ? 'center' : '')};
    width: 100%;
    max-height: 80vh;
    display: flex;
    flex-flow: column;
    gap: calc(2 * ${STYLE.default.padding});
    position: relative;

    @media ${mobile} {
        max-height: unset;
        padding: ${({ skipPadding = false }) =>
            skipPadding
                ? 0
                : `calc(2 * ${STYLE.default.padding}) calc(3 * ${STYLE.default.padding}) calc(${STYLE.default.padding}) calc(3 * ${STYLE.default.padding})`};
    }

    @media ${miniMobile} {
        min-height: 88vh;
        justify-content: space-between;
    }
`;

const Header = styled.img`
    width: 50%;
    min-width: 200px;

    @media ${mobile} {
        height: 2rem;
        width: 100%;
        min-width: unset;
        max-width: 300px;
    }

    @media ${tablet} {
        height: 2rem;
        width: 100%;
        min-width: unset;
        max-width: 300px;
    }
`;

const BackButton = styled(ReactSVG)`
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
        cursor: pointer;
        width: 40px;
        transform: rotate(44deg);

        circle {
            fill: transparent;
        }
    }

    @media ${mobile} {
        top: 5px;
        right: 7.5px;

        svg {
            width: 30px;
        }
    }
`;

const PageContent = styled.div<{ mobileHeight?: string }>`
    position: relative;
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;

    @media ${mobile} {
        max-height: 70vh !important;
        height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight : '')} !important;
    }
`;

export const Page: React.FC<Props> = ({ bgColor, center, skipPadding, showFooter, children, style, maxHeight, id }) => (
    <StyledPage bgColor={bgColor} center={center} skipPadding={skipPadding} style={style} maxHeight={maxHeight} id={id}>
        {children}
        {showFooter ? <Footer showInfoLink={true} /> : null}
    </StyledPage>
);

export const PageInner: React.FC<Props> = ({ bgColor, center, skipPadding = true, children, style, maxHeight }) => (
    <StyledPage bgColor={bgColor} center={center} skipPadding={skipPadding} style={style} maxHeight={maxHeight}>
        {children}
    </StyledPage>
);

export const InitPage: React.FC<Props> = ({
    bgColor,
    center,
    maxHeight,
    skipPadding,
    mobileHeight,
    children,
    style,
    innerStyle,
    onBackClick,
    showInfoLink
}) => {
    const pageRef = useRef<HTMLDivElement>();
    const [maxInnerHeight, setMaxInnerHeight] = useState<number>();

    useEffect(() => {
        if (pageRef.current) {
            setMaxInnerHeight(pageRef.current.clientHeight );
        }
    }, [pageRef]);

    return (
        <StyledInitPage
            ref={pageRef as any}
            bgColor={bgColor}
            center={center}
            skipPadding={skipPadding}
            style={style}
            maxHeight={maxHeight}
        >
            {onBackClick ? (
                <BackButton
                    src={'media/button_radius-plus.svg'}
                    beforeInjection={(svg: SVGSVGElement) => {
                        svg.setAttribute('style', 'height: 40px');
                    }}
                    onClick={onBackClick}
                    title="Zurück"
                />
            ) : null}

            <Header src="media/logo_br-regional-update.svg" />
            <PageContent style={{ ...innerStyle, maxHeight: maxInnerHeight }} mobileHeight={mobileHeight}>
                {children}
            </PageContent>
            <Footer showInfoLink={showInfoLink} />
        </StyledInitPage>
    );
};
