import React, { useRef } from 'react';
import { useGlobal } from '../../logic/main';
import styled from 'styled-components';
import { COLOR, STYLE } from '../../styles/variables';
import { defaultConfig } from '../../config';
import GeoSuggest from '../../modules/maptiler-geosuggest/Geosuggest';
import { GeocodingResult } from '../../modules/maptiler-geosuggest/types/additionals';
import { track } from '../../logic/tracker';
import { FeatureHierarchy } from '@maptiler/client';

const Layout = styled.div<{
    $fontColor: string;
    $bgColor: string;
    $outlineColor: string;
    $fullWidth?: boolean;
}>`
    width: ${({ $fullWidth = false }) => ($fullWidth ? '100%' : 'auto')};
    margin-top: calc(${STYLE.default.padding} / 2);
    margin-bottom: calc(${STYLE.default.padding});
    font-family: Open Sans SemiBold;

    .geosuggest {
        width: 100%;
    }

    input {
        padding: ${({ $fullWidth = false }) => ($fullWidth ? '1rem 0.75rem' : '1rem 2rem')} !important;
        background-color: ${({ $bgColor }) => $bgColor} !important;
        color: ${({ $fontColor }) => $fontColor} !important;
        text-align: center;
        font-family: Open Sans SemiBold;

        color: ${({ $fontColor }) => $fontColor} !important;
        border-color: ${({ $outlineColor }) => $outlineColor} !important;
        border-width: 1px !important;
        border-style: solid !important;
        box-sizing: border-box !important;

        border-radius: ${STYLE.default.radius} !important;

        &::placeholder {
            color: ${({ $fontColor }) => $fontColor} !important;
            opacity: 1;
            font-size: 1rem;
        }

        &::-webkit-input-placeholder {
            color: ${({ $fontColor }) => $fontColor} !important;
        }

        &:focus {
            background-color: white !important;
            color: black !important;
            text-align: left;

            &::placeholder {
                opacity: 0.5;
            }
        }

        &:focus-visible {
            background-color: white;
            outline: none;
        }
    }

    .geosuggest__input {
        width: ${({ $fullWidth = false }) => ($fullWidth ? 'calc(100% - 2px)' : 'auto')} !important;
        font-size: 1rem !important;
        padding: 10px;
    }

    .geosuggest__item--active {
        background-color: ${COLOR.primary} !important;
        margin-left: -15px;
        margin-right: -15px;
        color: white !important;
    }

    .geosuggest__input-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .geosuggest__suggests-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .geosuggest__suggests {
        position: absolute;
        top: 0;
        padding-inline-start: 0;
        padding: 0.5rem 1rem;
        background-color: white;
        margin-top: -0.5rem;
        border-color: ${({ $outlineColor }) => $outlineColor};
        border-width: 1px;
        border-style: solid;
        z-index: 99;
        box-sizing: border-box;
        border-top-width: 0;
        width: calc(100% - 2px);

        li {
            list-style: none;
            padding: 5px 0;
        }
        ul {
            padding-inline-start: 1px !important;
            padding-left: 0;
        }
        border-bottom-left-radius: ${STYLE.default.radius};
        border-bottom-right-radius: ${STYLE.default.radius};
    }

    .geosuggest__suggests--hidden {
        display: none;
    }
`;

interface Props {
    fontColor?: string;
    bgColor?: string;
    outlineColor?: string;
    fullWidth?: boolean;
}

type EnrichedFeatureHierarchy = FeatureHierarchy & { text_de?: string };

export const LocationInput: React.FC<Props> = ({
    fontColor = 'white',
    outlineColor = COLOR.primary,
    bgColor = COLOR.primary,
    fullWidth
}) => {
    const geoSuggestEl = useRef<HTMLInputElement>(null);
    const [_, { setLocation }] = useGlobal(
        (_) => undefined,
        (actions) => ({
            setLocation: actions.setUserLocation
        })
    );

    const onSuggestSelect = (suggest?: GeocodingResult) => {
        if (suggest && suggest.place_name) {
            let name = suggest.text;
            if (suggest.place_type.includes('postal_code')) {
                const context = suggest.context?.find(
                    (s) => s.id.includes('municipal_district') || s.id.includes('municipal') || s.id.includes('county')
                );
                name = context ? context.text : name;
            }

            const county = suggest?.context?.find((c) =>
                c.id.includes('county')
            ) as unknown as EnrichedFeatureHierarchy;
            const district = (county?.text_de || county?.text || suggest?.text)?.replace('Landkreis ', '') || '';

            //console.warn('DISTRICT IS:', district, 'GOT FROM, ', suggest);
            setLocation({
                center: {
                    lat: suggest.center[1],
                    lng: suggest.center[0]
                },
                name,
                district,
                radius: defaultConfig.defaultRadius
            });
            setTimeout(() => track('start', 'inputLocation', name), 1000);
        }
    };

    return (
        <Layout $bgColor={bgColor} $fontColor={fontColor} $outlineColor={outlineColor} $fullWidth={fullWidth}>
            <GeoSuggest
                apiKey={defaultConfig.maptilerKey}
                ref={geoSuggestEl as any}
                placeholder="Ortsname oder PLZ eingeben"
                onSuggestSelect={onSuggestSelect}
                minLength={2}
                autoComplete="off"
                types={['locality', 'county', 'municipality', 'postal_code']}
                country={'de'}
                autoActivateFirstSuggest={true}
                ignoreEnter={false}
                bounds={defaultConfig.bounds.toBBoxString().split(',') as any}
            />
        </Layout>
    );
};
