import React from 'react';
import { useGlobal } from '../../logic/main';
import { Button } from '../presenter/Button';
import { COLOR } from '../../styles/variables';
import { View } from '../../models';

export const RestartContainer: React.FC = () => {
    const [{ userLocation, currentView }, restart] = useGlobal(
        (state) => ({ userLocation: state.userLocation, currentView: state.currentView }),
        (actions) => actions.resetAll
    );

    return !!userLocation && currentView === View.PLAYER ? (
        <Button
            dropShadow={true}
            outlineColor={'transparent'}
            title="Neu starten"
            color={COLOR.primary}
            bgColor={COLOR.pageBackground}
            onClick={restart}
        >
            Neu starten
        </Button>
    ) : null;
};
