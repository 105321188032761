import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { mobile, RR_COLORS, STYLE } from '../../styles/variables';
import { Location, Playlist, PlayState, SeekState } from '../../models';
import { DateIndicator } from './DateIndicator';
import { Timeline } from './Timeline';
import { utc } from 'moment/moment';
import { A, H2 } from '../../styles/styledComponents';
import { ReactSVG } from 'react-svg';
import { SvgRenderer } from './SvgRenderer';
import { BrowserDetector } from '../../utils/browserDetection';
import { throttle } from 'lodash';

interface Props {
    onPlayback?: (state: PlayState) => void;
    onSeek?: (state: SeekState | number, seconds: number) => void;
    state?: PlayState;
    currSeconds: number;
    currIndex: number;
    userLocation?: Location;
    filteredPlayList: Playlist;
    onResetClick: () => void;
    playingOutro: boolean;
}

const ListenedAll = styled(ReactSVG)`
    svg {
        width: 60px;
        height: 60px;
    }
`;

const PlayerWrapper = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    flex-flow: column;
    justify-content: space-between;
    flex-wrap: var(--player-wrap);
    padding-bottom: calc(1.5 * ${STYLE.default.padding});
    gap: calc(2.5 * ${STYLE.default.padding});
    margin-top: 1rem;

    @media ${mobile} {
        padding: 0 calc(${STYLE.default.padding}) calc(1.5 * ${STYLE.default.padding});
    }
`;

const ButtonGrid = styled.div`
    display: flex;
    flex-flow: row;
    column-gap: calc(${STYLE.default.padding} * 1.5);
    align-items: center;
    justify-content: center;

    > * {
        cursor: pointer;

        :hover {
        }
    }
`;

const BottomGrid = styled.div`
    display: flex;
    flex-flow: row;
    column-gap: calc(${STYLE.default.padding});
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const InfoGrid = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    text-align: left;
`;

const BigButton = styled(SvgRenderer)`
    width: 75%;
`;

const SmallButton = styled(SvgRenderer)`
    width: 50%;
`;

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: calc(${STYLE.default.padding} * 2);
    padding: 0 calc(${STYLE.default.padding} * 6);
    justify-content: flex-start;
    line-height: 3ex;
    height: 9.5ex; /* 2.5ex for each visible line */

    @media ${mobile} {
        padding: 0;
    }
`;

const InfoText = styled.div`
    color: black;
    max-height: 6ex; /* 2.5ex for each visible line */
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Block = styled.div<{ alwaysVisible?: boolean }>`
    display: flex;
    flex-flow: column;
    color: black;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: calc(30% - ${STYLE.default.padding});
    text-align: left;

    @media ${mobile} {
        display: ${({ alwaysVisible }) => (alwaysVisible ? 'flex' : 'none')};
    }
`;

const MobileBlock = styled(Block)`
    display: none;
    @media ${mobile} {
        display: flex;
    }
`;

const Text = styled.div``;

export const Player: React.FC<Props> = ({
    onPlayback,
    onSeek,
    state,
    currIndex,
    currSeconds,
    filteredPlayList,
    userLocation,
    onResetClick,
    playingOutro,
    ...other
}) => {
    const renderHeader = () => {
        if (playingOutro) {
            return (
                <InfoWrapper>
                    <ListenedAll src={'media/icon_alles-gehoert.svg'} />
                    <InfoGrid>
                        <H2 skipMargin={true}>Alle aktuellen Meldungen gehört!</H2>
                        <InfoText>
                            Kommen Sie später zurück oder{' '}
                            <A inline={true} onClick={onResetClick}>
                                starten Sie mit einem neuen Ort.
                            </A>
                        </InfoText>
                    </InfoGrid>
                </InfoWrapper>
            );
        } else {
            return (
                <InfoWrapper>
                    {filteredPlayList[currIndex]?.date ? (
                        <DateIndicator date={filteredPlayList[currIndex]?.date} />
                    ) : null}
                    <InfoGrid>
                        <H2 skipMargin={true} style={{ marginBottom: '0.25rem' }}>
                            {filteredPlayList[currIndex]?.location.name}
                        </H2>
                        <InfoText>{filteredPlayList[currIndex]?.title}</InfoText>
                    </InfoGrid>
                </InfoWrapper>
            );
        }
    };

    const getButtonStyles = (size: 'big' | 'small') => ({
        style: { width: '100%', maxWidth: size === 'big' ? '75px' : '40px', height: '60px' },
        styles: css`
            &:hover {
                circle {
                    fill: ${RR_COLORS['br-hellgrau']};
                }
            }
        `
    });

    const timeLeft =
        currSeconds >= (filteredPlayList[currIndex]?.duration || 0)
            ? utc(0).format('mm:ss')
            : utc(((filteredPlayList[currIndex]?.duration || 0) - currSeconds) * 1000).format('mm:ss');

    const handleSeek = (seek: SeekState | number, seconds: number) => onSeek && onSeek(seek, seconds);
    const handlePlay = (playState: PlayState) => onPlayback && onPlayback(playState);

    const throttledSeek = useRef(throttle(handleSeek, 250)).current;

    const throttledToggle = useRef(throttle(handlePlay, 250)).current;

    return (
        <PlayerWrapper {...other}>
            {renderHeader()}

            <Timeline
                isPlaying={state === 'PLAY'}
                style={{ marginTop: 'auto' }}
                secondsList={filteredPlayList.map((i) => i.duration || 0)}
                currentSecond={currSeconds}
                onClick={onSeek}
                currentIndex={currIndex}
            />
            <BottomGrid>
                <Block>
                    BR Regional-Update für{' '}
                    <b>
                        {userLocation?.name} + {userLocation?.radiusRounded || 0} km
                    </b>
                </Block>
                <MobileBlock>
                    <Text>
                        {BrowserDetector.isPortrait ? 'Meld.' : 'Meldung'} {currIndex + 1}/{filteredPlayList.length}
                    </Text>
                    <Text>{timeLeft} Min</Text>
                </MobileBlock>
                <ButtonGrid>
                    <SmallButton
                        title="Zurück"
                        src="media/button_skip_left.svg"
                        onClick={() => throttledSeek('BACKWARD', 0)}
                        {...getButtonStyles('small')}
                    />
                    <BigButton
                        title={state === 'PAUSE' ? 'Abspielen' : 'Stoppen'}
                        src={state === 'PAUSE' ? 'media/button_play_active.svg' : 'media/button_stop-pause.svg'}
                        onClick={() => throttledToggle(state === 'PAUSE' ? 'PLAY' : 'PAUSE')}
                        {...getButtonStyles('big')}
                    />
                    <SmallButton
                        title="Weiter"
                        src="media/button_skip_right.svg"
                        onClick={() => throttledSeek('FORWARD', 0)}
                        {...getButtonStyles('small')}
                    />
                </ButtonGrid>

                <Block>
                    <Text>
                        Meldung {currIndex + 1}/{filteredPlayList.length}
                    </Text>
                    <Text>{timeLeft} Min</Text>
                </Block>
                <Block alwaysVisible={true}>
                    <Text>Gesamt:</Text>
                    <Text>
                        {utc(filteredPlayList.reduce((p, c) => p + (c.duration || 0), 0) * 1000).format('mm:ss')} Min
                    </Text>
                </Block>
            </BottomGrid>
        </PlayerWrapper>
    );
};
