import React from 'react';
import { InitPage } from '../components/presenter/Page';
import { COLOR, STYLE } from '../styles/variables';
import { A, ButtonWrapper, P } from '../styles/styledComponents';
import { Button } from '../components/presenter/Button';
import { useGlobal } from '../logic/main';
import { LocationInput } from '../components/container/LocationInput';
import { View } from '../models';
import styled from 'styled-components';

const Disclaimer = styled.div`
    font-size: 0.85rem;
    color: ${COLOR.br24.mediumGrey};
    margin: calc(${STYLE.default.padding} * 1.5) 0 calc(${STYLE.default.padding});
`;

export const NoLocation: React.FC = () => {
    const [_, { changeView, getDeviceLocation }] = useGlobal(
        (_) => undefined,
        (actions) => ({
            changeView: actions.changeCurrentView,
            getDeviceLocation: actions.getDeviceLocation
        })
    );

    return (
        <InitPage
            style={{ alignItems: 'center', textAlign: 'center' }}
            showInfoLink={true}
            innerStyle={{ overflow: 'inherit' }}
        >
            <P color="black">
                Mit dem BR Regional-Update versorgen wir Sie mit individuell für Sie zusammengestellten, aktuellen
                Nachrichten aus Bayern – basierend auf Ihrem aktuellen Standort oder einem Wunsch-Ort.
            </P>
            <P color="black">
                <b>Dies ist ein Prototyp.</b>
                <A inline={true} onClick={() => changeView(View.INFO)}>
                    Mehr Infos
                </A>
            </P>
            <Disclaimer>
                Durch die Freigabe Ihres Standortes bzw. die Eingabe Ihres Wunschortes stimmen Sie zu, dass wir Ihre
                GPS-Standortdaten bzw. Ortsangabe und Ihre IP-Adresse verarbeiten. Weitere Informationen zur
                Datenverarbeitung finden Sie
                <A
                    href="https://www.br.de/unternehmen/service/datenschutz/datenschutz-standortbasierte-funktionalitaeten-100.html"
                    inline={true}
                    target="_blank"
                >
                    hier unter Ziff. 3
                </A>
                .
            </Disclaimer>
            <ButtonWrapper>
                <Button
                    color={'white'}
                    bgColor={COLOR.primary}
                    outlineColor={'white'}
                    onClick={getDeviceLocation}
                    fullWidth={false}
                    margin={`calc(${STYLE.default.padding}) 0 ${STYLE.default.padding}`}
                >
                    Meinen aktuellen Standort nutzen
                </Button>
                <LocationInput fullWidth={true} />
            </ButtonWrapper>
        </InitPage>
    );
};
