export class UmamiTracker {
    private websiteId: string;
    private hostname: string;
    private umamiUrl: string;
    private lastUrl: string = '/';
    constructor(websiteId: string, umamiUrl: string, hostname?: string) {
        this.websiteId = websiteId;
        this.hostname = hostname || location.hostname;
        this.umamiUrl = umamiUrl + '/api/send';
    }

    public trackView(url: string) {
        this.lastUrl = (url.startsWith('/') ? url : '/' + url).toLowerCase();
        const payload = {
            website: this.websiteId,
            hostname: this.hostname,
            screen: window.innerWidth + 'x' + window.innerHeight,
            language: navigator.language,
            cache: null,
            event_type: 1,
            url: this.lastUrl,
            referrer: ''
        };
        try {
            fetch(this.umamiUrl, {
                credentials: 'omit',
                headers: {
                    'User-Agent': navigator.userAgent,
                    Accept: '*/*',
                    'Accept-Language': window.navigator.language,
                    'Content-Type': 'text/plain',
                    'Sec-Fetch-Dest': 'empty',
                    'Sec-Fetch-Mode': 'cors',
                    'Sec-Fetch-Site': 'cross-site',
                    'Cache-Control': 'max-age=0'
                },
                referrer: window.location.toString().substring(0, window.location.toString().indexOf('/', 9)),
                body: '{"type":"event","payload":' + JSON.stringify(payload) + '}',
                method: 'POST',
                mode: 'cors'
            }).catch(() => console.debug('Error on impression'));
        } catch (e) {
            console.debug('Error on impression');
        }
    }

    public trackEvent(eventName: string, eventData: { [key: string]: string | number }) {
        const payload = {
            website: this.websiteId,
            hostname: this.hostname,
            screen: window.innerWidth + 'x' + window.innerHeight,
            language: navigator.language,
            cache: null,
            url: this.lastUrl,
            event_type: 2,
            name: eventName,
            data: eventData
        };
        try {
            fetch(this.umamiUrl, {
                credentials: 'omit',
                headers: {
                    'User-Agent': navigator.userAgent,
                    Accept: '*/*',
                    'Accept-Language': window.navigator.language,
                    'Content-Type': 'text/plain',
                    'Sec-Fetch-Dest': 'empty',
                    'Sec-Fetch-Mode': 'cors',
                    'Sec-Fetch-Site': 'cross-site'
                },
                referrer: window.location.toString().substring(0, window.location.toString().indexOf('/', 9)),
                body: '{"type":"event","payload":' + JSON.stringify(payload) + '}',
                method: 'POST',
                mode: 'cors'
            }).catch(() => console.debug('Error on impression'));
        } catch (e) {
            console.debug('Error on impression');
        }
    }
}
