import React from 'react';
import { GlobalStyle } from './styles/globalStyle';
import { Fonts } from './styles/fonts';
import { Loading } from './components/container/Loading';
import { OutHookConfigurator, ChangeViewListener } from './utils/hooks';
import { RemixSnackbar } from './components/container/RemixSnackbar';
import { ApplicationWrapper } from './components/container/ApplicationWrapper';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './styles/variables';
import { FeedbackButton } from './components/container/FeedbackButton';

export const App: React.FC = () => (
    <>
        <Fonts />
        <GlobalStyle />
        <ChangeViewListener />
        <OutHookConfigurator />
        <ThemeProvider theme={theme}>
            <ApplicationWrapper />
            <Loading />
            <RemixSnackbar />
            <FeedbackButton />
        </ThemeProvider>
    </>
);
