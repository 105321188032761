import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { COLOR } from '../../styles/variables';
import ProgressBar from './Progressbar';

const timelineGap = 5;

interface Props {
    secondsList: number[];
    currentSecond: number;
    currentIndex: number;
    bgColor?: string;
    onClick?: (index: number, progress: number) => void;
    style?: React.CSSProperties;
    isPlaying?: boolean;
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    gap: ${timelineGap}px;
    align-items: center;
    justify-content: center;
`;

export const Timeline: React.FC<Props> = ({
    secondsList,
    currentSecond,
    currentIndex,
    bgColor = COLOR.primary,
    style,
    isPlaying,
    onClick
}) => {
    const containerRef = useRef<HTMLDivElement>();
    const handleTimeLineClick = (index: number, progress: number, max: number) => {
        if (onClick) {
            const transformedSeconds = (max * progress) / 100;
            onClick(index, transformedSeconds);
        }
    };

    const getMax = useCallback(
        (seconds: number) =>
            (seconds *
                (100 -
                    (containerRef.current
                        ? ((timelineGap * 100) / (containerRef.current?.clientWidth || timelineGap)) *
                          (secondsList.length - 1)
                        : 0))) /
            secondsList.reduce((a, b) => a + b, 0),
        [secondsList, containerRef]
    );

    const getCompleted = useCallback(
        (seconds: number, i: number) =>
            i === currentIndex ? (currentSecond * 100) / seconds : i < currentIndex ? 100 : 0,
        [currentSecond, currentIndex]
    );

    useEffect(() => {
        //console.log('RENDERING TIMELINE:', containerRef);
    }, [containerRef]);

    return (
        <Container style={style} ref={containerRef as any}>
            {secondsList.map((seconds, i) => (
                <ProgressBar
                    key={`time-${i}`}
                    id={`time-${i}`}
                    isPlaying={i === currentIndex && isPlaying}
                    isActive={i === currentIndex}
                    bgColor={bgColor}
                    onClick={(progress) => handleTimeLineClick(i, progress, seconds)}
                    completed={getCompleted(seconds, i)}
                    max={getMax(seconds)}
                    style={{ marginBottom: 0 }}
                />
            ))}
        </Container>
    );
};
