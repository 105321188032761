import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { COLOR, miniMobile, mobile, STYLE } from '../../styles/variables';

export const transitionTime = 0.85;
export const fastTransitionTime = 0.25;
export const transitionType = 'ease-out';

const Backdrop = styled.div<{ open?: boolean }>`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 999;
    position: absolute;
    align-items: center;
    opacity: ${({ open }) => (open ? 1 : 0)};
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: ${({ open }) => (open ? 'blur(2px)' : 'blur(0px)')};
    transition: opacity ${transitionTime}s ${transitionType}, backdrop-filter ${transitionTime}s ${transitionType};
    box-sizing: content-box;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    pointer-events: none;
`;

const Container = styled.div<{
    position: 'center' | 'bottom';
    height?: number;
}>`
    width: ${({ position }) => (position === 'center' ? '660px' : '800px')};
    min-width: 600px;
    position: absolute;
    bottom: ${({ position }) => (position === 'center' ? '50%' : 0)};
    left: 50%;
    height: ${({ height }) => (height ? `${height}px` : '32rem')};
    max-height: 80vh;
    z-index: 1001;
    transform: ${({ position }) => (position === 'center' ? 'translate(-50%, 50%)' : 'translate(-50%, 0)')};

    border-radius: ${({ position }) =>
        position === 'center' ? STYLE.default.radius : `${STYLE.default.radius} ${STYLE.default.radius} 0 0`};
    transition: height ${fastTransitionTime}s ${transitionType}, bottom ${transitionTime}s ${transitionType},
        width ${transitionTime}s ${transitionType}, translate ${transitionTime}s ${transitionType};

    @media ${mobile} {
        width: ${({ position }) => (position === 'center' ? '80vw' : '100vw')};
        min-width: unset;
        max-height: unset;
    }

    @media ${miniMobile} {
        width: 100vw;
        top: ${({ position }) => (position === 'center' ? 0 : 'unset')};
        bottom: ${({ position }) => (position === 'center' ? 'unset' : 0)};
        transform: ${({ position }) => (position === 'center' ? 'unset' : 'translate(0, 25%)')};
        left: 0;
        min-height: ${({ position }) => (position === 'center' ? 'unset' : 'unset')};
    }
`;

const CardContainer = styled.div<{
    position: 'center' | 'bottom';
    side?: 'front' | 'back';
    height?: number;
}>`
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    box-shadow: ${STYLE.default.shadow};

    box-sizing: content-box;
    border-radius: ${({ position }) =>
        position === 'center' ? STYLE.default.radius : `${STYLE.default.radius} ${STYLE.default.radius} 0 0`};
    transform: rotateY(${({ side }) => (side === 'back' ? '-180deg' : '0deg')})
        translateZ(${({ side }) => (side === 'back' ? 1 : 0)}px);
    transition: transform ${transitionTime}s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    > div {
        overflow: hidden;
        position: absolute;
        border-radius: ${({ position }) =>
            position === 'center' ? STYLE.default.radius : `${STYLE.default.radius} ${STYLE.default.radius} 0 0`};
        backface-visibility: hidden;
        background-color: ${COLOR.pageBackground};
        width: 100%;
        height: 100%;
        transition: opacity ${fastTransitionTime}s ${transitionType};
        @media ${mobile} {
            border-radius: unset;
        }
    }

    @media ${mobile} {
        border-radius: ${({ position }) => (position === 'center' ? STYLE.default.radius : 'unset')} !important;
    }
`;

const FrontCard = styled.div<{
    side?: 'front' | 'back';
    position?: 'center' | 'bottom';
}>`
    pointer-events: ${({ side }) => (side === 'front' ? 'auto' : 'none')};
    opacity: ${({ side }) => (side === 'front' ? 1 : 0)};

    @media ${mobile} {
        border-radius: ${({ position }) => (position === 'center' ? STYLE.default.radius : 'unset')} !important;
    }
`;

const BackCard = styled.div<{
    side?: 'front' | 'back';
    position?: 'center' | 'bottom';
}>`
    pointer-events: ${({ side }) => (side === 'back' ? 'auto' : 'none')};
    transform: rotateY(180deg);
    opacity: ${({ side }) => (side === 'back' ? 1 : 0)};

    @media ${mobile} {
        border-radius: ${({ position }) => (position === 'center' ? STYLE.default.radius : 'unset')} !important;
    }
`;

interface Props {
    position: 'center' | 'bottom';
    style?: React.CSSProperties;
    side?: 'front' | 'back';
    backContent?: React.ReactElement;
    minHeight?: number;
    centerChildren?: boolean;
    enableOverflow?: boolean;
}

export const Card: React.FC<Props> = ({
    position,
    children,
    enableOverflow,
    style,
    side = 'front',
    backContent = null
}) => {
    const frontRef = useRef<HTMLDivElement>(null);
    const backRef = useRef<HTMLDivElement>(null);

    const [backHeight, setBackHeight] = useState<string | undefined>();
    const [frontHeight, setFrontHeight] = useState<string | undefined>();
    const [currentHeight, setCurrentHeight] = useState<string | undefined>();

    useEffect(() => {
        let newHeight = currentHeight;
        const adjustHeight = () => {
            setTimeout(() => {
                setFrontHeight(getComputedStyle(frontRef.current!.children[0])?.height);
                setBackHeight(getComputedStyle(backRef.current!.children[0])?.height);
                newHeight = side === 'front' ? frontHeight : backHeight;
                setCurrentHeight(side === 'front' ? frontHeight : backHeight);
            }, transitionTime * (newHeight === currentHeight ? 150 : 300));
        };

        if (frontRef.current && backRef.current) {
            const sideRef = side === 'front' ? frontRef.current : backRef.current;
            sideRef.style.overflow = 'hidden';

            setTimeout(() => {
                if (position === 'bottom') {
                    frontRef.current!.style.overflow = 'hidden';
                    backRef.current!.style.overflow = 'hidden';
                } else if (enableOverflow) {
                    sideRef.style.overflow = 'visible';
                }
            }, transitionTime * 1000);

            adjustHeight();
            window.onresize = () => adjustHeight();
        }
    });

    return (
        <>
            <Backdrop open={position === 'center'} className="card-backdrop" />
            <Container style={{ ...style, height: currentHeight }} position={position} className="card-outer-container">
                <CardContainer style={style} position={position} side={side} className="card-container">
                    <FrontCard ref={frontRef} side={side} id="card-front" position={position}>
                        {children}
                    </FrontCard>
                    <BackCard ref={backRef} side={side} id="card-back" position={position}>
                        {backContent}
                    </BackCard>
                </CardContainer>
            </Container>
        </>
    );
};
