import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useGlobal } from '../../logic/main';

export const ldsRipple = keyframes`0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        opacity: 0;
    }`;

export const Layout = styled.div<{ isVisible?: boolean }>`
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    display: flex;
    place-items: center;
    place-content: center;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

export const Wrapper = styled.div`
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
`;

export const InnerOne = styled.div`
    display: inline-block;
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ${ldsRipple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`;

// @ts-ignore
export const InnerTwo = styled(InnerOne)`
    display: inline-block;
    animation-delay: -0.5s;
`;

export const Loading: React.FC<{ isVisible?: boolean }> = () => {
    const [isVisible] = useGlobal((state) => state.isLoading);

    return (
        <Layout isVisible={isVisible} className="loading-wrapper">
            <Wrapper>
                <InnerOne />
                <InnerTwo />
            </Wrapper>
        </Layout>
    );
};
