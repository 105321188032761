import React from 'react';
import { Button, Snackbar, SnackbarContent } from '@material-ui/core';
import { useGlobal } from '../../logic/main';
import { Level } from '../../models';
import { RR_COLORS, STYLE } from '../../styles/variables';
import styled, { css } from 'styled-components';
import { SvgRenderer } from '../presenter/SvgRenderer';

let style: React.CSSProperties = {
    backgroundColor: 'white',
    color: 'black',
    borderRadius: STYLE.default.radius,
    fontSize: '1rem'
};

const iconTypeMap = {
    [Level.INFO]: 'media/snackbar_i.svg',
    [Level.SUCCESS]: 'media/snackbar_check.svg',
    [Level.WARNING]: 'media/snackbar_hinweis.svg',
    [Level.ERROR]: 'media/snackbar_rufezeichen.svg'
};

const NotificationWrapper = styled.div`
    display: flex;
    flex-flow: row;
    gap: calc(2 * ${STYLE.default.padding});
    align-items: center;
`;

const MessageWrapper = styled.div``;

export const RemixSnackbar: React.FC = () => {
    const [{ notification }, { setNotification, onNotificationAction }] = useGlobal(
        (state) => ({
            notification: state.notification
        }),
        (actions) => ({ setNotification: actions.setNotification, onNotificationAction: actions.onNotificationAction })
    );

    const action = (
        <>
            <Button color="secondary" size="small" onClick={onNotificationAction}>
                {notification?.actionText?.toUpperCase()}
            </Button>
        </>
    );

    const getStyle = (): React.CSSProperties => {
        switch (notification?.type) {
            case Level.INFO:
                return { ...style, backgroundColor: RR_COLORS['br-infogelb'], color: 'black' };
            case Level.WARNING:
                return { ...style, backgroundColor: RR_COLORS['br-infoorange'], color: 'white' };
            case Level.ERROR:
                return { ...style, backgroundColor: RR_COLORS['br-inforrot'], color: 'white' };
            case Level.SUCCESS:
                return { ...style, backgroundColor: RR_COLORS['br-infogr-n'], color: 'white' };
            default:
                return style;
        }
    };

    const getFill = () => {
        switch (notification?.type) {
            case Level.INFO:
                return 'black';
            case Level.WARNING:
                return 'white';
            case Level.ERROR:
                return 'white';
            default:
                return 'white';
        }
    };

    return (
        <Snackbar
            open={!!notification?.text}
            onClose={() => setNotification()}
            onClick={() => setNotification()}
            action={action}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ zIndex: 99999 }}
            autoHideDuration={notification?.actionText ? undefined : undefined}
        >
            <SnackbarContent
                style={getStyle()}
                message={
                    <NotificationWrapper>
                        <SvgRenderer
                            src={!!notification ? iconTypeMap[notification.type] : ''}
                            styles={css`
                                svg {
                                    height: 100%;
                                    display: block;
                                    width: auto;
                                }
                                g {
                                    fill: ${getFill()};
                                }
                            `}
                        />
                        <MessageWrapper>{notification?.text}</MessageWrapper>
                    </NotificationWrapper>
                }
            />
        </Snackbar>
    );
};
