import React, { useEffect } from 'react';
import { Page } from '../../components/presenter/Page';
import { Player } from '../../components/presenter/Player';
import { useGlobal } from '../../logic/main';
import { PlayState, PlayType, SeekState } from '../../models';
import { track } from '../../logic/tracker';

export const Listen: React.FC = () => {
    const [
        { userLocation, filteredPlayList, playType, currIndex, currSeconds, playState, updatedRadius },
        { seek, play, pause, getPlaylist, reset, getTrackingInfo }
    ] = useGlobal(
        (state) => ({
            userLocation: state.userLocation,
            filteredPlayList: state.filteredPlayList,
            currIndex: state.currentArticleIndex,
            currSeconds: state.currentArticleSeconds,
            playState: state.playState,
            updatedRadius: state.hasUpdatedLocationData,
            playType: state.playType
        }),
        (actions) => ({
            seek: actions.seek,
            play: actions.play,
            pause: actions.pause,
            getPlaylist: actions.getPlaylist,
            reset: actions.resetAll,
            getTrackingInfo: actions._getTrackingInfo
        })
    );

    useEffect(() => {
        if (filteredPlayList.length === 0 || updatedRadius) {
            //console.warn('>>>>>>>>>>>>>>>>>>> CALLING GET PLAYLIST FROM LISTEN VIEW!', filteredPlayList, updatedRadius);
            getPlaylist();
        }
    }, [userLocation, updatedRadius]);

    const handleSeek: (state: SeekState | number, seconds: number) => void = (state, seconds) => {
        seek(state, seconds, 'manual');
    };

    const handlePlay: (state: PlayState) => void = (state) => {
        setTimeout(
            () => track('player', state === 'PLAY' ? 'play' : 'pause', undefined, 'manual', getTrackingInfo()),
            250
        );
        if (state === 'PLAY') {
            play();
        } else {
            pause();
        }
    };

    return (
        <Page center={true} skipMargin={true}>
            <Player
                state={playState}
                onPlayback={handlePlay}
                onSeek={handleSeek}
                playingOutro={playType === PlayType.OUTRO}
                filteredPlayList={filteredPlayList}
                currIndex={currIndex}
                userLocation={userLocation}
                currSeconds={currSeconds}
                onResetClick={reset}
            />
        </Page>
    );
};
