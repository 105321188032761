import { icon, Icon } from 'leaflet';
import { css } from 'styled-components';
import { COLOR } from '../../../styles/variables';

export const markerStyles = css`
    .leaflet-interactive {
        outline: none;
    }

    .leaflet-marker-icon {
        img {
            width: 100%;
        }

        .number {
            position: relative;
            top: -44px;
            left: -3.5px;
            font-size: 14px;
            font-weight: bold;
            color: ${COLOR.primary};
            width: 100%;
            text-align: center;
        }
    }

    .main-circle {
      cursor: auto;
    }

    .radius-label {
        width: auto !important;
        height: auto !important;
        margin-top: -15px !important;
        margin-left: 0 !important;
        position: relative;
    }

    .radius-change {
        cursor: pointer;

        &[alt$='disabled'] {
            filter: contrast(0.7) grayscale(0.75) drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4)) !important;
            margin-left: -10px !important;
            margin-top: -10px !important;
            width: 20px !important;
            height: 20px !important;
            cursor: auto;
        }
    }
`;

export const NumberMarker = Icon.extend({
    options: {
        number: '',
        shadowUrl: null,
        iconAnchor: [12, 40],
        popupAnchor: [15, 15],
        iconSize: [25],
        className: 'leaflet-div-icon'
    },
    createIcon: function () {
        const defaultSize = 26;
        const div = document.createElement('div');
        div.title = 'Vergrößern Sie Ihren Interessen-Radius, um diese Meldung zu erhalten.';
        const img = this._createImg(
            this.options['amount'] > 1 ? 'media/marker_meldung-multi.svg' : 'media/marker_meldung.svg'
        );
        this.options['iconSize'] = this.options['amount'] > 1 ? [defaultSize * 1.2] : [defaultSize];
        setTimeout(() => {
            const numDiv = document.createElement('div');
            numDiv.setAttribute('class', 'number');
            numDiv.innerHTML = this.options['amount'] === 1 ? '' : this.options['amount'];
            div.appendChild(img);
            div.appendChild(numDiv);
        }, 250);

        this._setIconStyles(div, 'icon');
        return div;
    },
    createShadow: function () {
        return null;
    }
}) as new (options: { amount: number }) => any;

export const RadiusLabel = Icon.extend({
    options: {
        angle: '',
        current: '',
        origin: '',
        isSetting: '',
        radius: '',
        items: '',
        shadowUrl: null,
        iconAnchor: [0, 0],
        popupAnchor: [0, 0],
        iconSize: [25],
        className: 'radius-label'
    },
    createIcon: function () {
        const div = document.createElement('div');
        setTimeout(() => {
            const numDiv = document.createElement('div');
            numDiv.setAttribute('class', 'number');
            numDiv.innerHTML = this.options['amount'] === 1 ? '' : this.options['amount'];
            div.appendChild(numDiv);
        }, 250);

        this._setIconStyles(div, 'icon');
        return div;
    },
    createShadow: function () {
        return null;
    }
}) as new (options: { amount: number }) => any;

export const minusIcon = icon({
    iconUrl: 'media/button_radius-minus.svg',
    iconRetinaUrl: 'media/button_radius-minus.svg',
    className: 'radius-change',
    iconAnchor: [15, 15],
    popupAnchor: [15, 15],
    iconSize: [30, 30]
});

export const plusIcon = icon({
    iconUrl: 'media/button_radius-plus.svg',
    className: 'radius-change',
    iconRetinaUrl: 'media/button_radius-plus.svg',
    iconAnchor: [15, 15],
    popupAnchor: [15, 15],
    iconSize: [30, 30]
});
