import globalHook, { InitializerFunction } from 'use-global-hook';
import { MainActionsType } from './models';
import { cleanedCounty, getDetailsFromLatLng, isEnglish } from '../utils';
import { Language } from '../config/models';
import {
    Coverage,
    CoverageResponse,
    FeedbackType,
    Level,
    Location,
    Marker,
    MarkerResponse,
    Playlist,
    PlayState,
    PlayType,
    RegionalAudioResponse,
    SeekState,
    View,
    Warning
} from '../models';
import { LocalStorage } from '../utils/localStorage';
import { OutHook } from '../utils/hooks';
import { PlayListPlayer } from './PlayListPlayer';
import { defaultConfig, mainConfig } from '../config';
import { track, TrackingInfo } from './tracker';
import { LatLng } from 'leaflet';
import { allowedDistricts } from '../config/data';
import { BrowserDetector } from '../utils/browserDetection';

export interface MainStore {
    language: Language;
    userLocation?: Location;
    playList: Playlist;
    filteredPlayList: Playlist;
    currentArticleSeconds: number;
    currentArticleIndex: number;
    isLoading: boolean;
    currentView: View;
    lastView?: View;
    views: { front?: View; back?: View };
    showInfo?: boolean;
    playListPlayer?: PlayListPlayer;
    playState: PlayState;
    coverage: Coverage[];
    notification?: { text?: string; type: Level; actionText?: string };
    hasUpdatedPlaylist?: boolean;
    hasUpdatedLocationData?: boolean;
    maxNewsAge: number;
    maxPlaylistDuration: number;
    marker?: Marker[];
    isFirstLoad: boolean;
    newsUnderLimit?: boolean;
    lastNewsAge: number;
    playType?: PlayType;
    displayedWarnings?: Warning[];
}

export interface MainActions {
    setUserLocation: (location?: Location) => void;
    setCurrentArticleIndex: (index: number) => void;
    setCurrentArticleSeconds: (
        seconds?: number,
        index?: number,
        isPlaying?: boolean,
        playType?: PlayType,
        viaOnEnd?: boolean
    ) => void;
    resetPlaylist: () => void;
    resetAll: () => void;
    changeViews: (view: { front?: View; back?: View }, changeCurrentView?: boolean) => void;
    changeCurrentView: (view?: View) => void;
    goBack: () => void;
    getPlaylist: (redirect?: boolean) => void;
    getCoverage: (redirect?: boolean) => void;
    seek: (direction: SeekState | number, progress: number, trigger?: 'manual' | 'automatic') => void;
    play: () => void;
    pause: () => void;
    stop: (isEnd?: boolean) => void;
    setIsLoading: (isLoading: boolean) => void;
    getDeviceLocation: () => void;
    _initPlaylistPlayer: () => void;
    _checkLocationInput: (location?: Location) => boolean;
    _updatePlaylist: (playlist: Playlist, force?: boolean) => void;
    _setListened: (id: number) => void;
    _getMarker: () => void;
    _getTrackingInfo: (index?: number, seconds?: number) => TrackingInfo;
    setNotification: (message?: string, type?: Level, actionText?: string) => void;
    onNotificationAction: () => void;
    setMaxPlaylistDuration: (minutes?: number) => void;
    setMaxNewsAge: (hours?: number, update?: boolean) => void;
    giveFeedback: (types: FeedbackType, info?: string) => string;
    _toggleSession: () => void;
    _showWarning: (warning: Warning) => void;
}

const initialState: MainStore = {
    language: isEnglish() ? Language.EN : Language.DE,
    userLocation: undefined,
    playList: [],
    coverage: [],
    filteredPlayList: [],
    currentArticleSeconds: 0,
    currentArticleIndex: 0,
    isLoading: false,
    showInfo: true,
    playState: 'PAUSE',
    lastNewsAge: defaultConfig.maxNewsAgeHrs,
    maxNewsAge: defaultConfig.maxNewsAgeHrs,
    maxPlaylistDuration: defaultConfig.maxPlaylistDurationMinutes,
    currentView: LocalStorage.getLocation() ? View.PLAYER : View.NO_LOCATION,
    views: {
        front: LocalStorage.getLocation() ? View.PLAYER : View.NO_LOCATION,
        back: View.INFO
    },
    isFirstLoad: true,
    displayedWarnings: []
};

const actions: MainActionsType = {
    async getPlaylist(store, redirect) {
        //console.warn('Getting playlist!');
        if (store.state.userLocation?.center) {
            store.actions.setIsLoading(true);
            const currentLocation = store.state.userLocation;
            const apiParams = mainConfig.apiEndpoint?.includes('bbc-hack')
                ? `getPlaylist?lat=${currentLocation?.center?.lat}&lng=${currentLocation?.center?.lng}&rad=${
                      currentLocation?.radius
                  }&length=${12}&date=${'all'}`
                : `playlist?latitude=${currentLocation?.center?.lat}&longitude=${
                      currentLocation?.center?.lng
                  }&maxRadius=${currentLocation?.radius}&maxDuration=${store.state.maxPlaylistDuration * 60}&maxAge=${
                      store.state.maxNewsAge
                  }&minItems=${defaultConfig.minNews}&maxItems=${defaultConfig.maxNews}`;
            try {
                const response = await fetch(`${mainConfig.apiEndpoint}/${apiParams}`);
                let result: RegionalAudioResponse = await response.json();
                //console.debug('GOT RegionalAudioResponse!', result);
                result.playlist = result.playlist.map((p) => ({
                    ...p,
                    audioFileUrl: !p.audioFileUrl.includes('stream')
                        ? p.audioFileUrl.replace('regional-news/', 'regional-news-stream/')
                        : p.audioFileUrl,
                    location: {
                        ...p?.location,
                        center: {
                            ...p?.location?.center,
                            lat: p?.location?.latitude || p?.location?.center!.lat,
                            lng: p?.location?.longitude || p?.location?.center!.lng
                        }
                    }
                }));
                const filteredPlayList = result.playlist.filter(
                    (i) => i.type !== PlayType.PLING && i.type !== PlayType.INTRO && i.type !== PlayType.OUTRO
                );
                const isSamePlaylist =
                    store.state.filteredPlayList.length === filteredPlayList.length &&
                    store.state.filteredPlayList.every((audio, index) => audio.id === filteredPlayList.at(index)?.id);

                store.setState({
                    playList: result.playlist,
                    isLoading: false,
                    isFirstLoad: false
                });
                store.actions._updatePlaylist(filteredPlayList, !isSamePlaylist);
                if (filteredPlayList.length > 0) {
                    store.actions.setUserLocation({ isSet: true });
                }

                if (redirect && filteredPlayList.length > 0 && !location.hash.includes('/remix/player')) {
                    OutHook.navigate!(store.state.userLocation.isSet ? '/remix/player' : '/remix/settings');
                } else if (
                    redirect &&
                    filteredPlayList.length === 0 &&
                    store.state.userLocation.radius !== defaultConfig.defaultRadius
                ) {
                    store.actions.setNotification(
                        'Leider liegen mit Ihren aktuellen Einstellungen keine neuen Meldungen vor. Bitte vergrößern Sie Ihren Interessen-Radius oder das Nachrichten-Alter oder kommen Sie zu einem späteren Zeitpunkt wieder zurück.',
                        Level.WARNING
                    );
                    OutHook.navigate!('/remix/settings');
                }
                if (store.state.userLocation.isSet && filteredPlayList.length === 0 && !redirect) {
                    OutHook.navigate!('/remix/settings');
                }
                /*console.warn(
                    'SHOW NEWS MAX WARN - store.state.newsUnderLimit',
                    store.state.newsUnderLimit,
                    'filteredPlayList.length',
                    filteredPlayList.length
                );*/
                if (!isSamePlaylist) {
                    store.actions._initPlaylistPlayer();
                }
            } catch (e) {
                console.error(e);
                store.actions.setNotification(
                    'Fehler beim Abruf der Nachrichtenmeldungen. Bitte versuchen Sie es später erneut.',
                    Level.ERROR
                );
                store.actions.setIsLoading(false);
            }
            store.setState({ hasUpdatedPlaylist: false, hasUpdatedLocationData: false });
        }
    },
    async getCoverage(store, redirect) {
        //console.log('GETTING COVERAGE');
        store.actions.setIsLoading(true);
        const currentLocation = store.state.userLocation;
        try {
            await store.actions._getMarker();
            const response = await fetch(
                `${mainConfig.apiEndpoint}/coverage?latitude=${currentLocation?.center?.lat}&longitude=${
                    currentLocation?.center?.lng
                }&minItems=${defaultConfig.minNews}&maxItems=${defaultConfig.maxNews}&maxDuration=${
                    store.state.maxPlaylistDuration * 60
                }&maxAge=${store.state.maxNewsAge}&maxRadius=${defaultConfig.maxRadius}`,
                { headers: { 'cache-control': 'no-cache' } }
            );

            const result: CoverageResponse = await response.json();
            const coverage = [...result.coverage]
                .sort((a, b) => a?.distance - b?.distance)
                .map((c) => ({
                    ...c,
                    distance: c?.distance < defaultConfig.minRadius ? defaultConfig.minRadius : c?.distance
                }));

            if (redirect) {
                store.actions.changeViews({ front: View.PLAYER }, true);
                OutHook.navigate!(store.state.userLocation?.isSet ? '/remix/player' : '/remix/settings');
            }

            const userRadius = store.state.userLocation?.radius || defaultConfig.defaultRadius;

            if (coverage.length > 0) {
                if (
                    userRadius === defaultConfig.defaultRadius ||
                    (userRadius <
                        (coverage.find((c) => c.broadcast_items.length === defaultConfig.minNews)?.distance || 0) &&
                        defaultConfig.autoRadius)
                ) {
                    const preferredRadius = coverage.find(
                        (c) => c.broadcast_items.length >= defaultConfig.minNews + 1
                    )?.distance;
                    store.actions.setUserLocation({
                        radius:
                            preferredRadius ||
                            coverage.find((c) => c.broadcast_items.length <= defaultConfig.minNews)?.distance
                    });
                    if (store.state.userLocation?.isSet) {
                        store.actions.setNotification(
                            `Ihr Interessen-Radius wurde automatisch vergrößert, da für dieses Nachrichten-Alter keine Meldungen im bisherigen Umkreis gefunden wurden.`
                        );
                    }
                }

                const items =
                    [...coverage]
                        .reverse()
                        .find(
                            (c) =>
                                (c.distance || 0) <= (store.state.userLocation?.radius || defaultConfig.defaultRadius)
                        )?.broadcast_items.length || 0;

                if (items <= 29) {
                    store.state.newsUnderLimit = false;
                }
                if (!store.state.newsUnderLimit && items >= defaultConfig.maxNews && !store.state.isFirstLoad) {
                    store.actions.setNotification(
                        'Meldungs-Maximum erreicht. Ihr Regional-Update enthält die 30 nächstgelegenen Nachrichten.',
                        Level.WARNING
                    );
                    store.state.newsUnderLimit = true;
                }

                if (items === 0 && store.state.userLocation?.radius !== defaultConfig.defaultRadius) {
                    store.actions.setNotification(
                        'Leider liegen mit Ihren aktuellen Einstellungen keine neuen Meldungen vor. Bitte vergrößern Sie Ihren Interessen-Radius oder das Nachrichten-Alter oder kommen Sie zu einem späteren Zeitpunkt wieder zurück.',
                        Level.WARNING
                    );
                }
                store.setState({
                    coverage,
                    isLoading: false
                });
            } else {
                store.setState({ isLoading: false });
                setTimeout(
                    () =>
                        store.actions.setNotification(
                            store.state.userLocation
                                ? 'Leider liegen mit Ihren aktuellen Einstellungen keine neuen Meldungen vor. Bitte vergrößern Sie Ihren Interessen-Radius oder das Nachrichten-Alter oder kommen Sie zu einem späteren Zeitpunkt wieder zurück'
                                : 'Leider liegen keine Meldungen vor. Bitte kommen Sie zu einem späteren Zeitpunkt wieder zurück.',
                            Level.WARNING
                        ),
                    500
                );
            }

            store.setState({ isFirstLoad: false });
        } catch (e) {
            console.error(e);
            store.actions.setNotification(
                'Fehler beim Abruf der Nachrichtenmeldungen. Bitte versuchen Sie es später erneut.',
                Level.ERROR
            );
            store.actions.setIsLoading(false);
        }
    },
    async _getMarker(store) {
        try {
            store.actions.setIsLoading(true);
            const response = await fetch(`${mainConfig.apiEndpoint}/global_coverage?maxAge=${store.state.maxNewsAge}`, {
                headers: { 'cache-control': 'no-cache' }
            });

            const result: MarkerResponse = await response.json();
            if (result) {
                store.setState({ marker: result.coverage });
            }
            store.actions.setIsLoading(false);
        } catch (e) {
            store.actions.setIsLoading(false);
        }
    },
    setUserLocation(store, userLocation) {
        const longitude = userLocation?.longitude || store.state.userLocation?.longitude;
        const latitude = userLocation?.latitude || store.state.userLocation?.latitude;
        const newData: Location | undefined = userLocation
            ? { ...store.state.userLocation, ...userLocation, longitude, latitude }
            : undefined;
        const oldRadius = store.state.userLocation?.radius;
        //console.warn('UPDATING USER LOCATION DATA TO', userLocation);
        if (newData?.radius) {
            newData.radiusRounded = Math.round(newData.radius);
        }

        if (!!userLocation?.radius) {
            track(
                'settings',
                userLocation.radius > (oldRadius || 0) ? 'increaseRadius' : 'decreaseRadius',
                userLocation.radius
            );
        }

        if (!!userLocation?.radius && newData) {
            if (userLocation.radius >= defaultConfig.maxRadius) {
                store.actions.setNotification(
                    `Maximaler Interessen-Radius erreicht (${defaultConfig.maxRadius} km o. ${defaultConfig.maxNews} Meldungen).`,
                    Level.INFO
                );
                newData.radiusRounded = defaultConfig.maxRadius;
                newData.radius = defaultConfig.maxRadius;
            } else if (
                userLocation.radius <=
                    (store.state.coverage.find((c) => c.broadcast_items.length === defaultConfig.minNews)?.distance ||
                        0) &&
                (store.state.userLocation?.radius || defaultConfig.defaultRadius) >
                    (newData.radius || defaultConfig.defaultRadius)
            ) {
                store.actions.setNotification(
                    `Kleinstmöglicher Interessen-Radius erreicht (mind. ${defaultConfig.minNews} Meldung${
                        defaultConfig.minNews === 1 ? '' : 'en'
                    }).`,
                    Level.INFO
                );
            }
        }

        store.setState({ userLocation: newData });

        store.setState({ hasUpdatedLocationData: true });
        LocalStorage.setLocation(newData);
        if (newData === undefined) {
            store.actions.resetPlaylist();
        }
        if (userLocation?.hasOwnProperty('center') && store.actions._checkLocationInput(userLocation)) {
            store.actions.getCoverage(true);
        }
    },
    setCurrentArticleIndex(store, currentArticleIndex) {
        store.actions._setListened(currentArticleIndex);
        store.setState({ currentArticleIndex, currentArticleSeconds: 0 });
    },
    setCurrentArticleSeconds(
        store,
        currentArticleSeconds = store.state.currentArticleSeconds,
        currentArticleIndex = store.state.currentArticleIndex,
        isPlaying,
        playType,
        viaOnEnd
    ) {
        /*console.log(
            'Set currentArticleSeconds',
            currentArticleSeconds,
            currentArticleIndex,
            store.state.filteredPlayList[store.state.currentArticleIndex]?.duration
        );*/

        store.actions._setListened(currentArticleIndex);

        if (currentArticleIndex !== store.state.currentArticleIndex && viaOnEnd) {
            track(
                'player',
                'heartbeat',
                undefined,
                'automatic',
                store.actions._getTrackingInfo(currentArticleIndex, currentArticleSeconds)
            );
        }

        store.setState({
            currentArticleSeconds,
            currentArticleIndex,
            playState: isPlaying ? 'PLAY' : 'PAUSE',
            playType
        });
    },
    resetPlaylist(store) {
        //console.log('Resetting Playlist');
        LocalStorage.resetPlaylist();
        store.setState({
            playList: [],
            currentArticleSeconds: 0,
            currentArticleIndex: 0
        });
    },
    resetAll(store) {
        const result = confirm('Möchten Sie Ihren Ort wirklich zurücksetzen?');
        if (result) {
            track('settings', 'reset');
            store.actions.resetPlaylist();
            LocalStorage.resetShowOnboarding();
            LocalStorage.resetMaxAge();
            LocalStorage.resetMaxDuration();
            LocalStorage.resetLocation();
            LocalStorage.resetShowIntro();
            store.setState(initialState);
            store.setState({
                views: {
                    front: View.NO_LOCATION,
                    back: View.INFO
                }
            });
            store.setState({
                currentView: View.NO_LOCATION
            });
            OutHook.navigate!('/');
        }
    },
    async _initPlaylistPlayer(store) {
        const introSound =
            store.state.playList.find((p) => p.type === 'INTRO')?.audioFileUrl ||
            'https://storage.googleapis.com/bbc-hackathon/intro.mp3';
        const outroSound =
            store.state.playList.find((p) => p.type === 'OUTRO')?.audioFileUrl ||
            'https://storage.googleapis.com/bbc-hackathon/outro.mp3';
        const plingSound =
            store.state.playList.find((p) => p.type === 'PLING')?.audioFileUrl ||
            'https://storage.googleapis.com/bbc-hackathon/flow.mp3';
        await store.state.playListPlayer?.reset();

        const playListPlayer = new PlayListPlayer(store.state.filteredPlayList, introSound, outroSound, plingSound);

        playListPlayer.onPlaying(store.actions.setCurrentArticleSeconds);
        playListPlayer.onEnd(() => {
            track('player', 'stop', undefined, 'automatic', store.actions._getTrackingInfo());
            store.actions.pause();
        });

        store.setState({
            playListPlayer
        });
    },

    seek(store, seek, seconds: number, trigger) {
        //console.warn('SEEKING', seek, seconds);
        const currentSeconds = store.state.currentArticleSeconds;
        const lastIndex = store.state.currentArticleIndex;
        if (seek !== 'FORWARD' && seek !== 'BACKWARD') {
            if (store.state.playState === 'PLAY') {
                store.state.playListPlayer?.play(seek, seconds);
            } else {
                store.actions.setCurrentArticleSeconds(seconds, seek);
            }

            track(
                'player',
                seek > lastIndex || (seconds > currentSeconds && seek === lastIndex) ? 'forward' : 'backward',
                'seek',
                trigger,
                store.actions._getTrackingInfo(seek, seconds)
            );
        } else {
            if (store.state.playState === 'PLAY') {
                if (seek === 'BACKWARD') {
                    store.state.playListPlayer?.back();
                } else {
                    store.state.playListPlayer?.skip();
                }
                setTimeout(() => track('player', 'forward', 'skip', trigger, store.actions._getTrackingInfo()), 250);
            } else {
                const playList = store.state.filteredPlayList;
                const newIndex = seek === 'BACKWARD' ? lastIndex - 1 : lastIndex + 1;
                const lastElementsDuration = store.state.filteredPlayList.at(-1)?.duration;
                const shouldJumpToEnd = newIndex > playList.length - 1 && currentSeconds !== lastElementsDuration;
                let calculatedIndex = 0;
                if (shouldJumpToEnd) {
                    //console.log('WILL JUMP TO END');
                    store.actions.setCurrentArticleSeconds(lastElementsDuration);
                    store.actions.pause();
                } else {
                    //console.log('NEW INDEX IS', newIndex);
                    if (lastIndex !== newIndex || lastIndex === playList.length - 1) {
                        if (newIndex > lastIndex) {
                            const index = newIndex;
                            calculatedIndex = index > playList.length - 1 ? 0 : index;
                            if (trigger === 'manual') {
                                track(
                                    'player',
                                    'forward',
                                    'skip',
                                    trigger,
                                    store.actions._getTrackingInfo(calculatedIndex, 0)
                                );
                            }
                        } else {
                            const index = newIndex;
                            calculatedIndex = index < 0 ? 0 : index;

                            if (trigger === 'manual') {
                                track(
                                    'player',
                                    'backward',
                                    'skip',
                                    trigger,
                                    store.actions._getTrackingInfo(calculatedIndex, 0)
                                );
                            }
                        }
                        //console.log('SETTING TO CALCULATED INDEX', calculatedIndex);
                        store.actions.setCurrentArticleSeconds(0, calculatedIndex);
                    } else {
                        //console.log('SETTING TO NEW INDEX', calculatedIndex);
                        store.actions.setCurrentArticleSeconds(0, newIndex);
                    }
                }
            }
        }
    },
    setIsLoading(store, isLoading) {
        //console.log('Set is loading', isLoading);
        store.setState({ isLoading });
    },
    async getDeviceLocation(store) {
        //console.debug('Trying to get location');
        store.actions.setIsLoading(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { city, district } = await getDetailsFromLatLng({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });

                    store.actions.resetPlaylist();
                    store.actions.setUserLocation({
                        center: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        },
                        name: city,
                        district,
                        radius: defaultConfig.defaultRadius
                    });

                    setTimeout(() => track('start', 'getGeoLocation', city), 250);

                    //console.log('DISABLING LOADING');
                    store.actions.setIsLoading(false);
                },
                (error) => {
                    alert('Konnte Geolocation nicht abfragen: ' + error.message);
                    store.actions.setIsLoading(false);
                }
            );
        } else {
            alert(
                'Konnte Geolocation nicht abfragen. Bitte überprüfe deine Browser-Berechtigung zur Nutzung des Standorts '
            );
            store.actions.setIsLoading(false);
        }
    },
    play(store) {
        if (
            store.state.currentArticleIndex === store.state.filteredPlayList.length - 1 &&
            store.state.playType === PlayType.OUTRO
        ) {
            store.actions.setCurrentArticleSeconds(0, 0);
        }
        store.state.playListPlayer?.play(store.state.currentArticleIndex, store.state.currentArticleSeconds);

        const version = BrowserDetector.getIOsVersion();

        if (version?.includes('17.0')) {
            store.actions._showWarning(Warning.IOS_17);
        }
    },
    pause(store) {
        store.setState({ playState: 'PAUSE' });
        store.state.playListPlayer?.pause();
        if (store.state.playState === 'PLAY') {
            track('player', 'pause', undefined, 'automatic', store.actions._getTrackingInfo());
        }
    },
    stop(store, isEnd) {
        //console.error('STOPPING PLAYER');
        if (!isEnd) {
            store.actions.setCurrentArticleSeconds(0);
        }

        store.setState({ playState: 'PAUSE' });
        store.state.playListPlayer?.stop();
    },
    changeViews(store, view, changeCurrentView) {
        //console.log('Changing views', view, changeCurrentView);
        store.setState({
            views: {
                front: view.front || store.state.views.front,
                back: view.back || store.state.views.back
            }
        });
        if (changeCurrentView && (view.front || view.back)) {
            store.actions.changeCurrentView((view.front || view.back)!);
        }
    },
    goBack(store) {
        store.actions.changeCurrentView(store.state.views.front);
    },
    changeCurrentView(store, currentView) {
        const lastView = currentView ? store.state.currentView : store.state.lastView;
        let newView = currentView;
        const views = store.state.views;
        // console.log('Change current view to:', newView, 'and last view is:', lastView, store.state.currentView, views);

        if (!currentView) {
            if (views.back !== newView && views.front !== newView) {
                store.actions.changeViews({ [defaultConfig.cardState[lastView!].side]: lastView });
                newView = lastView;
            } else if (lastView === store.state.currentView) {
                if (views.back === lastView) {
                    newView = store.state.views.front;
                } else {
                    newView = store.state.views.back;
                }
            }
        }

        store.setState({
            currentView: newView,
            lastView
        });
    },
    _updatePlaylist(store, playList, force) {
        const currentPlaylist = store.state.filteredPlayList;
        let currentArticleIndex = store.state.currentArticleIndex;
        let currentArticleSeconds = store.state.currentArticleSeconds;
        let hasUpdatedPlaylist = !!force;
        //console.warn('STARTING UPDATING PLAYLIST:', playList, currentPlaylist, 'USE FORCE?', !!force);
        const updatedPlaylist = playList.map((audio, index) => {
            if (
                currentPlaylist[index] &&
                (currentPlaylist[index].title === audio.title || currentPlaylist[index].id === audio.id)
            ) {
                if (!audio.listened) {
                    hasUpdatedPlaylist = true;
                }

                audio.listened = currentPlaylist[index].listened;
            }
            return audio;
        });
        if (playList[0]?.id !== currentPlaylist[0]?.id) {
            hasUpdatedPlaylist = true;
            currentArticleIndex = 0;
            currentArticleSeconds = 0;
        }
        if (hasUpdatedPlaylist) {
            //console.log('---------- PLAYLIST UPDATED!');
            store.setState({
                currentArticleSeconds,
                currentArticleIndex,
                hasUpdatedPlaylist,
                filteredPlayList: updatedPlaylist
            });
        }

        LocalStorage.setPlaylist(updatedPlaylist);
    },
    _setListened(store, id) {
        const currentPlaylist = store.state.filteredPlayList;
        if (!currentPlaylist.at(id)?.listened) {
            let updatedPlaylist = currentPlaylist;
            updatedPlaylist = currentPlaylist.map((audio, index) => {
                if (index === id) {
                    //console.log('#######Set Listening for index:', lastId);
                    audio.listened = true;
                }
                return audio;
            });
            store.setState({ hasUpdatedPlaylist: false });
            store.actions._updatePlaylist(updatedPlaylist);
        }
    },
    _showWarning(store, warning) {
        if (!store.state.displayedWarnings?.includes(warning)) {
            let message: string;
            switch (warning) {
                case Warning.IOS_17:
                    // https://developer.apple.com/forums/thread/740276
                    message =
                        'Hinweis: Unter iOS 17.0 treten Fehler bei der Audio-Wiedergabe auf. Bitte aktualisieren Sie nach Möglichkeit auf iOS 17.1';
                    break;
                default:
                    return;
            }
            store.actions.setNotification(message, Level.WARNING);
            store.state.displayedWarnings?.push(warning);
        }
    },
    setNotification(store, text, type = Level.INFO, actionText) {
        if (text) {
            track('notification', type, text);
        }
        store.setState({
            notification: {
                text,
                type,
                actionText
            }
        });
    },
    onNotificationAction() {},
    setMaxPlaylistDuration(store, maxPlaylistDuration = initialState.maxPlaylistDuration) {
        //console.log('SETTING MAX PLAYLIST DURATION');
        store.setState({ maxPlaylistDuration });
        LocalStorage.setMaxDuration(maxPlaylistDuration);
    },
    setMaxNewsAge(store, maxNewsAge = store.state.maxNewsAge, update) {
        // TODO: WELCHE LOGIK, wenn ganz aufgedreht und plötzlich mehr als 30 in Radius - TEXT - BILD SCHERE, da evtl mehr marker in RADIUS drin!?
        const lastAge = store.state.lastNewsAge;
        //console.warn('SETTING MAX NEWS AGE', maxNewsAge, typeof maxNewsAge, ' - update?', update, '-- from', lastAge);
        store.setState({ maxNewsAge });

        if (update) {
            if (maxNewsAge > lastAge) {
                track('settings', 'increaseNewsAge', maxNewsAge);
            } else {
                track('settings', 'decreaseNewsAge', maxNewsAge);
            }
            store.actions.getCoverage();
            if (store.state.filteredPlayList?.length > 0) {
                store.actions.getPlaylist();
            }
            store.setState({ lastNewsAge: maxNewsAge });
        }

        LocalStorage.setMaxAge(maxNewsAge);
    },
    _checkLocationInput(store, location) {
        const mappedLocalityData = allowedDistricts?.find(
            (d) => cleanedCounty(d.kreise_2021_name) === cleanedCounty(location?.district)
        );

        //console.warn('MAPPEDLOCATIONDATA', mappedLocalityData, 'FOR LOCATION', location);
        if (
            location &&
            location.center &&
            defaultConfig.bounds.contains(new LatLng(location.center.lat, location.center.lng)) &&
            mappedLocalityData
        ) {
            return true;
        } else {
            store.actions.setUserLocation();
            store.actions.setNotification(
                `Dieser Ort liegt leider ausserhalb unseres Regional-Angebots. Bitte wählen Sie einen Ort in Bayern.`,
                Level.WARNING
            );
            return false;
        }
    },
    _getTrackingInfo(
        store,
        currentArticleIndex = store.state.currentArticleIndex,
        currentArticleSeconds = store.state.currentArticleSeconds
    ) {
        //console.warn('GET TRACKING INFO', currentArticleSeconds);
        const playlist = store.state.filteredPlayList;
        return {
            cursorPosition: currentArticleSeconds,
            newsItemIndex: currentArticleIndex,
            newsItemDuration: playlist.at(currentArticleIndex)?.duration,
            playlistCursorPosition:
                playlist.filter((p, i) => i < currentArticleIndex).reduce((a, b) => a + b.duration!, 0) +
                currentArticleSeconds,
            content: `${store.state.filteredPlayList[currentArticleIndex]?.location.name} - ${store.state.filteredPlayList[currentArticleIndex]?.title}`
        };
    },
    async giveFeedback(store, type, info) {
        try {
            LocalStorage.setDidFeedback(true);
            track('feedback', type, info);
            if (type || !!info) {
                store.actions.setNotification('Vielen Dank für Ihr Feedback!', Level.SUCCESS);
            }
        } catch (e) {
            console.error('Could not send rating:', e);
        }
    },
    _toggleSession(store) {
        let handler: any;
        const checkFeedbackTrigger = () => {
            const playlist = store.state.filteredPlayList;
            if (playlist.length > 0 && playlist.filter((p) => p.listened).length > 1) {
                const last = LocalStorage.usageTime() || 0;
                if (last > defaultConfig.feedbackHintTime) {
                    LocalStorage.setDidFeedback(true);
                    clearInterval(handler);
                } else {
                    LocalStorage.setUsageTime(last + 1);
                }
            }
        };
        if (!LocalStorage.didFeedback() && defaultConfig.feedbackHintTime !== 0) {
            handler = setInterval(checkFeedbackTrigger, 1000);
        }
    }
};

const initStore: InitializerFunction<MainStore, MainActions> = async (store) => {
    const userLocation = await LocalStorage.getLocation();
    const maxPlaylistDuration = (await LocalStorage.maxDuration()) || initialState.maxPlaylistDuration;
    const maxNewsAge = (await LocalStorage.maxAge()) || initialState.maxNewsAge;
    let playlist = await LocalStorage.getPlaylist();
    playlist = playlist === undefined ? [] : playlist;
    const currentArticleIndex = (playlist.filter((a) => a.listened).length || 1) - 1;

    store.setState({
        ...store.state,
        showInfo: false,
        newsUnderLimit: playlist.length >= 30,
        userLocation,
        filteredPlayList: playlist,
        views: {
            front: !!userLocation ? View.PLAYER : View.NO_LOCATION,
            back: View.INFO
        },
        currentView: !!userLocation ? View.PLAYER : View.NO_LOCATION,
        currentArticleIndex,
        maxPlaylistDuration,
        maxNewsAge,
        lastNewsAge: maxNewsAge
    });

    store.actions._toggleSession();

    if (playlist.length > 0) {
        store.actions._initPlaylistPlayer();
    }
};

// @ts-ignore
export const useGlobal = globalHook<MainStore, MainActions>(initialState, actions, initStore);
