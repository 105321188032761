import React from 'react';
import styled from 'styled-components';
import { miniMobile, mobile, STYLE } from '../../styles/variables';
import { MapContainer } from './MapContainer';
import { HeaderContainer } from './HeaderContainer';
import { CardContainer } from './CardContainer.tsx';
import { RestartContainer } from './RestartContainer';

const MainWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    position: relative;
    overflow: hidden;

    > button {
        position: fixed;
        top: calc(2.2 * ${STYLE.default.padding});
        right: calc(2.25 * ${STYLE.default.padding});
        margin: 0 !important;
        z-index: 1000;
        padding: 0.35rem 1rem !important;
    }

    @media ${mobile} {
        overflow-y: scroll;
    }

    @media ${miniMobile} {
    }
`;

export const ApplicationWrapper: React.FC = () => {
    return (
        <MainWrapper>
            <MapContainer />
            <HeaderContainer />
            <CardContainer />
            <RestartContainer />
        </MainWrapper>
    );
};
