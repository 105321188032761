export const allowedDistricts = [
    {
        ags: '09161',
        kreise_2021_name: 'Ingolstadt',
        stadt_land: 'Stadt'
    },
    {
        ags: '09162',
        kreise_2021_name: 'München, Landeshauptstadt',
        stadt_land: 'Stadt'
    },
    {
        ags: '09163',
        kreise_2021_name: 'Rosenheim',
        stadt_land: 'Stadt'
    },
    {
        ags: '09171',
        kreise_2021_name: 'Altötting',
        stadt_land: 'Stadt'
    },
    {
        ags: '09172',
        kreise_2021_name: 'Berchtesgadener Land',
        stadt_land: 'Land'
    },
    {
        ags: '09173',
        kreise_2021_name: 'Bad Tölz-Wolfratshausen',
        stadt_land: 'Land'
    },
    {
        ags: '09174',
        kreise_2021_name: 'Dachau',
        stadt_land: 'Stadt'
    },
    {
        ags: '09175',
        kreise_2021_name: 'Ebersberg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09176',
        kreise_2021_name: 'Eichstätt',
        stadt_land: 'Land'
    },
    {
        ags: '09177',
        kreise_2021_name: 'Erding',
        stadt_land: 'Land'
    },
    {
        ags: '09178',
        kreise_2021_name: 'Freising',
        stadt_land: 'Stadt'
    },
    {
        ags: '09179',
        kreise_2021_name: 'Fürstenfeldbruck',
        stadt_land: 'Stadt'
    },
    {
        ags: '09180',
        kreise_2021_name: 'Garmisch-Partenkirchen',
        stadt_land: 'Land'
    },
    {
        ags: '09181',
        kreise_2021_name: 'Landsberg am Lech',
        stadt_land: 'Land'
    },
    {
        ags: '09182',
        kreise_2021_name: 'Miesbach',
        stadt_land: 'Land'
    },
    {
        ags: '09183',
        kreise_2021_name: 'Mühldorf am Inn',
        stadt_land: 'Land'
    },
    {
        ags: '09184',
        kreise_2021_name: 'München',
        stadt_land: 'Stadt'
    },
    {
        ags: '09185',
        kreise_2021_name: 'Neuburg-Schrobenhausen',
        stadt_land: 'Land'
    },
    {
        ags: '09186',
        kreise_2021_name: 'Pfaffenhofen an der Ilm',
        stadt_land: 'Stadt'
    },
    {
        ags: '09187',
        kreise_2021_name: 'Rosenheim',
        stadt_land: 'Stadt'
    },
    {
        ags: '09188',
        kreise_2021_name: 'Starnberg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09189',
        kreise_2021_name: 'Traunstein',
        stadt_land: 'Land'
    },
    {
        ags: '09190',
        kreise_2021_name: 'Weilheim-Schongau',
        stadt_land: 'Land'
    },
    {
        ags: '09261',
        kreise_2021_name: 'Landshut',
        stadt_land: 'Land'
    },
    {
        ags: '09262',
        kreise_2021_name: 'Passau',
        stadt_land: 'Land'
    },
    {
        ags: '09263',
        kreise_2021_name: 'Straubing',
        stadt_land: 'Land'
    },
    {
        ags: '09271',
        kreise_2021_name: 'Deggendorf',
        stadt_land: 'Land'
    },
    {
        ags: '09272',
        kreise_2021_name: 'Freyung-Grafenau',
        stadt_land: 'Land'
    },
    {
        ags: '09273',
        kreise_2021_name: 'Kelheim',
        stadt_land: 'Land'
    },
    {
        ags: '09274',
        kreise_2021_name: 'Landshut',
        stadt_land: 'Land'
    },
    {
        ags: '09275',
        kreise_2021_name: 'Passau',
        stadt_land: 'Land'
    },
    {
        ags: '09276',
        kreise_2021_name: 'Regen',
        stadt_land: 'Land'
    },
    {
        ags: '09277',
        kreise_2021_name: 'Rottal-Inn',
        stadt_land: 'Land'
    },
    {
        ags: '09278',
        kreise_2021_name: 'Straubing-Bogen',
        stadt_land: 'Land'
    },
    {
        ags: '09279',
        kreise_2021_name: 'Dingolfing-Landau',
        stadt_land: 'Land'
    },
    {
        ags: '09361',
        kreise_2021_name: 'Amberg',
        stadt_land: 'Land'
    },
    {
        ags: '09362',
        kreise_2021_name: 'Regensburg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09363',
        kreise_2021_name: 'Weiden in der Oberpfalz',
        stadt_land: 'Land'
    },
    {
        ags: '09371',
        kreise_2021_name: 'Amberg-Sulzbach',
        stadt_land: 'Land'
    },
    {
        ags: '09372',
        kreise_2021_name: 'Cham',
        stadt_land: 'Land'
    },
    {
        ags: '09373',
        kreise_2021_name: 'Neumarkt in der Oberpfalz',
        stadt_land: 'Land'
    },
    {
        ags: '09374',
        kreise_2021_name: 'Neustadt an der Waldnaab',
        stadt_land: 'Land'
    },
    {
        ags: '09375',
        kreise_2021_name: 'Regensburg',
        stadt_land: 'Land'
    },
    {
        ags: '09376',
        kreise_2021_name: 'Schwandorf',
        stadt_land: 'Land'
    },
    {
        ags: '09377',
        kreise_2021_name: 'Tirschenreuth',
        stadt_land: 'Land'
    },
    {
        ags: '09461',
        kreise_2021_name: 'Bamberg',
        stadt_land: 'Land'
    },
    {
        ags: '09462',
        kreise_2021_name: 'Bayreuth',
        stadt_land: 'Land'
    },
    {
        ags: '09463',
        kreise_2021_name: 'Coburg',
        stadt_land: 'Land'
    },
    {
        ags: '09464',
        kreise_2021_name: 'Hof',
        stadt_land: 'Land'
    },
    {
        ags: '09471',
        kreise_2021_name: 'Bamberg',
        stadt_land: 'Land'
    },
    {
        ags: '09472',
        kreise_2021_name: 'Bayreuth',
        stadt_land: 'Land'
    },
    {
        ags: '09473',
        kreise_2021_name: 'Coburg',
        stadt_land: 'Land'
    },
    {
        ags: '09474',
        kreise_2021_name: 'Forchheim',
        stadt_land: 'Land'
    },
    {
        ags: '09475',
        kreise_2021_name: 'Hof',
        stadt_land: 'Land'
    },
    {
        ags: '09476',
        kreise_2021_name: 'Kronach',
        stadt_land: 'Land'
    },
    {
        ags: '09477',
        kreise_2021_name: 'Kulmbach',
        stadt_land: 'Land'
    },
    {
        ags: '09478',
        kreise_2021_name: 'Lichtenfels',
        stadt_land: 'Land'
    },
    {
        ags: '09479',
        kreise_2021_name: 'Wunsiedel im Fichtelgebirge',
        stadt_land: 'Land'
    },
    {
        ags: '09561',
        kreise_2021_name: 'Ansbach',
        stadt_land: 'Land'
    },
    {
        ags: '09562',
        kreise_2021_name: 'Erlangen',
        stadt_land: 'Stadt'
    },
    {
        ags: '09563',
        kreise_2021_name: 'Fürth',
        stadt_land: 'Stadt'
    },
    {
        ags: '09564',
        kreise_2021_name: 'Nürnberg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09565',
        kreise_2021_name: 'Schwabach',
        stadt_land: 'Land'
    },
    {
        ags: '09571',
        kreise_2021_name: 'Ansbach',
        stadt_land: 'Land'
    },
    {
        ags: '09572',
        kreise_2021_name: 'Erlangen-Höchstadt',
        stadt_land: 'Stadt'
    },
    {
        ags: '09573',
        kreise_2021_name: 'Fürth',
        stadt_land: 'Stadt'
    },
    {
        ags: '09574',
        kreise_2021_name: 'Nürnberger Land',
        stadt_land: 'Stadt'
    },
    {
        ags: '09575',
        kreise_2021_name: 'Neustadt an der Aisch-Bad Windsheim',
        stadt_land: 'Land'
    },
    {
        ags: '09576',
        kreise_2021_name: 'Roth',
        stadt_land: 'Land'
    },
    {
        ags: '09577',
        kreise_2021_name: 'Weißenburg-Gunzenhausen',
        stadt_land: 'Land'
    },
    {
        ags: '09661',
        kreise_2021_name: 'Aschaffenburg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09662',
        kreise_2021_name: 'Schweinfurt',
        stadt_land: 'Land'
    },
    {
        ags: '09663',
        kreise_2021_name: 'Würzburg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09671',
        kreise_2021_name: 'Aschaffenburg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09672',
        kreise_2021_name: 'Bad Kissingen',
        stadt_land: 'Land'
    },
    {
        ags: '09673',
        kreise_2021_name: 'Rhön-Grabfeld',
        stadt_land: 'Land'
    },
    {
        ags: '09674',
        kreise_2021_name: 'Haßberge',
        stadt_land: 'Land'
    },
    {
        ags: '09675',
        kreise_2021_name: 'Kitzingen',
        stadt_land: 'Land'
    },
    {
        ags: '09676',
        kreise_2021_name: 'Miltenberg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09677',
        kreise_2021_name: 'Main-Spessart',
        stadt_land: 'Land'
    },
    {
        ags: '09678',
        kreise_2021_name: 'Schweinfurt',
        stadt_land: 'Land'
    },
    {
        ags: '09679',
        kreise_2021_name: 'Würzburg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09761',
        kreise_2021_name: 'Augsburg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09762',
        kreise_2021_name: 'Kaufbeuren',
        stadt_land: 'Land'
    },
    {
        ags: '09763',
        kreise_2021_name: 'Kempten (Allgäu)',
        stadt_land: 'Land'
    },
    {
        ags: '09764',
        kreise_2021_name: 'Memmingen',
        stadt_land: 'Land'
    },
    {
        ags: '09771',
        kreise_2021_name: 'Aichach-Friedberg',
        stadt_land: 'Land'
    },
    {
        ags: '09772',
        kreise_2021_name: 'Augsburg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09773',
        kreise_2021_name: 'Dillingen an der Donau',
        stadt_land: 'Land'
    },
    {
        ags: '09774',
        kreise_2021_name: 'Günzburg',
        stadt_land: 'Stadt'
    },
    {
        ags: '09775',
        kreise_2021_name: 'Neu-Ulm',
        stadt_land: 'Stadt'
    },
    {
        ags: '09776',
        kreise_2021_name: 'Lindau (Bodensee)',
        stadt_land: 'Stadt'
    },
    {
        ags: '09777',
        kreise_2021_name: 'Ostallgäu',
        stadt_land: 'Land'
    },
    {
        ags: '09778',
        kreise_2021_name: 'Unterallgäu',
        stadt_land: 'Land'
    },
    {
        ags: '09779',
        kreise_2021_name: 'Donau-Ries',
        stadt_land: 'Land'
    },
    {
        ags: '09780',
        kreise_2021_name: 'Oberallgäu',
        stadt_land: 'Land'
    }
];
