import React, { useCallback, useEffect } from 'react';
import { useGlobal } from '../../logic/main';
import { View } from '../../models';
import { NoLocation } from '../../views/NoLocation';
import { RegionalRemix } from '../../views/RegionalRemix';
import { Info } from '../../views/Info';
import { Card } from '../presenter/Card';
import { defaultConfig } from '../../config';
import { useLocation } from 'react-router-dom';
import { FAQs } from '../../views/FAQs';
import { Feedback } from '../../views/Feedback';
import { trackPage } from '../../logic/tracker';

export const CardContainer: React.FC = () => {
    const location = useLocation();
    const [{ views, currentView }] = useGlobal((state) => ({
        views: state.views,
        currentView: state.currentView
    }));

    const trackView = (view: string, currentView: View, toMatch: View) =>
        currentView === toMatch ? trackPage(view) : undefined;

    const getView = useCallback(
        (type: 'front' | 'back') => {
            //console.log('GETTING VIEW...', views);
            switch (views[type]) {
                case View.NO_LOCATION:
                    trackView('start', currentView, View.NO_LOCATION);
                    return <NoLocation />;
                case View.PLAYER:
                    trackView(
                        location.pathname.includes('remix/settings') ? 'settings' : 'player',
                        currentView,
                        View.PLAYER
                    );
                    return <RegionalRemix />;
                case View.INFO:
                    trackView('about', currentView, View.INFO);
                    return <Info />;
                case View.FAQ:
                    trackView('faq', currentView, View.FAQ);
                    return <FAQs />;
                case View.FEEDBACK:
                    trackView('feedback', currentView, View.FEEDBACK);
                    return <Feedback />;
            }
        },
        [currentView]
    );

    useEffect(() => {}, [currentView, location]);

    return (
        <Card
            position={defaultConfig.cardState[currentView].position}
            side={defaultConfig.cardState[currentView].side}
            backContent={getView('back')}
            enableOverflow={currentView === View.NO_LOCATION}
        >
            {getView('front')}
        </Card>
    );
};
