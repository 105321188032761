import React, { useCallback, useEffect, useMemo } from 'react';
import { Listen } from './tabs/Listen.RegionalRemix';
import { Settings } from './tabs/Settings.RegionalRemix';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobal } from '../logic/main';
import { Page } from '../components/presenter/Page';
import { Tab, Tabs, TabList, TabPanel } from '../styles/tabs';
import { ListenedIndicatorBadge } from '../components/container/ListenedIndicatorBadge';
import { track, trackPage } from '../logic/tracker';

let isFirstLoad = true;

const pathIndexMap = ['/remix/player', '/remix/settings'];

export const RegionalRemix: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [{ userLocation, playlist }, { getPlaylist }] = useGlobal(
        (state) => ({
            userLocation: state.userLocation,
            playlist: state.filteredPlayList
        }),
        (actions) => ({
            reset: actions.resetAll,
            getCoverage: actions.getCoverage,
            getPlaylist: actions.getPlaylist
        })
    );

    const [value, setValue] = React.useState(
        !!userLocation?.isSet ? (location.pathname.includes('remix/player') ? 0 : 1) : 1
    );

    const changeTabIfNotYet = useCallback(
        ({ index, path }: { index?: number; path?: string }) => {
            let selectedIndex: number | undefined = index;
            let selectedPath: string | undefined = path;
            if (path !== undefined) {
                selectedIndex = pathIndexMap.indexOf(path);
            }
            if (index !== undefined) {
                selectedPath = pathIndexMap[index];
            }
            if (selectedIndex !== undefined && value !== selectedIndex) {
                setValue(selectedIndex);
            }
            if (selectedPath !== undefined && location.pathname !== selectedPath) {
                navigate(selectedPath);
            }
        },
        [value, location.pathname]
    );

    const handleChangeIndex = (index: number) => {
        changeTabIfNotYet({ index });
    };

    useEffect(() => {
        changeTabIfNotYet({ path: location.pathname });
        if (isFirstLoad && !!userLocation?.isSet) {
            isFirstLoad = false;
            getPlaylist();
        }
        if (!location.pathname.includes('remix/settings') && !location.pathname.includes('remix/player')) {
            if (userLocation?.isSet) {
                changeTabIfNotYet({ index: 0 });
            } else {
                changeTabIfNotYet({ index: 1 });
            }
        }
        trackPage(location.pathname.includes('remix/settings') ? 'settings' : 'player');
    }, [userLocation, location.pathname]);

    const isPlayerDisabled = useMemo(() => !userLocation?.isSet || playlist.length === 0, [userLocation, playlist]);

    return (
        <Page skipPadding={true} showFooter={true} id={'remix-page'} bgColor={'transparent'}>
            <Tabs selectedIndex={value} onSelect={handleChangeIndex}>
                <TabList>
                    <Tab
                        isDisabled={!userLocation?.isSet}
                        title="Ihr Update"
                        onClick={(e) => {
                            if (isPlayerDisabled) {
                                track('start', 'startedVia', 'tab');
                                e.preventDefault();
                                e.stopPropagation();
                                getPlaylist(true);
                            }
                        }}
                    >
                        <ListenedIndicatorBadge>Ihr Update</ListenedIndicatorBadge>
                    </Tab>
                    <Tab title="Einstellungen">Einstellungen</Tab>
                </TabList>
                <TabPanel disabled={!userLocation?.isSet}>
                    <Listen />
                </TabPanel>
                <TabPanel>
                    <Settings />
                </TabPanel>
            </Tabs>
        </Page>
    );
};
