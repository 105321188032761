import React from 'react';
import styled from 'styled-components';
import { miniMobile, STYLE } from '../../styles/variables';
import { transitionTime, transitionType } from './Card.old';

const Wrapper = styled.div<Props>`
    display: flex;
    align-items: center;
    width: 100%;
    left: 50%;
    position: fixed;
    top: calc(3.72 * ${STYLE.default.padding});
    justify-content: flex-start;
    margin-left: calc(2.25 * ${STYLE.default.padding});
    opacity: ${({ isTop }) => (isTop ? 1 : 0)};
    transform: translate(-50%, -50%);
    z-index: 999;
    transition: opacity ${transitionTime}s ${transitionType};
`;

const StyledHeader = styled.img`
    height: 2.25rem;

    @media ${miniMobile} {
        max-width: 50%;
    }
`;

interface Props {
    isTop?: boolean;
}

export const Header: React.FC<Props> = ({ isTop }) => {
    return (
        <Wrapper isTop={isTop}>
            <StyledHeader src="media/logo_br-regional-update.svg" />
        </Wrapper>
    );
};
