import React from 'react';
import 'moment/locale/de';
import styled from 'styled-components';
import { COLOR, notify } from '../../styles/variables';

interface Props {
    value?: number | string;
    style?: React.CSSProperties;
    color?: string;
}

export const Indicator = styled.div`
    --indiSize: 2.5ch;
    color: white;
    font-size: 1rem;
    background-color: ${COLOR.primary};
    align-items: center;
    display: flex;
    border-radius: 50%;
    height: var(--indiSize);
    width: var(--indiSize);
    text-align: center;
    justify-content: center;
    line-height: var(--indiSize);
    font-weight: bold;
    animation: ${notify} 1s ease-in-out;
`;

export const IndicatorValue = styled.span`
    font-size: 0.75rem;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-flow: row;
    gap: 1ch;
    align-items: center;
    justify-content: center;
`;

export const IndicatorBadge: React.FC<Props> = ({ value, style, children, color }) => {
    return (
        <Wrapper style={style} title={'Noch nicht gehörte Meldungen'}>
            {children}
            {value ? (
                <Indicator style={{ backgroundColor: color }} key={`indi-${value}`}>
                    <IndicatorValue>{value}</IndicatorValue>
                </Indicator>
            ) : null}
        </Wrapper>
    );
};
