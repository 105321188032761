import React from 'react';
import styled from 'styled-components';
import { COLOR, STYLE } from '../../styles/variables';

export interface ButtonProps {
    color?: string;
    bgColor?: string;
    outlineColor?: string;
    dropShadow?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    onSelect: (value: string | number) => void;
    style?: React.CSSProperties;
    margin?: string;
    title?: string;
    options: (string | number)[];
    selectedOption?: string | number;
    placeHolder?: string;
}

const Container = styled.select<{
    $color?: string;
    $margin?: string;
    $bgColor?: string;
    $fullWidth?: boolean;
    $dropShadow?: boolean;
    $outlineColor?: string;
}>`
    background-color: ${({ $bgColor }) => $bgColor} !important;
    color: ${({ $color }) => $color} !important;
    border-color: ${({ $outlineColor }) => $outlineColor} !important;
    border-width: 2px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid !important;
    font-family: Open Sans SemiBold;
    width: ${({ $fullWidth = false }) => ($fullWidth ? '100%' : 'auto')} !impor tant;
    border-radius: ${STYLE.default.radius} !important;
    box-shadow: ${({ $dropShadow }) => ($dropShadow ? STYLE.default.shadow : '')} !important;
    padding: ${({ $fullWidth = false }) => ($fullWidth ? '1rem 0.75rem' : '0.5rem 0.25rem')} !important;
    margin: ${({ $margin = `0.5rem 0` }) => $margin} !important;
    font-size: 1rem !important;
    height: auto;
    -webkit-appearance: menulist-button;
    -moz-appearance: none;

    :disabled {
        opacity: 0.5 !important;
    }
`;

export const Select: React.FC<ButtonProps> = ({
    color = 'black',
    bgColor = COLOR.br24.lightGrey,
    outlineColor = COLOR.br24.lightGrey,
    onSelect,
    dropShadow,
    style,
    margin,
    fullWidth,
    disabled,
    title,
    options,
    selectedOption,
    placeHolder = 'Bitte wählen Sie...'
}) => {
    return (
        <Container
            title={title}
            style={style}
            $fullWidth={fullWidth}
            $bgColor={bgColor}
            $color={color}
            $outlineColor={outlineColor}
            $dropShadow={dropShadow}
            onChange={(event) => onSelect(event.target.value)}
            disabled={disabled}
            $margin={margin}
        >
            {options.map((option) => (
                <option value={option} selected={option === selectedOption}>
                    {option}
                </option>
            ))}
        </Container>
    );
};
