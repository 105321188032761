const userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera;

export class BrowserDetector {
    public static isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

    public static isMobile = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return true;
        } else if (
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return true;
        }
        return false;
    };

    public static isMobileIOS = () =>
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        (userAgent.includes('Mac') && 'ontouchend' in document) ||
        BrowserDetector.isSafari;

    public static isIOs = () => /(iPhone|iPod|iPad)/i.test(navigator.platform);

    public static isFireFox = () => userAgent.search('Firefox') > -1;

    public static isOldEdge = () => userAgent.toLowerCase().indexOf('edge') > -1;

    public static isChromium = (window as any).chrome;

    public static isInternetExplorer = () => {
        const msie = userAgent.indexOf('MSIE ');

        return msie > 0 || !!userAgent.match(/Trident.*rv:11\./);
    };

    public static isStandalone = BrowserDetector.isIOs()
        ? (window.navigator as any).standalone
        : window.matchMedia('(display-mode: standalone)').matches;

    public static hasNotch =
        BrowserDetector.isStandalone && getComputedStyle(document.documentElement).getPropertyValue('--sat');

    public static includesTaskbar = BrowserDetector.isIOs() && (window.navigator as any).standalone;

    public static isPortrait = innerWidth <= innerHeight;

    public static getIOsVersion() {
        var agent = window.navigator.userAgent,
            start = agent.indexOf('OS ');
        if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
            return agent
                .substr(start + 3, 6)
                .replace(/_/g, '.')
                .trim();
        }
        return undefined;
    }
}
