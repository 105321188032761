import React from 'react';
import { useGlobal } from '../../logic/main';
import styled from 'styled-components';
import { View } from '../../models';
import { COLOR, mobile, STYLE } from '../../styles/variables';

const StyledFeedbackButton = styled.div<{ isVisible?: boolean }>`
    position: fixed;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    top: 50%;
    opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
    transform: translateY(-50%) translateX(${({ isVisible }) => (isVisible ? 50 : 100)}%) rotate(-90deg);
    right: -1px;
    transform-origin: bottom;
    color: white;
    background-color: ${COLOR.primary};
    padding: 5px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: ${STYLE.default.shadow};

    :hover {
        cursor: pointer;
    }

    @media ${mobile} {
        top: 30%;
    }
`;

export const FeedbackButton: React.FC = () => {
    const [{ userLocation }, { changeViews }] = useGlobal(
        (state) => ({
            userLocation: state.userLocation
        }),
        (actions) => ({ changeViews: actions.changeViews })
    );

    const triggerFeedbackModal = () => changeViews({ back: View.FEEDBACK }, true);

    return (
        <StyledFeedbackButton isVisible={!!userLocation?.center} onClick={triggerFeedbackModal}>
            Feedback
        </StyledFeedbackButton>
    );
};
