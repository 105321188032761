import React from 'react';
import styled from 'styled-components';
import { ButtonProps } from './Button';
import { TextField } from '@material-ui/core';
import { COLOR, STYLE } from '../../styles/variables';

interface Props extends ButtonProps {
    placeholder: string;
    fontColor?: string;
    placeHolderColor?: string;
    style?: React.CSSProperties;
    innerPadding?: string;
    value?: string;
    handleValueChange?: (value: string) => void;
    align?: string;
    maxLength?: number;
    multiline?: boolean;
}

const Wrapper = styled(TextField)<{
    $fontColor?: string;
    $innerPadding?: string;
    $bgColor?: string;
    $align?: string;
    $fullWidth?: boolean;
    $dropShadow?: boolean;
    $outlineColor?: string;
    $placeHolderColor?: string;
}>`
    border: 0 !important;
    margin-bottom: ${STYLE.default.padding};
    width: ${({ $fullWidth = false }) => ($fullWidth ? '100%' : 'auto')} !important;
    border-radius: ${STYLE.default.radius};

    input {
        padding: ${({ $fullWidth = false, $innerPadding = '1rem 0.75rem' }) =>
            $fullWidth ? $innerPadding : '1rem 2rem'} !important;
        background-color: ${({ $bgColor }) => $bgColor} !important;
        color: ${({ $fontColor }) => $fontColor} !important;
        text-align: ${({ $align = 'center' }) => $align};
        border-radius: ${STYLE.default.radius};

        &::placeholder {
            color: ${({ $fontColor, $placeHolderColor }) => $placeHolderColor || $fontColor} !important;
            opacity: 1;
            font-size: 1rem;
        }

        &::-webkit-input-placeholder {
            color: ${({ $fontColor }) => $fontColor} !important;
        }

        &:focus {
            text-align: left;
            &::placeholder {
                opacity: 0.5;
            }
        }
    }

    .MuiOutlinedInput-root.Mui-focused fieldset {
        border-color: ${COLOR.primary} !important;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${COLOR.primary} !important;
    }

    fieldset {
        color: ${({ $fontColor }) => $fontColor} !important;
        border-color: ${({ $outlineColor }) => $outlineColor} !important;
        border-width: 2px !important;
        border-style: solid !important;
        width: ${({ $fullWidth = true }) => ($fullWidth ? '100%' : 'auto')} !important;
        border-radius: ${STYLE.default.radius} !important;
        box-shadow: ${({ $dropShadow }) => ($dropShadow ? STYLE.default.shadow : '')} !important;
    }
`;

const Input: React.FC<Props> = ({
    color,
    bgColor,
    outlineColor,
    dropShadow,
    style,
    innerPadding,
    fullWidth,
    align,
    maxLength = 1000,
    multiline,
    placeHolderColor,
    placeholder,
    value,
    handleValueChange
}) => {
    return (
        <Wrapper
            $bgColor={bgColor}
            $fullWidth={fullWidth}
            $fontColor={color}
            $outlineColor={outlineColor}
            $dropShadow={dropShadow}
            placeholder={placeholder}
            style={style}
            multiline={multiline}
            minRows={2}
            maxRows={3}
            $innerPadding={innerPadding}
            $placeHolderColor={placeHolderColor}
            $align={align}
            inputProps={{ maxLength: maxLength }}
            color="primary"
            variant="outlined"
            onChange={(e) => handleValueChange && handleValueChange(e.target.value)}
            value={value}
        />
    );
};

export default Input;
