import React from 'react';
import { useGlobal } from '../../logic/main';
import { useLocation } from 'react-router-dom';
import { Map } from '../presenter/Map';
import styled from 'styled-components';
import { defaultConfig } from '../../config';
import { Level } from '../../models';

const FakeMap = styled.div`
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    background-position: center;
    background-size: cover;
    background-image: url('media/init-bg.png');
    position: fixed;
    top: 0;
    left: 0;
`;

export const MapContainer: React.FC = () => {
    const [{ userLocation, filteredPlayList, coverage, currIndex, marker }, { setUserLocation, setNotification }] =
        useGlobal(
            (state) => ({
                userLocation: state.userLocation,
                filteredPlayList: state.filteredPlayList,
                coverage: state.coverage,
                marker: state.marker,
                currIndex: state.currentArticleIndex
            }),
            (actions) => ({
                setUserLocation: actions.setUserLocation,
                setNotification: actions.setNotification
            })
        );

    const location = useLocation();

    return userLocation ? (
        <Map
            location={
                location.pathname === '/remix/settings' || filteredPlayList.length === 0
                    ? { location: userLocation?.center, name: userLocation?.name }
                    : {
                          location: filteredPlayList[currIndex]?.location.center,
                          name: filteredPlayList[currIndex]?.location.name
                      }
            }
            origin={{ location: userLocation?.center, name: userLocation?.name }}
            items={
                [...coverage].reverse().find((c) => (c.distance || 0) <= (userLocation?.radius || 0))?.broadcast_items
                    .length || 0
            }
            maxItems={defaultConfig.maxNews}
            isFixed={true}
            onRadiusChange={
                location.pathname === '/remix/settings'
                    ? (to) => {
                          const newRadius =
                              (to > 0
                                  ? coverage.find((c) => (c.distance || 0) > (userLocation?.radius || 0))
                                  : [...coverage].reverse().find((c) => (c.distance || 0) < (userLocation?.radius || 0))
                              )?.distance ||
                              (userLocation?.radius || 0) +
                                  (to > 0 ? defaultConfig.scaleStep : -1 * defaultConfig.scaleStep);
                          if (
                              coverage.length > 0 &&
                              newRadius >=
                                  (coverage.find((c) => c.broadcast_items.length === defaultConfig.minNews)?.distance ||
                                      0)
                          ) {
                              setUserLocation({ radius: newRadius });
                          } else if (coverage.length === 0) {
                              setUserLocation({ radius: newRadius });
                          }
                      }
                    : undefined
            }
            onDisabledRadiusClicked={(type) =>
                type === 'max'
                    ? setNotification(
                          (userLocation?.radius || 0) < defaultConfig.maxRadius &&
                              !!filteredPlayList.length &&
                              filteredPlayList.length < defaultConfig.maxNews
                              ? 'Leider liegen für diese Region im maximal verfügbaren Radius keine weiteren Meldungen vor. Bitte erhöhen Sie das Nachrichten-Alter oder kommen Sie zu einem späteren Zeitpunkt wieder zurück.'
                              : `Maximaler Interessen-Radius erreicht (${defaultConfig.maxRadius} km o. ${
                                    defaultConfig.maxNews
                                } Meldung${defaultConfig.maxNews === 1 ? '' : 'en'}).`,
                          Level.INFO
                      )
                    : setNotification(
                          `Kleinstmöglicher Interessen-Radius erreicht (mind. ${defaultConfig.minNews} Meldung${
                              defaultConfig.minNews === 1 ? '' : 'en'
                          }).`,
                          Level.INFO
                      )
            }
            minRadius={coverage.find((c) => c.broadcast_items.length === defaultConfig.minNews)?.distance}
            maxRadius={defaultConfig.maxRadius}
            marker={marker}
            radius={location.pathname === '/remix/settings' ? (userLocation?.radius || 0) * 1000 : undefined}
        />
    ) : (
        <FakeMap />
    );
};
