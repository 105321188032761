import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { ReactSVG } from 'react-svg';

interface Props {
    src: string;
    size?: string;
    onClick?: () => void;
    styles?: FlattenSimpleInterpolation;
    style?: React.CSSProperties;
    title?: string;
}

const SVGDivWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Wrapper = styled(ReactSVG)<{ styles?: FlattenSimpleInterpolation }>`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
        display: block;
        height: auto;
    }

    ${({ styles }) => styles};
`;

export const SvgRenderer: React.FC<Props> = ({ src, onClick, styles, style, title }) => (
    <SVGDivWrapper title={title}>
        <Wrapper title={title} src={src} onClick={onClick} style={style} styles={styles} />
    </SVGDivWrapper>
);
