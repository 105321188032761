import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { COLOR, mobile, STYLE } from '../../styles/variables';
import { wait } from '../../utils';

export const transitionTime = 0.65;
export const fastTransitionTime = 0.05;
export const transitionType = 'ease-out';

const StyledCard = styled.div<{
    position: 'center' | 'bottom';
    side?: 'front' | 'back';
    height?: number;
}>`
    width: ${({ position }) => (position === 'center' ? '35vw' : '800px')};
    display: flex;
    position: fixed;
    flex-flow: column;
    transform-style: preserve-3d;
    bottom: 50%;
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    left: 50%;
    z-index: 999;
    transform: translate(-50%, 50%) perspective(1000px) rotateY(${({ side }) => (side === 'back' ? '-180deg' : '0deg')});
    background-color: ${COLOR.pageBackground};
    align-items: center;
    box-shadow: ${STYLE.default.shadow};
    box-sizing: content-box;
    border-radius: ${({ position }) =>
        position === 'center' ? STYLE.default.radius : `${STYLE.default.radius} ${STYLE.default.radius} 0 0`};
    will-change: bottom;
    transition: height ${transitionTime}s ${transitionType}, bottom ${transitionTime}s ${transitionType},
        transform ${transitionTime}s ${transitionType}, opacity ${transitionTime}s ${transitionType},
        width ${fastTransitionTime}s ${transitionType};

    > div {
        backface-visibility: hidden;
        overflow: hidden;
        transition: opacity ${transitionTime}s ${transitionType};

        :nth-child(1) {
            opacity: 0;
        }
    }

    @media ${mobile} {
        width: ${({ position }) => (position === 'center' ? '80vw' : '100vw')};
    }
`;

const Backdrop = styled.div<{ open?: boolean }>`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 999;
    position: fixed;
    align-items: center;
    opacity: ${({ open }) => (open ? 1 : 0)};
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: ${({ open }) => (open ? 'blur(2px)' : 'blur(0px)')};
    transition: opacity ${transitionTime}s ${transitionType}, backdrop-filter ${transitionTime}s ${transitionType};
    box-sizing: content-box;
    pointer-events: none;
`;

const FrontCard = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: fit-content;
    backface-visibility: hidden;
    z-index: 1000;
    box-sizing: content-box;
`;

const BackCard = styled.div`
    position: absolute;
    backface-visibility: hidden;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: fit-content;
    transform: rotateY(180deg);
    box-sizing: content-box;
`;

interface Props {
    position: 'center' | 'bottom';
    style?: React.CSSProperties;
    side?: 'front' | 'back';
    backContent?: React.ReactElement;
    minHeight?: number;
}

const CardPresenter: React.FC<Props> = ({
    position,
    children,
    style,
    minHeight,
    side = 'front',
    backContent = null
}) => {
    const cardRef = useRef<HTMLDivElement>(null);
    const frontRef = useRef<HTMLDivElement>(null);
    const backRef = useRef<HTMLDivElement>(null);
    // As front changes more often, just these states and logics are used. Do the same for back component, if it changes!
    const [lastFront, setLastFront] = useState('');
    const [lastFrontHeight, setLastFrontHeight] = useState<number | undefined>(minHeight);

    useEffect(() => {
        const card = cardRef.current;

        const adjustHeight = async (side: 'front' | 'back') => {
            let currentCardHeight;
            const sideRef = side === 'front' ? frontRef.current : backRef.current;
            const pageChild = sideRef!.children[0];
            const pageChildClassRef = pageChild.children[0].className;
            const pageChildMaxHeight = getComputedStyle(pageChild)?.maxHeight;

            if (side === 'front') {
                // Just use timer on first render of passed element, once it was rendered or height is given, no need to wait for animation
                if (
                    lastFront !== pageChildClassRef &&
                    (pageChildMaxHeight === 'none' || pageChildMaxHeight === 'none')
                ) {
                    //frontRef.current!.style.height = 'auto';
                    setLastFront(pageChildClassRef);
                    currentCardHeight = pageChild.clientHeight;
                    frontRef.current!.style.height = 'initial';
                    cardRef.current!.style.height = `${lastFrontHeight}px`;

                    await wait(transitionTime * 1000);
                    frontRef.current!.style.opacity = '1';
                    currentCardHeight = pageChild.clientHeight;
                    cardRef.current!.style.height = `${currentCardHeight}px`;
                } else {
                    frontRef.current!.style.opacity = '1';
                    setLastFront(pageChildClassRef);
                    const hasMaxHeight = pageChildMaxHeight && pageChildMaxHeight !== 'none';
                    if (hasMaxHeight) {
                        frontRef.current!.style.height = '100%';
                    }
                    const targetHeight = hasMaxHeight
                        ? parseInt(pageChildMaxHeight, 10)
                        : (currentCardHeight = pageChild.clientHeight);
                    currentCardHeight = targetHeight;
                    cardRef.current!.style.height = `${targetHeight}px`;
                    setLastFrontHeight(currentCardHeight);
                }
            } else {
                frontRef.current!.style.opacity = '1';
                currentCardHeight = pageChild.clientHeight;
                cardRef.current!.style.height = `${currentCardHeight}px`;
                setTimeout(() => {
                    cardRef.current!.style.height = `${pageChild.clientHeight}px`;
                }, fastTransitionTime * 1000);
            }
            if (card) {
                switch (position) {
                    case 'bottom':
                        card.style.bottom = `${(currentCardHeight || 0) / 2}px`;
                        break;
                    case 'center':
                        card.style.bottom = '50%';
                        break;
                    default:
                        break;
                }
            }
        };

        if (frontRef.current && backRef.current) {
            adjustHeight(side);
            window.onresize = () => adjustHeight(side);
        }
    }, [position, side]);

    return (
        <>
            <Backdrop open={position === 'center'} />
            <StyledCard ref={cardRef} style={style} position={position} side={side}>
                <FrontCard id="card-front" ref={frontRef}>
                    {children}
                </FrontCard>
                <BackCard id="card-back" ref={backRef}>
                    {backContent}
                </BackCard>
            </StyledCard>
        </>
    );
};

export default CardPresenter;
