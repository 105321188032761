import { MainConfig, Language } from './models';
import { config } from '../../page.config';
import { isEnglish, path } from '../utils/';
import { Config, View } from '../models';
import { LatLng, LatLngBounds } from 'leaflet';

const lang = isEnglish() ? Language.EN : Language.DE;

// @ts-ignore
const URL = __URL__;
// @ts-ignore
const API = __API__;
// @ts-ignore
const STAGE = __STAGE__ === 'undefined' ? undefined : __STAGE__;
// @ts-ignore
const MAPTILER_KEY = __MAPTILER_KEY__;
// @ts-ignore
export const UMAMI_ID = __UMAMI_ID__;
// @ts-ignore
export const DEBUG = __DEBUG__;

const { de, en, headerImage, previewImage, ...otherConfig } = config;

const defaultBackend =
    STAGE?.toLowerCase() == 'live'
        ? 'https://regional-news-bos.interaktiv.br.de'
        : 'https://regional-news-bos.brdata-dev.de';

const trackingUrl = STAGE?.toLowerCase() == 'live' ? 'https://t.interaktiv.br.de' : 'https://t.brdata-dev.de';

export const mainConfig: MainConfig = {
    apiEndpoint: !!API && API !== 'undefined' ? API : defaultBackend,
    url: `${URL}${lang === 'en' ? 'en/' : ''}`,
    trackingID: UMAMI_ID,
    trackingUrl,
    title: config[lang].title,
    headline: config[lang].headline,
    description: config[lang].description,
    keywords: config[lang].keywords,
    previewImage: path(config[lang].previewImage || previewImage),
    headerImage: path(headerImage),
    mobileShareText: config[lang].mobileShareText,
    twitterShareText: config[lang].twitterShareText,
    whatsappShareText: config[lang].whatsappShareText,
    mailShareText: config[lang].mailShareText,
    ...otherConfig
};

const bavariaCoords = [
    { lat: 47.583748, lng: 9.602103 }, // (Bottom lef) Southwest corner
    { lat: 50.317782, lng: 12.100281 } // (Top right) Northeast corner
];
const borderCoords = [
    { lat: 50.563298, lng: 10.120521 }, // Top Middle
    { lat: 50.071706, lng: 9.002734 }, // Middle left
    { lat: 48.696005, lng: 13.83113 }, // Middle right
    { lat: 47.469053, lng: 13.006027 }, // Bottom right
    { lat: 47.275894, lng: 10.235009 } // Bottom middle
];

// Create LatLng objects for each coordinate
const bavariaSouthwest = new LatLng(bavariaCoords[0].lat, bavariaCoords[0].lng);
const bavariaNortheast = new LatLng(bavariaCoords[1].lat, bavariaCoords[1].lng);
const borderPoints = borderCoords.map((coord) => new LatLng(coord.lat, coord.lng));

// Create a bounds object for Bavaria and the border
const bounds = new LatLngBounds(bavariaSouthwest, bavariaNortheast);
borderPoints.forEach((point) => bounds.extend(point));

export const defaultConfig: Config = {
    useIOsAudioHack: false,
    feedbackHintTime: 120,
    defaultRadius: 15,
    autoRadius: false,
    maxPlaylistDurationMinutes: 30,
    maxNewsAgeHrs: 48,
    bounds,
    scaleStep: 5,
    minNews: 1,
    maxNews: 30,
    minRadius: 2,
    maptilerKey: !STAGE ? MAPTILER_KEY : await (await fetch('maptiler.txt'))?.text(),
    maxRadius: 100,
    maxInitRadius: 50,
    cardState: {
        [View.NO_LOCATION]: { position: 'center', side: 'front' },
        [View.INFO]: { position: 'center', side: 'back' },
        [View.FAQ]: { position: 'center', side: 'back' },
        [View.FEEDBACK]: { position: 'center', side: 'back' },
        [View.PLAYER]: { position: 'bottom', side: 'front' }
    }
};

window.dataLayer = window.dataLayer || [];
