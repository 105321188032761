import React from 'react';
import styled from 'styled-components';
import { COLOR, elasticSpin, RR_COLORS, STYLE } from '../../../styles/variables';
import { Coords } from '../../../models';
import { Map } from 'leaflet';
import GeometryUtil from 'leaflet-geometryutil';

interface Props {
    location?: { name?: string; location?: Coords };
    origin?: { name?: string; location?: Coords };
    radius?: number;
    items?: number;
    map?: Map;
    title?: string;
}

const MarkerWrapper = styled.div`
    width: auto;
    display: flex;
    gap: 0.5ch;
    white-space: nowrap;
    border-radius: calc(${STYLE.default.radius} * 1.75);
    background-color: white;
    padding: 5px 15px;
    position: absolute;
    top: -10px;
    left: 0;
    font-size: 1rem;
    transform: translateX(-50%);
    align-items: center;
`;

const Origin = styled.div`
    font-weight: bold;
`;

const Angle = styled.div`
    color: ${COLOR.primary};
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const AngleIconWrapper = styled.div`
    width: 2.75ch;
    height: 2.75ch;
    border-radius: 50%;
    border: 1.75px dashed ${RR_COLORS['br-mittelgrau']};
    display: flex;
    align-items: center;
    justify-content: center;
`;
const AngleIcon = styled.img<{ angle?: number; size?: string }>`
    transform: rotate(${(props) => props.angle || 0}deg);
    width: ${(props) => props.size || '1.85ch'} !important;
    animation: ${(props) => (props.angle ? elasticSpin(props.angle) : '')} 1.5s ease-in-out 1s;
`;

const Dot = styled.div`
    width: 1ch;
    height: 1ch;
    background-color: ${COLOR.primary};
    border-radius: 50%;
`;

const Radius = styled.div`
    color: ${COLOR.primary};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.75ch;
`;

const Current = styled.span`
    color: ${COLOR.primary};
    font-weight: normal;
    display: contents;
`;
const Items = styled.div`
    color: ${RR_COLORS['br-mittelgrau']};
`;

export const RadiusLabel: React.FC<Props> = ({ radius, origin, location, items, map, title }) => {
    const isCenter = () =>
        location &&
        location.location &&
        origin &&
        origin.location &&
        (Math.round((map?.distance(location.location, origin.location) || 0) / 1000) < 1 ||
            origin.name === location.name);

    return !!origin?.location && !!location?.location ? (
        <MarkerWrapper title={title}>
            {radius ? (
                <Origin>{origin?.name}</Origin>
            ) : (
                <Angle>
                    <AngleIconWrapper>
                        {isCenter() ? (
                            <Dot />
                        ) : (
                            <AngleIcon
                                src="media/icon_kompass_nadel.svg"
                                angle={Math.round(GeometryUtil.angle(map!, location.location, origin.location))}
                            />
                        )}
                    </AngleIconWrapper>
                    {isCenter() ? (
                        origin?.name
                    ) : (
                        <>
                            {Math.round((map?.distance(location.location, origin.location) || 0) / 1000).toFixed(0)} km{' '}
                            <Current>von {origin?.name}</Current>
                        </>
                    )}
                </Angle>
            )}
            {radius ? (
                <Radius>
                    <AngleIcon src="media/icon_radius.svg" size={'2.25ch'} />
                    <span style={{ marginLeft: '0.5ch' }}>{Math.round(radius / 1000).toFixed(0)} km</span>
                </Radius>
            ) : null}
            {radius ? (
                <Items>
                    {items} Meldung{items === 1 ? '' : 'en'}
                </Items>
            ) : null}
        </MarkerWrapper>
    ) : null;
};
