import React, { memo, MouseEventHandler, useEffect } from 'react';
import styled from 'styled-components';
import { COLOR, RR_COLORS } from '../../styles/variables';
import { compareDeep } from '../../utils';
import { Line } from 'rc-progress';
import { BackgroundWaves } from './Noise';

interface Props {
    completed: number;
    bgColor?: string;
    isPlaying?: boolean;
    isActive?: boolean;
    style?: React.CSSProperties;
    onClick?: (progress: number) => void;
    id: string;
    max: number;
    key?: any;
}

const StyledLine = styled(Line as any)<{ max: number; isPlaying?: boolean; percent?: number }>`
    width: 100%;
    height: 5px;
    pointer-events: all;
    cursor: pointer;
    position: absolute;
    left: 0;
    overflow-x: hidden;

    .rc-progress-line-trail {
        stroke: ${RR_COLORS['br-hellgrau']};
    }

    .rc-progress-line-path {
        stroke: ${({ bgColor = COLOR.primary, isPlaying, percent }) =>
            isPlaying || percent ? bgColor : 'transparent'};
        pointer-events: none;
    }
`;

const Container = styled.div<{ isPointer?: boolean; max?: number }>`
    height: 6px;
    cursor: ${({ isPointer }) => (!!isPointer ? 'pointer' : 'inherit')};
    width: ${({ max }) => max}%;
    max-width: ${({ max }) => max}%;
    position: relative;
`;

const Dot = styled.span<{ bgColor?: string }>`
    background-color: ${({ bgColor = COLOR.primary }) => bgColor};
    position: absolute;
    width: 15px;
    z-index: 999;
    top: -4.5px;
    left: 0;
    border-radius: 50%;
    height: 15px;
    transition: left 0.5s ease;
`;

const ProgressBar: React.FC<Props> = ({ completed, bgColor, isPlaying, onClick, style, max = 100, id, isActive }) => {
    const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
        const rect = (e as any).target.getBoundingClientRect();
        const clicked = e.clientX - rect.left;
        const progress = (100 * clicked) / rect.width;
        return onClick && onClick(progress);
    };

    useEffect(() => {
        const progress: HTMLDivElement = document.getElementsByClassName(id)[0] as any;

        if (progress) {
            progress.addEventListener('click', handleClick as any);
        }
        return () => {
            progress.removeEventListener('click', handleClick as any);
        };
    }, [max, id]);

    return (
        <Container style={style} max={max}>
            <StyledLine
                percent={completed > 100 ? 100 : completed}
                strokeWidth={2}
                max={max}
                strokeColor={bgColor}
                className={id}
                padding={'0.25rem 0'}
                isLabelVisible={false}
                transitionDuration={isPlaying ? 0.75 : 0}
                isPlaying={isPlaying}
                trailWidth={2}
                trailColor={RR_COLORS['br-mittelgrau']}
            />
            {isPlaying ? <BackgroundWaves peak={10} /> : null}
            {isActive ? <Dot style={{ left: (completed > 99 ? 99 : completed) + '%' }} /> : null}
        </Container>
    );
};

export default memo<Props>(
    ProgressBar,
    (prevProps, nextProps) =>
        compareDeep(prevProps.children, nextProps.children) &&
        prevProps.completed === nextProps.completed &&
        prevProps.max === nextProps.max &&
        prevProps.isPlaying === nextProps.isPlaying &&
        prevProps.isActive === nextProps.isActive
);
