import { LatLngBounds } from 'leaflet';

export interface Coords {
    lat: number;
    lng: number;
}

export interface Location {
    name?: string;
    center?: Coords;
    radius?: number;
    radiusRounded?: number;
    isSet?: boolean;
    bboxMax?: Coords;
    bboxMin?: Coords;
    latitude?: number;
    longitude?: number;
    district?: string;
}

export interface Marker {
    latitude: number;
    longitude: number;
    count: number;
}

export enum View {
    NO_LOCATION = 'NO_LOCATION',
    INFO = 'INFO',
    PLAYER = 'PLAYER',
    FAQ = 'FAQ',
    FEEDBACK = 'FEEDBACK'
}

export interface Config {
    defaultRadius: number;
    autoRadius?: boolean;
    maxPlaylistDurationMinutes: number;
    maxNewsAgeHrs: number;
    minNews: number;
    maxNews: number;
    maptilerKey: string;
    scaleStep: number;
    bounds: LatLngBounds;
    minRadius: number;
    maxRadius: number;
    maxInitRadius: number;
    useIOsAudioHack: boolean;
    feedbackHintTime: number;
    cardState: {
        [key in View]: { position: 'center' | 'bottom'; side: 'front' | 'back' };
    };
}

export interface Subtitle {
    time: number;
    text: string;
}

export enum PlayType {
    BEITRAG = 'BEITRAG',
    PLING = 'PLING',
    NEWS = 'NEWS',
    INTRO = 'INTRO',
    OUTRO = 'OUTRO'
}

export enum FeedbackType {
    Happy = 'happy',
    Ok = 'ok',
    Nope = 'nope',
    Custom = 'custom'
}

export interface Feedback {
    id: string;
    types: FeedbackType[];
    info?: string;
}

export interface InternalRegionalAudio {
    listened?: boolean;
}

export interface Feedback {
    id: string;
    types: FeedbackType[];
    info?: string;
}

export interface RegionalAudio extends InternalRegionalAudio {
    id: string;
    type: PlayType;
    audioFileUrl: string;
    title: string;
    author: string;
    duration?: number;
    region: string;
    location: Location;
    topics?: string[];
    subtitles?: Subtitle[];
    date: string;
}

export type Playlist = RegionalAudio[];

export interface RegionalAudioResponse {
    playlist: Playlist;
    jingleAudioUrl: string;
    introAudioUrl: string;
    outroAudioUrl: string;
}

export interface BroadCastItem {
    om_id: string;
    location: { latitude: number; longitude: number };
}

export interface Coverage {
    /**
     * Distance from given Lat/Lng in km
     */
    distance: number;
    /**
     * Amount of broadcast items
     */
    broadcast_items: BroadCastItem[];
    /**
     * Summed duration of broadcast items in seconds
     */
    duration: number;
}

export interface CoverageResponse {
    coverage: Coverage[];
}

export interface MarkerResponse {
    coverage: Marker[];
}

export interface MatchedSubstrings {
    length: number;
    offset: number;
}

export interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface Viewport {
    south: number;
    west: number;
    north: number;
    east: number;
}

export interface Geometry {
    location: Coords;
    viewport: Viewport;
}

export interface Photo {
    height: number;
    html_attributions: string[];
    width: number;
}

export interface Gmaps {
    address_components: AddressComponent[];
    adr_address: string;
    formatted_address: string;
    geometry: Geometry;
    icon: string;
    name: string;
    photos: Photo[];
    place_id: string;
    reference: string;
    types: string[];
    url: string;
    utc_offset: number;
    vicinity: string;
    website: string;
    html_attributions: any[];
    utc_offset_minutes: number;
}

export type PlayState = 'PAUSE' | 'PLAY';
export type SeekState = 'FORWARD' | 'BACKWARD';

export interface Coords {
    lat: number;
    lng: number;
}

export interface LocationData {
    description: string;
    isFixture: boolean;
    label: string;
    matchedSubstrings: MatchedSubstrings;
    placeId: string;
    gmaps: Gmaps;
    location: Coords;
}

export enum Level {
    WARNING = 'warning',
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success'
}

export enum Warning {
    IOS_17 = 'ios-17'
}
