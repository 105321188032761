import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useGlobal } from '../logic/main';

export const OutHook: { navigate?: (to: string) => void } = {};

export const OutHookConfigurator = () => {
    const _useNavigate = useNavigate();

    useEffect(() => {
        OutHook.navigate = _useNavigate;
    }, [_useNavigate]);

    return null;
};

export const ChangeViewListener: React.FC = () => {
    const { pathname } = useLocation();
    const [_, { pause }] = useGlobal(
        (_) => undefined,
        (actions) => ({ pause: actions.pause })
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        pause();
    }, [pathname]);

    return null;
};
