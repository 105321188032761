export enum Language {
    DE = 'de',
    EN = 'en'
}

export enum Caption {
    Wirtschaft = 'Wirtschaft',
    Coronavirus = 'Coronavirus',
    Bayern = 'Bayern',
    Sport = 'Sport',
    Newsletter = 'Newsletter',
    Kultur = 'Kultur',
    Wissen = 'Wissen',
    Netzwelt = 'Netzwelt',
    Nachrichten = 'Nachrichten'
}

export enum SocialMedia {
    Facebook = 'Facebook',
    Twitter = 'Twitter',
    Mail = 'Mail',
    Whatsapp = 'Whatsapp'
}

export enum Author {
    MaxZierer = 'Max Zierer',
    RobertSchoeffel = 'Robert Schöffel',
    SteffenKuehne = 'Steffen Kühne',
    HakanTanriverdi = 'Hakan Tanriverdi',
    NielsRingler = 'Niels Ringler',
    UliKoeppen = 'Uli Köppen',
    MichaelKreil = 'Michael Kreil',
    AnnKathrinWetter = 'Ann-Kathrin Wetter',
    VerenaNierle = 'Verena Nierle',
    LisaWreschniok = 'Lisa Wreschniok',
    SebastianBayerl = 'Sebastian Bayerl',
    ChristianSonnberger = 'Christian Sonnberger',
    ElisaHarlan = 'Elisa Harlan',
    OliverSchnuck = 'Oliver Schnuck',
    RebeccaCiesielski = 'Rebecca Ciesielski',
    SammyKhamis = 'Sammy Khamis'
}

export interface TextConfig {
    title: string;
    headline: string;
    description: string;
    keywords: string[];
    mobileShareText?: string;
    twitterShareText?: string;
    mailShareText?: string;
    whatsappShareText?: string;
    previewImage?: string;
}

export interface PageConfig {
    caption?: Caption;
    authors: Author[];
    url: string;
    provideEn?: boolean;
    ATId: string;
    headerImage: string;
    previewImage?: string;
    apiEndpoint?: string;
}

export interface LayoutConfig {
    headerOverlay?: boolean;
    showAside?: boolean;
    showProgress?: boolean;
}

export interface NavbarSize {
    breakpoint: string;
    size: string;
}

export enum Navbar {
    SMALL,
    MEDIUM,
    LARGE
}

export enum Position {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export enum Layout {
    COLUMN = 'column',
    ROW = 'row'
}

export type UserConfig = Omit<PageConfig, 'url'> & {
    de: TextConfig;
    en: TextConfig;
} & LayoutConfig;

export type CustomConfig = {
    trackingUrl: string;
    trackingID: string;
};

export type MainConfig = PageConfig & TextConfig & LayoutConfig & CustomConfig;
