import React from 'react';
import styled from 'styled-components';
import { A } from '../../styles/styledComponents';
import { COLOR, mobile, STYLE } from '../../styles/variables';
import { useGlobal } from '../../logic/main';
import { View } from '../../models';
import { track } from '../../logic/tracker';

interface Props {
    showInfoLink?: boolean;
    onInfoClicked?: () => void;
}

export const FooterWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 1ch;
    font-size: 0.85rem;
    margin-bottom: ${STYLE.default.padding};
    color: ${COLOR.br24.mediumGrey};

    a {
        color: ${COLOR.br24.mediumGrey};
    }
    @media ${mobile} {
        flex-wrap: wrap;
    }
`;

export const Footer: React.FC<Props> = () => {
    const [currentView, { changeViews }] = useGlobal(
        (state) => state.currentView,
        (actions) => ({
            changeViews: actions.changeViews
        })
    );

    const disabledStyle = (view: View): React.CSSProperties | undefined =>
        view === currentView ? { opacity: 0.5, pointerEvents: 'none', textDecoration: 'none' } : undefined;

    return (
        <FooterWrapper>
            <A style={disabledStyle(View.INFO)} onClick={() => changeViews({ back: View.INFO }, true)}>
                Über das Projekt
            </A>{' '}
            |{' '}
            <A style={disabledStyle(View.FAQ)} onClick={() => changeViews({ back: View.FAQ }, true)}>
                FAQs
            </A>{' '}
            |{' '}
            <A
                onClick={() => track('info', 'privacyClicked', true)}
                href="https://www.br.de/unternehmen/service/datenschutz/datenschutz-standortbasierte-funktionalitaeten-100.html"
                target="_blank"
            >
                Datenschutz
            </A>{' '}
            |{' '}
            <A href="https://www.br.de/unternehmen/service/impressum/index.html" target="_blank">
                Impressum
            </A>
        </FooterWrapper>
    );
};
