import React, { memo, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { noise, RR_COLORS } from '../../styles/variables';
import { randomBetween } from '../../utils';

const NoiseWrapper = styled.div<{ arr?: any[]; height?: number; size?: number; colorRange?: string[]; peak?: number }>`
    --sy: 1;
    --aps: running;
    --op: 0;

    position: absolute;
    left: 0;
    top: ${({ size = 3 }) => size}px;
    transform: translateY(-50%);
    width: 100%;
    height: ${({ height = 3 }) => height}px;
    border: none;
    background: none;
    color: #fff;
    pointer-events: none;
    z-index: -1;

    &:after {
        content: '';
        opacity: var(--op, 1);
        transition: opacity 0.4s;
    }

    & > i {
        position: absolute;
        left: var(--left, 0);
        top: 50%;
        width: ${({ size = 3 }) => size}px;
        height: ${({ peak = 20 }) => peak}px;
        transform: translateY(-50%) scaleY(var(--sy, 0));
        z-index: -1;
        transition: transform var(--duration) ease-out;

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background-image: linear-gradient(
                ${({ colorRange = ['#fff', RR_COLORS['br-hellgrau']] }) =>
                    `${colorRange[0]}, ${colorRange[1]}, ${colorRange[0]}`}
            );
            border-radius: 50%;
            animation: ${noise()} var(--duration) infinite ease-in-out alternate;
            animation-play-state: var(--aps, paused);
        }

        ${({ arr = [], size = 3 }) =>
            arr.map(
                (_, i) => css`
                    &:nth-child(${i + 1}) {
                        --left: ${i * (size + 1)}px;
                        --duration: ${randomBetween(200, 400, true)}ms;
                        --size: ${randomBetween(0, 1)};
                    }
                `
            )}
    }
`;

interface Props {
    height?: number;
    size?: number;
    style?: React.CSSProperties;
    colorRange?: string[];
    peak?: number;
}

export const BackgroundWaves: React.FC<Props> = memo(({ height, size = 3, style, colorRange, peak }) => {
    const [arr, setArr] = useState<any[] | undefined>();
    const noiseRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (noiseRef.current && !arr) {
            const width = noiseRef.current.clientWidth;
            const waves = new Array(Math.round(width / (size + 1))).fill(1);
            setArr(waves);
        }
    }, [noiseRef, arr]);

    return (
        <NoiseWrapper arr={arr} height={height} size={size} style={style} ref={noiseRef as any} colorRange={colorRange} peak={peak}>
            {arr?.map((_, i) => (
                <i key={`wave-${i}`}></i>
            ))}
        </NoiseWrapper>
    );
});
