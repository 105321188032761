import { UmamiTracker } from './UmamiTracker';
import { DEBUG, mainConfig } from '../config';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FeedbackType, Level } from '../models';
import { LocalStorage } from '../utils/localStorage';

const umamiTracker = new UmamiTracker(mainConfig.trackingID, mainConfig.trackingUrl);

let lastPage: { key: string; events: string[] } = { key: '', events: [] };

export interface TrackingEvents {
    start: {
        getGeoLocation: string;
        inputLocation: string;
        startedVia: 'tab' | 'button';
    };
    settings: {
        increaseRadius: number;
        decreaseRadius: number;
        increaseNewsAge: number;
        decreaseNewsAge: number;
        reset: boolean;
    };
    player: {
        play: string;
        pause: string;
        forward: string | 'JINGLE';
        backward: string | 'JINGLE';
        seek: 'back' | 'forth';
        heartbeat: number;
        stop: string;
    };
    info: {
        privacyClicked: boolean;
        mapInteraction: boolean;
        faqDetails: string;
    };
    notification: {
        [Level.INFO]: string;
        [Level.SUCCESS]: boolean;
        [Level.WARNING]: boolean;
        [Level.ERROR]: boolean;
    };
    feedback: {
        [FeedbackType.Happy]: boolean;
        [FeedbackType.Ok]: boolean;
        [FeedbackType.Nope]: boolean;
        [FeedbackType.Custom]: string;
    };
}

const isSamePage = (info: any) => {
    const isPageEvent = info.event === 'set_properties' || info.event === 'page.display';
    const isIt = isPageEvent && info.page === lastPage.key && lastPage.events.length === 2;
    if (isPageEvent && info.page !== lastPage.key) {
        lastPage = { key: info.page, events: [info.event] };
    } else if (isPageEvent && !lastPage.events.includes(info.event)) {
        lastPage.events.push(info.event);
    }

    if (!isIt && DEBUG) {
        console.warn('TRACKING:', info);
    }

    return isIt;
};

const pushToDatalayer = (info: any) =>
    !!(!mainConfig.trackingUrl.includes('brdata-dev') || DEBUG) && !isSamePage(info)
        ? window.dataLayer.push(info)
        : null;

const getDefaultParameters = () => ({
    location: LocalStorage.getLocation()?.name,
    radius: LocalStorage.getLocation()?.radius,
    newsAge: LocalStorage.maxAge() || 48,
    newsItems: LocalStorage.getPlaylist()?.length,
    playlistDuration: LocalStorage.getPlaylist()?.reduce((a, b) => a + (b?.duration || 0), 0)
});

export interface TrackingInfo {
    newsItemIndex?: number;
    newsItemDuration?: number;
    cursorPosition?: number;
    playlistCursorPosition?: number;
    content?: string;
}

export const track = <T extends keyof TrackingEvents>(
    category: T,
    action: keyof TrackingEvents[T],
    name?: any,
    trigger?: 'manual' | 'automatic',
    other?: TrackingInfo
) => {
    umamiTracker.trackEvent(category, { [action]: name as any });

    const data = {
        event: category === 'player' ? `av.${action}` : 'click.action', // Event-Typ, bspw. select_location,
        action: category === 'player' ? name : action,
        label: category === 'player' || category === 'settings' ? undefined : name,
        ...getDefaultParameters(),
        publisher: category === 'player' ? `BAYERN 1` : undefined,
        ...other,
        trigger
    };

    pushToDatalayer(data);
};

export const trackPage = (view?: string) => {
    const data = {
        event: 'set_properties',
        url: window.location.href, // Die URL der Seite
        page: view, // Titel der Seite
        seitenname_id: `remix-regional-${view}`, // Eindeutige ID der Seite,
        ...getDefaultParameters()
    };
    setTimeout(() => {
        pushToDatalayer(data);
        pushToDatalayer({
            event: 'page.display',
            url: window.location.href, // Die URL der Seite
            page: view, // Titel der Seite
            seitenname_id: `remix-regional-${view}` // Eindeutige ID der Seite
        });
        umamiTracker.trackView(view || window.location.pathname);
    }, 500);
};

export const usePageView = (view: string, ref?: string) => {
    const location = useLocation();
    useEffect(() => {
        if (location.pathname.includes(ref || view)) {
            trackPage(view);
        }
    }, [location]);
};
